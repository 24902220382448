import { Icon } from '@chakra-ui/react'
import * as React from 'react'

const PlusIcon = ({ boxSize, color }: {
  boxSize: number | string
  color: string
}): JSX.Element => (
    <Icon viewBox="0 0 122.881 122.88" boxSize={boxSize} color={color}>
        <path
            fill='currentColor'
            d="M56.573 4.868a4.887 4.887 0 0 1 1.426-3.442A4.85 4.85 0 0 1 61.44 0a4.85 4.85 0 0 1 1.863.371l.037.017a4.879 4.879 0 0 1 1.543 1.039 4.851 4.851 0 0 1 1.426 3.442c0 .03-.002.06-.006.089v51.62h51.619a.907.907 0 0 1 .09-.006c.656 0 1.285.132 1.861.371.014.005.025.011.037.017a4.879 4.879 0 0 1 1.543 1.039 4.852 4.852 0 0 1 1.057 5.3 4.877 4.877 0 0 1-2.637 2.638v.001a4.865 4.865 0 0 1-1.861.37.68.68 0 0 1-.09-.006l-51.619.001v51.619a.67.67 0 0 1 .006.09c0 .656-.133 1.286-.371 1.861l-.018.037a4.857 4.857 0 0 1-6.339 2.599 4.855 4.855 0 0 1-2.638-2.636h-.001a4.843 4.843 0 0 1-.371-1.861.67.67 0 0 1 .006-.09V66.303H4.958a.658.658 0 0 1-.09.006 4.844 4.844 0 0 1-1.859-.371 4.855 4.855 0 0 1-2.638-2.636 4.848 4.848 0 0 1 0-3.721c.249-.6.61-1.137 1.056-1.583a4.853 4.853 0 0 1 3.442-1.426c.031 0 .061.002.09.006h51.62V4.958a.68.68 0 0 1-.006-.09z"
        />
    </Icon>
)

export default PlusIcon
