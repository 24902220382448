import { VStack, Center, HStack } from '@chakra-ui/react'
import React from 'react'
import Card from '../../../../ui/card/Card'
import { type RealEstateDetailStore } from './RealEstateDetailStore'
import { Subtitle } from '../../../../ui/text/BodyTextComponent'
import DefaultSelect from '../../../../ui/inputs/DefaultSelect'
import DefaultEditableInput from '../../../../ui/inputs/DefaulEditabletInput'

const RealEstateBasicInfoForm = ({ store }: { store: RealEstateDetailStore }): JSX.Element => {
  return (
    <Card>
        <VStack alignItems='start' gap={6} my={8} width="100%">
          <Subtitle text="Características" />
          <HStack gap={4} marginY={'1rem'} width="80%">
            <Center width="49%">
              <DefaultSelect
                            placeholder={store.realEstate?.basicInfo.operationType?.toString() ?? 'Operación'}
                            onChange={(value) => { store.setOperationType(value) }}
                            optionsArray={[
                              { text: 'Compra', value: 'buy' },
                              { text: 'Alquiler', value: 'rent' }
                            ]} accessibilityKey={'Selecciona Tipo Operación'}
              />
            </Center>
            <Center width="49%">
              <DefaultSelect placeholder={store.realEstate?.basicInfo.type ?? 'Tipo'}
                           onChange={(value) => { store.setType(value) }}
                           optionsArray={[
                             { text: 'Piso', value: 'flat' },
                             { text: 'Apartamento', value: 'apartment' },
                             { text: 'Ático', value: 'penthouse' },
                             { text: 'Casa', value: 'house' },
                             { text: 'Chalet', value: 'chalet' },
                             { text: 'Villa', value: 'villa' },
                             { text: 'Duplex', value: 'duplex' },
                             { text: 'Atico Duplex', value: 'duplex penthouse' },
                             { text: 'Loft', value: 'loft' },
                             { text: 'Casa Rustica', value: 'townhouse' },
                             { text: 'Terreno', value: 'plot' }
                           ]} accessibilityKey={'Selecciona Tipo'}
              />
            </Center>
          </HStack>
          <HStack gap={4} width="100%">
            <Center width="49%">
              <DefaultEditableInput text="Dormitorios"
                            value={store.realEstate?.basicInfo.rooms.toString()}
                            type='number'
                            onChangeText={(value: string) => { store.setRooms(value) }}
                            isRequired
                            isDisabled={false}
              />
            </Center>
            <Center width="49%">
              <DefaultEditableInput text="Baños"
                            value={store.basicInfo.bathrooms.toString()}
                            type='number'
                            onChangeText={(value: string) => { store.setBathrooms(value) }}
                            isRequired
              />
            </Center>
          </HStack>
          <HStack gap={4} width="100%">
            <Center width="32%">
              <DefaultEditableInput text="Superfície Util"
                            value={store.realEstate?.basicInfo.livingAreaSize.toString()}
                            type='number'
                            onChangeText={(value: string) => { store.setLivingAreaSize(value) }}
                            isRequired
              />
            </Center>
            <Center width="32%">
              <DefaultEditableInput text="Superfície Construida"
                            value={store.realEstate?.basicInfo.builtAreaSize?.toString()}
                            type='number'
                            onChangeText={(value: string) => { store.setBuildAreaSize(value) }}
                            isRequired
              />
            </Center>
            <Center width="32%">
              <DefaultEditableInput text="Superficie del Terreno"
                            value={store.realEstate?.basicInfo.plotAreaSize?.toString()}
                            type='number'
                            onChangeText={(value: string) => { store.setPlotAreaSize(value) }}
                            isRequired
              />
            </Center>
          </HStack>
          <HStack gap={4} marginY={'1rem'} width="100%">
            <Center width="49%">
              <DefaultEditableInput text="Precio" value={store.realEstate?.basicInfo.price.toString()} onChangeText={(value: string) => { store.setPrice(value) }} />
            </Center>
          </HStack>
          {/* <VStack alignItems='start' width="100%">
            <FormLabel>Descripción</FormLabel>
            <Textarea h={20} placeholder="Descripción" width="100%" borderRadius={borderRadius}
                      value={store.description}
                      onChange={(value) => store.setDescription(value.target.value)}
                      paddingX={'0.5rem'} />
          </VStack> */}
          {/* <HStack width="100%" marginY={'1rem'} flexWrap={'wrap'}>
            <VStack flexGrow={1} alignItems='start'>
              <Checkbox onChange={(event) => {
                if (event.target.checked) {
                  store.addFeature(RealEstateFeature.elevator)
                } else {
                  store.removeFeature(RealEstateFeature.elevator)
                }
              }}>Ascensor</Checkbox>
              <Checkbox onChange={(event) => {
                if (event.target.checked) {
                  store.addFeature(RealEstateFeature.builtInCabinets)
                } else {
                  store.removeFeature(RealEstateFeature.builtInCabinets)
                }
              }}>Armarios empotrados</Checkbox>
              <Checkbox onChange={(event) => {
                if (event.target.checked) {
                  store.addFeature(RealEstateFeature.terrace)
                } else {
                  store.removeFeature(RealEstateFeature.terrace)
                }
              }}>Terraza</Checkbox>
              <Checkbox onChange={(event) => {
                if (event.target.checked) {
                  store.addFeature(RealEstateFeature.garage)
                } else {
                  store.removeFeature(RealEstateFeature.garage)
                }
              }}>Garage</Checkbox>
            </VStack>
            <VStack flexGrow={1} alignItems='start'>
              <Checkbox onChange={(event) => {
                if (event.target.checked) {
                  store.addFeature(RealEstateFeature.balcony)
                } else {
                  store.removeFeature(RealEstateFeature.balcony)
                }
              }}>Balcón</Checkbox>
              <Checkbox onChange={(event) => {
                if (event.target.checked) {
                  store.addFeature(RealEstateFeature.pool)
                } else {
                  store.removeFeature(RealEstateFeature.pool)
                }
              }}>Piscina</Checkbox>
              <Checkbox onChange={(event) => {
                if (event.target.checked) {
                  store.addFeature(RealEstateFeature.storageRoom)
                } else {
                  store.removeFeature(RealEstateFeature.storageRoom)
                }
              }}>Trastero</Checkbox>
              <Checkbox onChange={(event) => {
                if (event.target.checked) {
                  store.addFeature(RealEstateFeature.parkland)
                } else {
                  store.removeFeature(RealEstateFeature.parkland)
                }
              }}>Zonas Verdes</Checkbox>
            </VStack>
            <VStack flexGrow={1} alignItems='start'>
              <Checkbox onChange={(event) => {
                if (event.target.checked) {
                  store.addFeature(RealEstateFeature.airConditioning)
                } else {
                  store.removeFeature(RealEstateFeature.airConditioning)
                }
              }}>A/C</Checkbox>
              <Checkbox onChange={(event) => {
                if (event.target.checked) {
                  store.addFeature(RealEstateFeature.individualHeating)
                } else {
                  store.removeFeature(RealEstateFeature.individualHeating)
                }
              }}>Calefacción individual</Checkbox>
              <Checkbox onChange={(event) => {
                if (event.target.checked) {
                  store.addFeature(RealEstateFeature.centralHeating)
                } else {
                  store.removeFeature(RealEstateFeature.centralHeating)
                }
              }}>Calefacción Central</Checkbox>
              <Checkbox onChange={(event) => {
                if (event.target.checked) {
                  store.addFeature(RealEstateFeature.gymnasium)
                } else {
                  store.removeFeature(RealEstateFeature.gymnasium)
                }
              }}>Gimnasio</Checkbox>
            </VStack>
            <VStack flexGrow={1} alignItems='start'>
              <Checkbox onChange={(event) => {
                if (event.target.checked) {
                  store.addFeature(RealEstateFeature.dressingRoom)
                } else {
                  store.removeFeature(RealEstateFeature.dressingRoom)
                }
              }}>Vestidor</Checkbox>
              <Checkbox onChange={(event) => {
                if (event.target.checked) {
                  store.addFeature(RealEstateFeature.highCeilings)
                } else {
                  store.removeFeature(RealEstateFeature.highCeilings)
                }
              }}>Techos altos</Checkbox>
              <Checkbox onChange={(event) => {
                if (event.target.checked) {
                  store.addFeature(RealEstateFeature.indoor)
                } else {
                  store.removeFeature(RealEstateFeature.indoor)
                }
              }}>Interior</Checkbox>
              <Checkbox onChange={(event) => {
                if (event.target.checked) {
                  store.addFeature(RealEstateFeature.exterior)
                } else {
                  store.removeFeature(RealEstateFeature.exterior)
                }
              }}>Exterior</Checkbox>
            </VStack>
            <VStack flexGrow={1} alignItems='start'>
              <Checkbox onChange={(event) => {
                if (event.target.checked) {
                  store.addFeature(RealEstateFeature.firstFloor)
                } else {
                  store.removeFeature(RealEstateFeature.firstFloor)
                }
              }}>Primeras Plantas</Checkbox>
              <Checkbox onChange={(event) => {
                if (event.target.checked) {
                  store.addFeature(RealEstateFeature.topFloors)
                } else {
                  store.removeFeature(RealEstateFeature.topFloors)
                }
              }}>Últimas Plantas</Checkbox>
            </VStack>
          </HStack> */}
        </VStack>
      </Card>
  )
}

export default RealEstateBasicInfoForm
