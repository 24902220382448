import { Button } from '@chakra-ui/react'
import { colors } from '../constants'
import React from 'react'

export const PrimaryLinkButton = ({ text, onTap, isLoading, isDisabled, isFullWidth }: {
  text: string
  onTap: () => void
  isLoading?: boolean
  isDisabled?: boolean
  isFullWidth?: boolean
}): JSX.Element => {
  function getWidth (fullWidth = false): string {
    return fullWidth ? '100%' : 'fit-content'
  }

  return (
    <Button
      bg={'transparent'}
      border='0'
      borderRadius='1.5rem'
      color={colors.primary['500']}
      fontWeight='bold'
      maxHeight={'2.75rem'}
      minWidth={'4rem'}
      p={'0.5rem 1rem'}
      w={getWidth(isFullWidth)}
      _hover={{
        color: colors.primary['600'],
        cursor: 'pointer'
      }}
      _active={{
        color: colors.primary['700']
      }}
      _disabled={{
        color: colors.coolGray['400'],
        cursor: 'default'
      }}
      isDisabled={isDisabled}
      isLoading={isLoading}
      onClick={onTap}
    >
      {text}
    </Button>
  )
}

export const SecondaryLinkButton = ({ text, onTap, isLoading, isDisabled, isFullWidth }: {
  text: string
  onTap: () => void
  isLoading?: boolean
  isDisabled?: boolean
  isFullWidth?: boolean
}): JSX.Element => {
  function getWidth (fullWidth = false): string {
    return fullWidth ? '100%' : 'fit-content'
  }

  return (
    <Button
      bg={'transparent'}
      border='0'
      borderRadius='1.5rem'
      color={colors.coolGray['700']}
      maxHeight={'2.75rem'}
      minWidth={'4rem'}
      p={'0.5rem 1rem'}
      w={getWidth(isFullWidth)}
      _hover={{
        color: colors.coolGray['800'],
        cursor: 'pointer'
      }}
      _active={{
        color: colors.coolGray['900']
      }}
      _disabled={{
        color: colors.coolGray['400'],
        cursor: 'default'
      }}
      isDisabled={isDisabled}
      isLoading={isLoading}
      onClick={onTap}
    >
      {text}
    </Button>
  )
}
