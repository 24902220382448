import React, { useEffect, useRef, useState } from 'react'
/*eslint-disable */
const RealEstateMapView = ({
  center,
  zoom,
  isHidden
}: {
  center: google.maps.LatLngLiteral | google.maps.LatLng
  zoom: number
  isHidden?: boolean
}): JSX.Element => {
  const ref = useRef<HTMLDivElement>(null)
  const [map, setMap] = useState<google.maps.Map>()
  const [marker, setMarker] = useState<google.maps.Marker>()
  const styles: google.maps.MapTypeStyle[] = []

  styles.push({
    featureType: 'poi',
    stylers: [{ visibility: 'off' }]
  },
  {
    featureType: 'transit',
    elementType: 'labels.icon',
    stylers: [{ visibility: 'off' }]
  })

  useEffect(() => {
    if ((ref.current != null) && (map == null)) {
      const map = new google.maps.Map(
        ref.current,
        {
          zoom: 16,
          center,
          draggable: false,
          panControl: false,
          scrollwheel: false,
          disableDefaultUI: true,
          fullscreenControl: false,
          streetViewControl: false,
          zoomControl: true,
          styles
        }
      )
      setMap(map)
    }
  }, [ref, map])

  useEffect(() => {
    // eslint-disable-next-line no-use-before-define
    if (!isHidden) {
      setMarker(new google.maps.Marker({
        position: center,
        map
      }))
    } else {
      marker?.setMap(null)
      setMarker(undefined)
    }
    map?.panTo(center)

  }, [center, isHidden])

  return (
        <>
         <div id="map" ref={ref} style={{ height: 400, width: 896 }}/>
        </>

  )
}

export default RealEstateMapView
