import React from 'react'

import { Heading, SkeletonText } from '@chakra-ui/react'
import { highColor, mediumColor, xxlSize, xlSize, type TextProps } from './TextProps'

export const Heading3 = ({ text }: TextProps): JSX.Element => (
    <Heading color={mediumColor}
             fontSize={xxlSize}
             fontWeight="normal"
    >
        {text}
    </Heading>
)

export const Heading4 = ({ text }: TextProps): JSX.Element => (
    <Heading color={highColor}
             fontSize={xlSize}
             fontWeight='bold'
    >
        {text}
    </Heading>
)

export const Heading4WithSkeleton = (props: TextProps & { isFetching: boolean }): JSX.Element =>
  props.isFetching
    ? (
    <SkeletonText isLoaded={!props.isFetching}
                  height={xlSize}
                  width='100%'
    >
        <Heading4 {...props}/>
    </SkeletonText>
      )
    : <Heading4 {...props}/>
