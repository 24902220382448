import { type Agent, type RealEstate, type RealEstateId, UserId } from '@howsin/common'
import { BackOfficeApplicationService } from '../../application/BackOfficeApplicationService'
import { MobxStore } from '../../../common/store/MobxStore'
import { action, observable } from 'mobx'
import { AppLifeCyclePublisher } from '../../../event/application/AppLifeCyclePublisher'
import { RealEstates } from '../../../feeds/domain/RealEstates'

export class AgentStore extends MobxStore {
  private static instance: AgentStore
  static getInstance (): AgentStore {
    if (this.instance != null) {
      return this.instance
    }
    this.instance = new AgentStore(
      BackOfficeApplicationService.getInstance(),
      AppLifeCyclePublisher.getInstance()
    )
    return this.instance
  }

  @observable agent: Agent | undefined = undefined
  @observable realEstates: RealEstates = new RealEstates()

  constructor (
    private readonly backOfficeApplicationService: BackOfficeApplicationService,
    private readonly appLifeCyclePublisher: AppLifeCyclePublisher
  ) {
    super()
    this.makeObservable()
  }

  async fetch (agentId: string): Promise<void> {
    const userId = new UserId(agentId)
    this.startFetching()
    await Promise.all([
      this.backOfficeApplicationService.getAgentDetail(userId),
      this.backOfficeApplicationService.getRealEstates(userId)
    ])
      .then(data => {
        this.setAgent(data[0])
        this.setRealEstates(data[1])
      }).finally(() => {
        this.endFetching()
      })
  }

  async generateAndCopyToken (id: UserId): Promise<void> {
    const token = await this.backOfficeApplicationService.getToken(id)
    await navigator.clipboard.writeText(token)
  }

  @action
  private setAgent (agent: Agent): void {
    this.agent = agent
  }

  @action
  private setRealEstates (realEstates: RealEstate[]): void {
    this.realEstates?.add(...realEstates)
  }

  @action
  clean (): void {
    this.agent = undefined
    this.realEstates = new RealEstates()
  }

  openRealEstate (id: RealEstateId): void {
    this.appLifeCyclePublisher.goToViewRealEstateAdmin(id)
  }

  async publish (id: RealEstateId): Promise<void> {
    await this.backOfficeApplicationService.publish(id)
  }

  createRealEstate (): void {
    this.appLifeCyclePublisher.goToCreateRealEstate()
  }

  editRealEstate (id: RealEstateId): void {
    this.appLifeCyclePublisher.goToEditRealEstateAdmin(id)
  }

  @action
  async archive (id: RealEstateId): Promise<void> {
    this.startFetching()
    this.backOfficeApplicationService.archive(id)
      .then((promise) => {
        void this.fetch(this.agent?.id.toString() ?? '')
        return promise
      }).catch((err) => {
        return err
      }
      )
    this.endFetching()
  }
}
