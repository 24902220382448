import { MobxStore } from '../../../../../common/store/MobxStore'
import { action, observable } from 'mobx'
import { BackOfficeApplicationService } from '../../../../application/BackOfficeApplicationService'
import { CampaingId } from '@howsin/common'

export class CreateCampaingStore extends MobxStore {
  private static instance: CreateCampaingStore
  static getInstance (): CreateCampaingStore {
    if (this.instance != null) {
      return this.instance
    }
    this.instance = new CreateCampaingStore(
      BackOfficeApplicationService.getInstance()
    )
    return this.instance
  }

  @observable isValid = false
  @observable advertiserId: string = ''
  @observable title: string = ''
  @observable description: string = ''
  @observable startAt: string = ''
  @observable endAt: string = ''
  @observable website: string = ''
  @observable media: File | undefined

  constructor (
    private readonly backOfficeApplicationService: BackOfficeApplicationService
  ) {
    super()
    this.makeObservable()
  }

  @action
  setAdvertiserId (value: string): void {
    this.advertiserId = value
    this.validatingData()
  }

  @action
  setTitle (value: string): void {
    this.title = value
    this.validatingData()
  }

  @action
  setDescription (value: string): void {
    this.description = value
    this.validatingData()
  }

  @action
  setInitDate (value: string): void {
    this.startAt = value
    this.validatingData()
  }

  @action
  setEndDate (value: string): void {
    this.endAt = value
    this.validatingData()
  }

  @action
  setWebsite (value: string): void {
    this.website = value
    this.validatingData()
  }

  @action
  private validatingData (): void {
    this.isValid = this.title !== '' && this.description !== '' && this.startAt !== '' && this.endAt !== '' && this.website !== '' && this.media !== undefined && this.advertiserId !== ''
  }

  @action
  setCampaingMedia (file: File): void {
    this.media = file
    this.validatingData()
  }

  clean (): void {
    this.title = ''
    this.description = ''
    this.startAt = ''
    this.endAt = ''
    this.website = ''
    this.isValid = false
    this.media = undefined
  }

  async createCampaingAndAddMedia (): Promise<void> {
    if (this.isValid && this.media != null) {
      try {
        this.startFetching()
        const id = new CampaingId()
        console.log('Creating campaing with id', id)
        // Create Campaing
        await this.backOfficeApplicationService.createCampaing(
          id,
          this.advertiserId,
          this.title,
          this.description,
          this.startAt,
          this.endAt,
          this.website)
        // change state to Draft
        await this.backOfficeApplicationService.toDraftCampaing(id)
        console.log('Campaing created on Draft state')
        // upload Video
        const video = await this.backOfficeApplicationService.uploadAdMedia(id, this.media)
        // create Ad with media
        await this.backOfficeApplicationService.createAd(
          id,
          this.advertiserId,
          this.title,
          this.description,
          this.website,
          video.video,
          video.aspectRatio,
          video.duration)
        console.log('Ad media upload and ad created')
        await this.backOfficeApplicationService.toReviewCampaing(id)
        console.log('Campaing change to Review state')
      } catch (error) {
        console.log('Error creating campaing', error)
      } finally {
        this.endFetching()
      }
    }
  }
}
