import SecondaryButton from '../button/SecondaryButton'
import React from 'react'
import { NavbarAgentStore } from '../../common/store/navbar/NavbarAgentStore'

export const NavbarAgent = ({ store = NavbarAgentStore.getInstance() }: { store?: NavbarAgentStore }): JSX.Element => (<>
  <SecondaryButton text={'+ Nueva publicación'} onTap={() => { store.createRealEstate() } }/>
  {/* <IconButton icon={'heart'} accessibilityName={'Likes'} onTap={() => { */}
  {/* }}/> */}
  {/* <IconButton icon={'bell'} accessibilityName={'Notificaciones'} onTap={() => { */}
  {/* }}/> */}
  {/* <IconButton icon={'settings'} accessibilityName={'Ajustes'} onTap={() => { */}
  {/* }}/> */}
</>)
