import { type Agent, type UserId } from '@howsin/common'
import { BackOfficeApplicationService } from '../../application/BackOfficeApplicationService'
import { MobxStore } from '../../../common/store/MobxStore'
import { action, observable } from 'mobx'
import { AppLifeCyclePublisher } from '../../../event/application/AppLifeCyclePublisher'

export class AgentsStore extends MobxStore {
  private static instance: AgentsStore

  static getInstance (): AgentsStore {
    if (this.instance != null) {
      return this.instance
    }
    this.instance = new AgentsStore(
      BackOfficeApplicationService.getInstance(),
      AppLifeCyclePublisher.getInstance()
    )
    return this.instance
  }

  @observable agents: Agent[] = []

  constructor (
    private readonly backOfficeApplicationService: BackOfficeApplicationService,
    private readonly appLifeCyclePublisher: AppLifeCyclePublisher
  ) {
    super()
    this.makeObservable()
  }

  async fetch (): Promise<void> {
    this.startFetching()
    const agents = await this.backOfficeApplicationService.getAgents()
    this.setAgents(agents)
    this.endFetching()
  }

  async generateAndCopyToken (id: UserId): Promise<void> {
    const token = await this.backOfficeApplicationService.getToken(id)
    await navigator.clipboard.writeText(token)
  }

  @action
  private setAgents (agents: Agent[]): void {
    this.agents = agents
  }

  goToCreateAgent (): void {
    this.appLifeCyclePublisher.goToCreateAgent()
  }
}
