import { colors } from '../constants'

export const containerStyle = {
  display: 'flex',
  flexGrow: 1,
  marginTop: '0.5rem'
}

export const backgroundColor = colors.white
export const borderColor = colors.coolGray['400']
export const borderRadius = '0.25rem'
export const borderWidth = '0.5px'
export const padding = '0.25rem 0.5rem'
