import React from 'react'
import { Center, Grid, GridItem, VStack } from '@chakra-ui/react'
import { Outlet, useNavigate } from 'react-router-dom'
import { type LinkItemProps, SidebarContent } from '../../../ui/sidebar/SidebarContent'
import { FiPieChart, FiSearch, FiShare, FiUsers, FiAward } from 'react-icons/fi'

const boxShadow = {
  boxShadow: '1px 2px 9px #E2E8F0'
}

export const BackOffice = (): JSX.Element => {
  const navigate = useNavigate()
  const items: LinkItemProps[] = [
    {
      name: 'Agentes',
      icon: FiUsers,
      onClick: () => { navigate('/backOffice/agents') }
    },
    {
      name: 'Validar',
      icon: FiShare,
      onClick: () => { navigate('/backOffice/feeds') }
    },
    {
      name: 'Search',
      icon: FiSearch,
      onClick: () => { navigate('/backOffice/search') }
    },
    {
      name: 'Advertisers',
      icon: FiAward,
      onClick: () => { navigate('/backOffice/advertisers') }
    },
    {
      name: 'Dashboard',
      icon: FiPieChart,
      onClick: () => { navigate('/backOffice/dashboard') }
    }
  ]
  return (<Grid
    templateAreas={'"nav main"'}
    gridTemplateRows={'1fr'}
    gridTemplateColumns={'200px 1fr'}
    h='100%'
    gap='1'
    fontWeight='bold'
  >
    <GridItem style={boxShadow} area={'nav'}>
      <SidebarContent items={items}/>
    </GridItem>
    <GridItem padding='2' area={'main'}>
      <Center
        alignItems='center'
        padding='2rem'
        width='100%'
      >
        <VStack
          alignItems='flex-start'
          gap={4}
          justifyContent='flex-start'
          maxWidth='60rem'
          width='100%'
        >
          <Outlet />
        </VStack>
      </Center>
    </GridItem>
  </Grid>
  )
}
