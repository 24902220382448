import React, { useEffect, useState } from 'react'
import BackButton from '../../../../ui/button/BackButton'
import { RealEstateDetailStore } from './RealEstateDetailStore'
// import { observer } from 'mobx-react'
import RealEstateGalleryCard from './RealEstateGalleryCard'
import RealEstateContentCard from './RealEstateContentCard'
import { useParams } from 'react-router-dom'
import {
  Center,
  Spinner,
  VStack
} from '@chakra-ui/react'
import { AuthStore } from '../../../../auth/infrastructure/view/AuthStore'
import { RealEstateManageCard } from './RealEstateManageCard'
import RealEstateAgentHeader from './RealEstateAgentHeader'
import { type RealEstate } from '@howsin/common'
import { colors } from '../../../../ui/constants'
import { RealEstateMetadataCard } from './RealEstateMetadataCard'

export const RealEstateStoreContext = React.createContext< RealEstateDetailStore>(RealEstateDetailStore.create())

const FeedDetail = (): JSX.Element => {
  const store = React.useContext(RealEstateStoreContext)
  const { realEstateId } = useParams()
  const [realEstate, setRealEstate] = useState<RealEstate | null >(null)

  useEffect(() => {
    async function fetch (): Promise<void> {
      if (realEstateId != null) {
        store.fetch(realEstateId)
          .then((realEstate) => {
            setRealEstate(realEstate)
          }).catch((error) => {
            throw error
          })
      }
    }
    void fetch()

    return () => {
      setRealEstate(null)
    }
  }, [realEstateId])

  if (realEstate == null) {
    return (
      <>
        <Spinner
              thickness='4px'
              speed='0.65s'
              emptyColor={colors.coolGray[400]}
              color={colors.primary[400]}
              size='xl'
            />
      </>)
  }

  return (
      <RealEstateStoreContext.Provider value={store}>
          <BackButton text="Detalles" />
          { AuthStore.getInstance().isAdmin &&
            <Center padding={4} shadow={3} borderRadius={4} width="100%">
              <RealEstateAgentHeader user={store.realEstate?.agent}/>
            </Center>
          }
          <Center padding={4} shadow={3} borderRadius={4} width="100%">
            <RealEstateGalleryCard/>
          </Center>
          { AuthStore.getInstance().isAdmin &&
            <VStack padding={4} shadow={3} borderRadius={4} width="100%">
              <RealEstateManageCard/>
              <RealEstateMetadataCard realEstate={store.realEstate}/>
            </VStack>
          }
          <VStack padding={4} shadow={3} borderRadius={4} width="100%">
            <RealEstateContentCard/>
          </VStack>
      </RealEstateStoreContext.Provider>
  )
}
export default FeedDetail
