import { Card, HStack, Center, Box, Modal, ModalBody, ModalContent, ModalOverlay, Spinner } from '@chakra-ui/react'
import { observer } from 'mobx-react'
import React, { useEffect } from 'react'
import { useGoBack } from '../../../../../common/react/hooks/useGoBack'
import BackButton from '../../../../../ui/button/BackButton'
import PrimaryButton from '../../../../../ui/button/PrimaryButton'
import DefaultInput from '../../../../../ui/inputs/DefaultInput'
import { DropZoneFile } from '../../../../../ui/inputs/DropZoneFile'
import { Subtitle } from '../../../../../ui/text/BodyTextComponent'
import { CreateAdvertiserStore } from './CreateAdvertiserStore'
import { colors } from '../../../../../ui/constants'

const createStyles = {
  dropArea: {
    width: '100%',
    height: 40,
    backgroundColor: colors.coolGray['200'],
    marginVertical: 8,
    borderRadius: 8
  }
}

export const CreateAdvertiser = observer(({ store = CreateAdvertiserStore.getInstance() }: { store?: CreateAdvertiserStore }): JSX.Element => {
  const goBack = useGoBack()
  useEffect(() => () => { store.clean() }, [store])
  return (
        <Box minHeight='100vh'>
            <BackButton text="Nuevo anunciante" />

            <Card>
                <Box my={8} padding={4} shadow={3} borderRadius={4} minWidth="100%">
                    <Subtitle text="Anunciante" />
                    <HStack gap={4} marginY={'1rem'} width="100%">
                    <Center flexGrow={1}>
                        <DefaultInput text="Nombre" isRequired value={store.name} onChangeText={(value) => { store.setName(value) }} />
                    </Center>
                    <Center flexGrow={1}>
                        <DefaultInput text="Nick name" isRequired onChangeText={(value) => { store.setNickName(value) }} />
                    </Center>
                    <Center flexGrow={4}>
                        <DefaultInput text="Email" isRequired onChangeText={(value) => { store.setEmail(value) }} />
                    </Center>
                    <Center flexGrow={4}>
                        <DefaultInput text="Sitio web" onChangeText={(value) => { store.setWebsite(value) }} />
                    </Center>
                    </HStack>
                </Box>
            </Card>

            <Card>
                <Box my={8} padding={4} shadow={3} borderRadius={4} width="100%">
                    <Subtitle text="Avatar" />
                    <Center style={createStyles.dropArea}>
                    <DropZoneFile onChange={(file) => { store.setAvatar(file) }}/>
                    </Center>
                </Box>
            </Card>
            <PrimaryButton isFullWidth text={'Crear'} isDisabled={!store.isValid} onTap={() => {
              void store.createAdvertiser().then(() => { goBack() })
            } } />

        <Modal isOpen={store.isFetching} onClose={() => {}}>
            <ModalOverlay />
                <ModalContent>
                    <ModalBody>
                        <Spinner
                            thickness='4px'
                            speed='0.65s'
                            emptyColor='gray.200'
                            size='xl'
                        />
                    </ModalBody>
                </ModalContent>
        </Modal>
    </Box>
  )
})
