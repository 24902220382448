import { observer } from 'mobx-react'
import React, { useEffect } from 'react'
import { DashboardStore } from './DashboardStore'
import { VStack, Wrap, WrapItem } from '@chakra-ui/react'
import Card from '../../../ui/card/Card'
import {
  Chart as ChartJS,
  ArcElement,
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
} from 'chart.js'
import { Doughnut, Line, Bar } from 'react-chartjs-2'
import { Heading3 } from '../../../ui/text/TitleComponent'

ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Filler)

export const Dashboard = observer(({ store = DashboardStore.getInstance() }: { store?: DashboardStore }): JSX.Element => {
  useEffect(() => {
    void store.fetch()
  }, [store])

  return (<VStack>
    <h1>Dashboard</h1>
    <Wrap>
      <WrapItem>
        <Card>
          <Heading3 text="Nuevos usuarios" />
          {!store.isFetching && <Line width='600px' options={{
            responsive: true,
            plugins: {
              legend: {
                display: false
              },
              title: {
                display: false
              }
            }
          }} data={store.newBuyers()} />}
        </Card>
      </WrapItem>
      <WrapItem>
        <Card>
          <Heading3 text="Overview" />
          {!store.isFetching && <Bar width='600px' options={{
            responsive: true,
            plugins: {
              legend: {
                display: false
              },
              title: {
                display: false
              }
            }
          }} data={store.overview()} />}
        </Card>
      </WrapItem>
      <WrapItem>
        <Card>
          <Heading3 text="Visualización" />
          {!store.isFetching && <Doughnut options={{
            plugins: {
              legend: {
                display: false
              },
              title: {
                display: false
              }
            }
          }} data={store.views()} />}
        </Card>
      </WrapItem>
      <WrapItem>
        <Card>
          <Heading3 text="Likes" />
          {!store.isFetching && <Doughnut options={{
            plugins: {
              legend: {
                display: false
              },
              title: {
                display: false
              }
            }
          }} data={store.likes()} />}
        </Card>
      </WrapItem>
      <WrapItem>
        <Card>
          <Heading3 text="Favoritos" />
          {!store.isFetching && <Doughnut options={{
            plugins: {
              legend: {
                display: false
              },
              title: {
                display: false
              }
            }
          }} data={store.favorites()} />}
        </Card>
      </WrapItem>
      <WrapItem>
        <Card>
          <Heading3 text="Compartidos" />
          {!store.isFetching && <Doughnut options={{
            plugins: {
              legend: {
                display: false
              },
              title: {
                display: false
              }
            }
          }} data={store.shared()} />}
        </Card>
      </WrapItem>
    </Wrap>
  </VStack>)
})
