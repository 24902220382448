import React from 'react'
import { SkeletonText, Text } from '@chakra-ui/react'
import { highColor, mediumColor, maxWidth, xlSize, lgSize, mdSize, xsSize, type TextProps } from './TextProps'

export const Subtitle = ({ text }: TextProps): JSX.Element => (
  <Text color={highColor}
        fontSize={xlSize}
        fontWeight='bold'
        maxWidth={maxWidth}
        mb={2}
        mt={0}
    >
    {text}
  </Text>
)

export const Label = ({ text }: TextProps): JSX.Element => (
  <Text color={highColor}
        fontSize={mdSize}
        fontWeight='medium'
        maxWidth={maxWidth}
        mb={2}
        mt={0}
  >
    {text}
  </Text>
)

export const LabelWithSkeleton = (props: TextProps & { isFetching: boolean }): JSX.Element => (
  <SkeletonText height={mdSize}
                isLoaded={!props.isFetching}
  >
    <Label {...props}/>
  </SkeletonText>
)

export const Body = ({ text }: TextProps): JSX.Element => (
  <Text color={mediumColor}
        fontSize={lgSize}
        fontWeight="normal"
        maxWidth={maxWidth}
        mb={2}
        mt={0}
  >
    {text}
  </Text>
)

export const BodyWithSkeleton = (props: TextProps & { isFetching: boolean, numOfLines?: number }): JSX.Element =>
  props.isFetching
    ? (
  <SkeletonText height={lgSize}
                isLoaded={!props.isFetching}
                noOfLines={props.numOfLines}
  >
    <Body {...props}/>
  </SkeletonText>
      )
    : <Body {...props}/>

export const Caption = ({ text }: TextProps): JSX.Element => (
  <Text color={mediumColor}
        fontSize={xsSize}
        fontWeight='normal'
        maxWidth={maxWidth}
        mb={2}
        mt={0}
  >
    {text}
  </Text>
)

export const CaptionWithSkeleton = (props: TextProps & { isFetching: boolean }): JSX.Element => (
  <SkeletonText height={xsSize}
                isLoaded={!props.isFetching}
  >
    <Caption {...props}/>
  </SkeletonText>
)
