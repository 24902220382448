import { type EventBusService } from '../../domain/EventBusService'
import { InMemoryEventBus } from './InMemoryEventBus'

let instance: EventBusService | undefined

export const getInstance = (): EventBusService => {
  if (instance != null) {
    return instance
  }
  instance = new InMemoryEventBus()
  return instance
}
