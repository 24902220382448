import { type PasswordlessService } from '../../../../domain/PasswordlessService'
import {
  getAuth,
  sendSignInLinkToEmail,
  signInWithEmailLink,
  isSignInWithEmailLink
} from 'firebase/auth'
import { LoginProvider } from '../provider/LoginProvider'
import { BackendServiceProvider } from '../../../../../common/service/BackendServiceProvider'

export class FirebasePasswordlessService extends LoginProvider implements PasswordlessService {
  private static instance: FirebasePasswordlessService

  static getInstance (): FirebasePasswordlessService {
    if (this.instance != null) {
      return this.instance
    }
    this.instance = new FirebasePasswordlessService(
      BackendServiceProvider.getInstance()
    )
    return this.instance
  }

  async startLogin (email: string): Promise<void> {
    const actionCodeSettings = {
      url: window.location.origin + '/finishSignUp',
      handleCodeInApp: true,
      dynamicLinkDomain: 'howsin.page.link'
    }
    const auth = getAuth()
    await sendSignInLinkToEmail(auth, email, actionCodeSettings)
    window.localStorage.setItem('emailForSignIn', email)
  }

  async getCredential (): Promise<string> {
    const auth = getAuth()
    if (isSignInWithEmailLink(auth, window.location.href)) {
      const email = window.localStorage.getItem('emailForSignIn')
      if (email == null) {
        throw new Error('Invalid browser')
      }
      const credential = await signInWithEmailLink(auth, email, window.location.href)
      window.localStorage.removeItem('emailForSignIn')
      return await credential.user.getIdToken()
    }
    throw new Error('Invalid link')
  }
}
