import React from 'react'
import { text } from '../constants'
import AppleIcon from './AppleIcon'
import BathIcon from './BathIcon'
import BackIcon from './BackIcon'
import BellIcon from './BellIcon'
import BookmarkIcon from './BookmarkIcon'
import CheckCircleIcon from './CheckCircleIcon'
import ChevronDownIcon from './ChevronDownIcon'
import ChevronLeftIcon from './ChevronLeftIcon'
import ChevronRightIcon from './ChevronRightIcon'
import ChevronUpIcon from './ChevronUpIcon'
import DiscordIcon from './DiscordIcon'
import FacebookIcon from './FacebookIcon'
import FloorIcon from './FloorIcon'
import GoogleIcon from './GoogleIcon'
import HeartIcon from './HeartIcon'
import InstagramIcon from './InstagramIcon'
import LocationIcon from './LocationIcon'
import LogoutIcon from './LogoutIcon'
import MenuIcon from './MenuIcon'
import PlusIcon from './PlusIcon'
import RoomIcon from './RoomIcon'
import SettingsIcon from './SettingsIcon'
import ShareIcon from './ShareIcon'
import SizeIcon from './SizeIcon'
import TwitterIcon from './TwitterIcon'
import RubbishIcon from './RubbishIcon'
import EditIcon from './EditIcon'
import PublishIcon from './PublishIcon'

const IconComponent = ({ icon, isLarge, isExtraLarge, color }: {
  icon: string
  isLarge?: boolean
  isExtraLarge?: boolean
  color?: string
}): JSX.Element => {
  const getSize = (): string => {
    if (isLarge === true) {
      return '2rem'
    }
    if (isExtraLarge === true) {
      return '4rem'
    }
    return '1rem'
  }

  const props: { name: string, color: string } = {
    name: icon,
    color: color ?? text.color
  }
  const size = getSize()

  if (icon === 'apple') {
    return (
            <AppleIcon boxSize={size} color={props.color}/>
    )
  }
  if (icon === 'back') {
    return (
            <BackIcon boxSize={size} color={props.color}/>
    )
  }
  if (icon === 'bath') {
    return (
            <BathIcon boxSize={size} color={props.color}/>
    )
  }
  if (icon === 'bell') {
    return (
            <BellIcon boxSize={size} color={props.color}/>
    )
  }
  if (icon === 'bookmark') {
    return (
            <BookmarkIcon boxSize={size} color={props.color}/>
    )
  }
  if (icon === 'checkCircle') {
    return (
            <CheckCircleIcon boxSize={size} color={props.color}/>
    )
  }
  if (icon === 'chevronDown') {
    return (
            <ChevronDownIcon boxSize={size} color={props.color}/>
    )
  }
  if (icon === 'chevronLeft') {
    return (
            <ChevronLeftIcon boxSize={size} color={props.color}/>
    )
  }
  if (icon === 'chevronRight') {
    return (
            <ChevronRightIcon boxSize={size} color={props.color}/>
    )
  }
  if (icon === 'chevronUp') {
    return (
            <ChevronUpIcon boxSize={size} color={props.color}/>
    )
  }
  if (icon === 'discord') {
    return (
            <DiscordIcon boxSize={size} color={props.color}/>
    )
  }
  if (icon === 'facebook') {
    return (
            <FacebookIcon boxSize={size} color={props.color}/>
    )
  }
  if (icon === 'floor') {
    return (
            <FloorIcon boxSize={size} color={props.color}/>
    )
  }
  if (icon === 'google') {
    return (
            <GoogleIcon boxSize={size} color={props.color}/>
    )
  }
  if (icon === 'heart') {
    return (
            <HeartIcon boxSize={size} color={props.color}/>
    )
  }
  if (icon === 'instagram') {
    return (
            <InstagramIcon boxSize={size} color={props.color}/>
    )
  }
  if (icon === 'location') {
    return (
            <LocationIcon boxSize={size} color={props.color}/>
    )
  }
  if (icon === 'logout') {
    return (
            <LogoutIcon boxSize={size} color={props.color}/>
    )
  }
  if (icon === 'menu') {
    return (
            <MenuIcon boxSize={size} color={props.color}/>
    )
  }
  if (icon === 'plus') {
    return (
            <PlusIcon boxSize={size} color={props.color}/>
    )
  }
  if (icon === 'room') {
    return (
            <RoomIcon boxSize={size} color={props.color}/>
    )
  }
  if (icon === 'settings') {
    return (
            <SettingsIcon boxSize={size} color={props.color}/>
    )
  }
  if (icon === 'share') {
    return (
            <ShareIcon boxSize={size} color={props.color}/>
    )
  }
  if (icon === 'size') {
    return (
            <SizeIcon boxSize={size} color={props.color}/>
    )
  }
  if (icon === 'twitter') {
    return (
            <TwitterIcon boxSize={size} color={props.color}/>
    )
  }
  if (icon === 'rubbish') {
    return (
            <RubbishIcon boxSize={size} color={props.color}/>
    )
  }
  if (icon === 'edit') {
    return (
            <EditIcon boxSize={size} color={props.color}/>
    )
  }
  if (icon === 'publish') {
    return (
            <PublishIcon boxSize={size} color={props.color}/>
    )
  }
  return <></>
}

export default IconComponent
