import { Icon } from '@chakra-ui/react'
import * as React from 'react'

const BookmarkIcon = ({ boxSize, color }: {
  boxSize: number | string
  color: string
}): JSX.Element => (
    <Icon viewBox="0 0 463.273 463.273" boxSize={boxSize} color={color}>
        <path
            fill='currentColor'
            d="M313.874 0H149.398c-16.28 0-29.477 13.197-29.476 29.477v422.368a11.429 11.429 0 0 0 19.151 8.423l84.84-77.788a11.428 11.428 0 0 1 15.446 0l84.84 77.788a11.427 11.427 0 0 0 19.151-8.423h.001V29.477C343.351 13.197 330.154 0 313.874 0z"
        />
    </Icon>
)

export default BookmarkIcon
