import React, { useState } from 'react'
import { Tooltip, Link } from '@chakra-ui/react'
import { type TextProps } from './TextProps'

function copyToClipboard (text: string | undefined, setTooltipOpen: (open: boolean) => void): void {
  if (text == null) {
    return
  }
  navigator.clipboard.writeText(text)
    .then(() => {
      console.log('Texto copiado al portapapeles')
      setTooltipOpen(true)
      setTimeout(() => { setTooltipOpen(false) }, 2000) // Cierra el tooltip después de 2 segundos
    })
    .catch(err => {
      console.error('Error al copiar texto: ', err)
    })
}

export const CopyTextComponent = ({ text }: TextProps): JSX.Element => {
  const [isTooltipOpen, setTooltipOpen] = useState(false)

  return (
    <Tooltip label="Copiado!" isOpen={isTooltipOpen} placement="top">
      <Link color="gray.600" onClick={() => { copyToClipboard(text, setTooltipOpen) }}>
        {text}
      </Link>
    </Tooltip>
  )
}
