import { getAuth, signInWithPopup, OAuthProvider } from 'firebase/auth'
import { type BackendService } from '../../../../../common/service/BackendService'
import { BackendServiceProvider } from '../../../../../common/service/BackendServiceProvider'
import { LoginProvider } from './LoginProvider'

export class AppleLoginProvider extends LoginProvider {
  async getCredential (): Promise<string> {
    const auth = getAuth()
    const result = await signInWithPopup(auth, this.provider)

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return OAuthProvider.credentialFromResult(result)!.accessToken!
  }

  private static instance: AppleLoginProvider

  static getInstance (): AppleLoginProvider {
    if (this.instance != null) {
      return this.instance
    }
    this.instance = new AppleLoginProvider(BackendServiceProvider.getInstance())
    return this.instance
  }

  private readonly provider: OAuthProvider

  private constructor (backendService: BackendService) {
    super(backendService)
    this.provider = new OAuthProvider('apple.com')
    this.provider.addScope('email')
  }
}
