export enum Events {
  goToAdminDashboard = 'goToAdminDashboard',
  goToAgentDashboard = 'goToAgentDashboard',
  goToViewRealEstateAgent = 'goToViewRealEstateAgent',
  goToCreateRealEstate = 'goToCreateRealEstate',
  goToCreateAgent = 'goToCreateAgent',
  goToViewRealEstateAdmin = 'goToViewRealEstateAdmin',
  goToEditRealEstateAdmin = 'goToEditRealEstateAdmin',
  goToAgentDetailsAdmin = 'goToAgentDetailsAdmin',
  goToCreateAdvertiser = 'goToCreateAdvertiser',
  goToAdvertisersList = 'goToAdvertisersList',
  goToAdvertiserDetail = 'goToAdvertiserDetail',
  goToCreateCampaing = 'goToCreateCampaing'
}
