import React from 'react'
import { HStack, VStack } from '@chakra-ui/react'

import { SecondaryLinkButton } from '../button/LinkButton'
import { HowsinIcon } from '../icon/HowsinIcon'
import { Label } from '../text/BodyTextComponent'
import { colors } from '../constants'

const footerStyles = {
  container: {
    height: 'fit-content',
    padding: 10
  },
  logoContainer: {
    height: '100%',
    maxWidth: 200,
    maxHeight: 'inherit',
    paddingVertical: 8,
    paddingHorizontal: 16
  },
  labelContent: {
    maxWidth: 280,
    paddingLeft: '0.25rem',
    paddingVertical: 10
  },
  socialsTitle: {
    paddingRight: 10
  }
}

const Footer = (): JSX.Element => (
  <VStack bg={colors.primary['400']} style={footerStyles.container}>
    <HStack alignItems='flex-start' w='100%' justifyContent={'space-between'}>
      <VStack alignItems='flex-start'>
        <HStack style={footerStyles.logoContainer}>
          <HowsinIcon/>
        </HStack>
      </VStack>
    </HStack>
    <HStack w='100%' h='0.5px' backgroundColor={colors.coolGray['600']} marginY='1rem'/>
    <HStack w='100%' px='0.25rem' justifyContent={'space-between'} alignItems='baseline'>
      <Label text='©2023 Howsin Online Technologies. Todos los derechos reservados'/>
      <HStack spacing={4} alignItems={'flex-end'}>
        <SecondaryLinkButton text='Privacy & Policy' onTap={() => {
          window.open('https://howsin.com/politica-privacidad/', '_blank')
        }}/>
        <SecondaryLinkButton text='Terms & Conditions' onTap={() => {
          window.open('https://howsin.com/terminos-y-condiciones/', '_blank')
        }}/>
      </HStack>
    </HStack>
  </VStack>
)
export default Footer
