import { VStack, Center, HStack, SimpleGrid, Box, Image } from '@chakra-ui/react'
import React, { useState } from 'react'
import Card from '../../../../ui/card/Card'
import { DropZoneFile } from '../../../../ui/inputs/DropZoneFile'
import { colors } from '../../../../ui/constants'
import { RealEstateStoreContext } from './RealEstateDetail'

const createStyles = {
  dropArea: {
    backgroundColor: colors.coolGray['200'],
    borderRadius: 8,
    cursor: 'pointer',
    minHeight: 80,
    marginVertical: 8,
    width: '100%'
  }
}
const RealEstateFileInput = (): JSX.Element => {
  const store = React.useContext(RealEstateStoreContext)
  const [files, setFiles] = useState(store.images ?? [])

  const handleFileChange = (file: File): void => {
    store.addImageToImages(file)
    setFiles([...store.images])
  }

  const handleRemoveFile = (file: File): void => {
    store.removeImageFromImages(file)
    setFiles([...store.images])
  }

  return (
    <Card>
        <VStack alignItems='start' gap={4} my={8} width="100%">
            <Center style={createStyles.dropArea}>
                <DropZoneFile onChange={(file) => { handleFileChange(file) }} isMultiFiles/>
            </Center>
        </VStack>
        { files.length !== 0 && (
        <HStack maxWidth='inherit' display={'flex'} overflowX='scroll'>
            <SimpleGrid columns={7} spacing={3}>
                {files.map((image) =>
                    <Box width="75px" height="75px" key={image.name}>
                        <Image width="100%" height="100%" objectFit="cover" onClick={() => { handleRemoveFile(image) }} src={URL.createObjectURL(image)} alt=""/>
                    </Box>
                )}
            </SimpleGrid>
        </HStack>
        )}
    </Card>
  )
}

export default RealEstateFileInput
