import { MobxStore } from '../../../../common/store/MobxStore'
import { action, observable } from 'mobx'
import { BackOfficeApplicationService } from '../../../application/BackOfficeApplicationService'
import { UrlImage } from '@howsin/common'

export class CreateAgentStore extends MobxStore {
  private static instance: CreateAgentStore
  static getInstance (): CreateAgentStore {
    if (this.instance != null) {
      return this.instance
    }
    this.instance = new CreateAgentStore(
      BackOfficeApplicationService.getInstance()
    )
    return this.instance
  }

  @observable isValid = false
  @observable name = ''
  @observable nickName = ''
  @observable email = ''
  @observable mobile = ''
  @observable instagram?: string
  @observable avatar?: File

  constructor (
    private readonly backOfficeApplicationService: BackOfficeApplicationService
  ) {
    super()
    this.makeObservable()
  }

  @action
  clean (): void {
    this.isValid = false
    this.name = ''
    this.nickName = ''
    this.email = ''
    this.mobile = ''
    this.instagram = undefined
    this.avatar = undefined
  }

  @action
  setName (value: string): void {
    this.name = value
    this.validatingData()
  }

  @action
  setNickName (value: string): void {
    this.nickName = value
    this.validatingData()
  }

  @action
  setEmail (value: string): void {
    this.email = value
    this.validatingData()
  }

  @action
  setMobile (value: string): void {
    this.mobile = value
    this.validatingData()
  }

  @action
  setAvatar (file: File): void {
    this.avatar = file
    this.validatingData()
  }

  @action
  setInstagram (value: string): void {
    this.instagram = value
    this.validatingData()
  }

  @action
  private validatingData (): void {
    this.isValid = this.name !== '' && this.nickName !== '' && this.email !== '' && this.mobile !== '' && this.avatar != null
  }

  async send (): Promise<void> {
    if (this.isValid && this.avatar != null) {
      this.startFetching()
      const userId = await this.backOfficeApplicationService.createAgent(
        this.name,
        this.nickName,
        this.email,
        this.mobile,
        this.avatar,
        this.instagram
      )
      const avatar = await this.backOfficeApplicationService.uploadAvatar(
        userId, this.avatar
      )
      await this.backOfficeApplicationService.sendAvatar(userId, new UrlImage(
        avatar.getUrl()
      ))
      this.endFetching()
    }
  }
}
