import { HttpResponse } from './HttpResponse'

export class FetchHttpService {
  private static instance: FetchHttpService
  private token?: string

  static getInstance (): FetchHttpService {
    if (this.instance != null) {
      return this.instance
    }
    this.instance = new FetchHttpService()
    return this.instance
  }

  async get (path: string): Promise<HttpResponse> {
    return await this.fetch(path)
  }

  async getWithToken (path: string): Promise<HttpResponse> {
    return await this.fetch(path, { withAuthorization: true })
  }

  async postWithToken (path: string, data?: any): Promise<HttpResponse> {
    return await this.fetch(path, { method: 'POST', withAuthorization: true, data })
  }

  async putWithToken (path: string, data?: any): Promise<HttpResponse> {
    return await this.fetch(path, { method: 'PUT', withAuthorization: true, data })
  }

  async post (path: string): Promise<HttpResponse> {
    return await this.fetch(path, { method: 'POST' })
  }

  private async fetch (
    path: string,
    {
      withAuthorization,
      method,
      data
    }: { withAuthorization?: boolean, method?: 'POST' | 'DELETE' | 'GET' | 'PUT', data?: any } = {}
  ): Promise<HttpResponse> {
    const headers = await this.getHeaders(withAuthorization)
    this.isPost(method) && headers.append('Content-Type', 'application/json')
    const body = this.isPost(method) ? JSON.stringify(data ?? {}) : undefined
    const response = await fetch(path, { headers, method: method ?? 'GET', body })
    if (response.status > 401) {
      throw new Error('Fail backend')
    }
    const bodyResponse = await this.getBody(response)
    return new HttpResponse(response.status, bodyResponse)
  }

  private isPost (method?: 'POST' | 'DELETE' | 'GET' | 'PUT'): boolean {
    return method === 'POST' || method === 'PUT' || method === 'DELETE'
  }

  async multiPartWithToken (path: string, body: FormData): Promise<HttpResponse> {
    const headers = await this.getHeaders(true)
    const response = await fetch(path, { headers, method: 'POST', body })
    const bodyResponse = await this.getBody(response)
    return new HttpResponse(response.status, bodyResponse)
  }

  private async getBody (response: Response): Promise<any> {
    try {
      try {
        return await response.json()
      } catch (e) {
        return await response.text()
      }
    } catch (e) {
      return {}
    }
  }

  private async getHeaders (
    needAuthorization: boolean = false
  ): Promise<Headers> {
    const headers = new Headers()
    if (needAuthorization) {
      headers.append('Authorization', `Bearer ${this.token ?? ''}`)
    }
    return headers
  }

  setToken (token: string): void {
    this.token = token
  }

  async deleteWithToken (path: string, data?: any): Promise<HttpResponse> {
    return await this.fetch(path, { method: 'DELETE', withAuthorization: true, data })
  }
}
