import { MobxStore } from '../../../common/store/MobxStore'
import { BackOfficeApplicationService } from '../../application/BackOfficeApplicationService'
import { action, observable } from 'mobx'

export class DashboardStore extends MobxStore {
  private static instance: DashboardStore

  static getInstance (): DashboardStore {
    if (this.instance != null) {
      return this.instance
    }
    this.instance = new DashboardStore(
      BackOfficeApplicationService.getInstance()
    )
    return this.instance
  }

  @observable dashboard: {
    newBuyers: Record<string, number>
    views: Record<string, number>
    favorites: Record<string, number>
    likes: Record<string, number>
    shared: Record<string, number>
    realEstates: Record<string, string>
  } = {
      newBuyers: {},
      views: {},
      favorites: {},
      likes: {},
      shared: {},
      realEstates: {}
    }

  constructor (
    private readonly backOfficeApplicationService: BackOfficeApplicationService
  ) {
    super()
    this.makeObservable()
  }

  async fetch (): Promise<void> {
    this.startFetching()
    const dashboard = await this.backOfficeApplicationService.getDashboard()
    this.setDashboard(dashboard)
    this.endFetching()
  }

  @action
  private setDashboard (dashboard: {
    newBuyers: Record<string, number>
    views: Record<string, number>
    favorites: Record<string, number>
    likes: Record<string, number>
    shared: Record<string, number>
    realEstates: Record<string, string>
  }): void {
    this.dashboard = dashboard
  }

  views (): { labels: string[], datasets: any[] } {
    return {
      labels: this.getLabels(Object.keys(this.dashboard.views)),
      datasets: [
        {
          label: '# segundos vistos',
          data: Object.values(this.dashboard.views),
          backgroundColor: Object.values(this.dashboard.favorites)
            .map(() => this.dynamicColors()),
          borderWidth: 1
        }
      ]
    }
  }

  favorites (): { labels: string[], datasets: any[] } {
    return {
      labels: this.getLabels(Object.keys(this.dashboard.favorites)),
      datasets: [
        {
          label: '# de favoritos',
          data: Object.values(this.dashboard.favorites),
          backgroundColor: Object.values(this.dashboard.favorites)
            .map(() => this.dynamicColors()),
          borderWidth: 1
        }
      ]
    }
  }

  private getLabels (realEstateIds: string[]): string[] {
    return realEstateIds.map(realEstateId => this.dashboard.realEstates[realEstateId] ?? realEstateId)
  }

  likes (): { labels: string[], datasets: any[] } {
    return {
      labels: this.getLabels(Object.keys(this.dashboard.likes)),
      datasets: [
        {
          label: '# de likes',
          data: Object.values(this.dashboard.likes),
          backgroundColor: Object.values(this.dashboard.likes)
            .map(() => this.dynamicColors()),
          borderWidth: 1
        }
      ]
    }
  }

  shared (): { labels: string[], datasets: any[] } {
    return {
      labels: this.getLabels(Object.keys(this.dashboard.shared)),
      datasets: [
        {
          label: '# de compartidos',
          data: Object.values(this.dashboard.shared),
          backgroundColor: Object.values(this.dashboard.favorites)
            .map(() => this.dynamicColors()),
          borderWidth: 1
        }
      ]
    }
  }

  private dynamicColors (): string {
    const r = Math.floor(Math.random() * 255)
    const g = Math.floor(Math.random() * 255)
    const b = Math.floor(Math.random() * 255)
    return `rgb(${r},${g},${b})`
  }

  newBuyers (): any {
    return {
      labels: Object.keys(this.dashboard.newBuyers)
        .map(date => new Date(date))
        .sort((a, b) => a.getTime() - b.getTime())
        .map(date => date.toDateString()),
      datasets: [
        {
          fill: 'Nuevos usuarios',
          data: Object.values(this.dashboard.newBuyers),
          borderColor: this.dynamicColors()
        }
      ]
    }
  }

  overview (): { labels: string[], datasets: any[] } {
    return {
      labels: Object.values(this.dashboard.realEstates),
      datasets: [
        {
          label: 'Shared',
          data: Object.keys(this.dashboard.realEstates)
            .map(realEstateId => this.dashboard.shared[realEstateId]),
          backgroundColor: this.dynamicColors(),
          borderWidth: 1
        },
        {
          label: 'View in seconds',
          data: Object.keys(this.dashboard.realEstates)
            .map(realEstateId => this.dashboard.views[realEstateId]),
          backgroundColor: this.dynamicColors(),
          borderWidth: 1
        },
        {
          label: 'Favorites',
          data: Object.keys(this.dashboard.realEstates)
            .map(realEstateId => this.dashboard.favorites[realEstateId]),
          backgroundColor: this.dynamicColors()
        },
        {
          label: 'Likes',
          data: Object.keys(this.dashboard.realEstates)
            .map(realEstateId => this.dashboard.likes[realEstateId]),
          backgroundColor: this.dynamicColors()
        }
      ]
    }
  }
}
