import { Center, HStack, Modal, ModalBody, ModalContent, ModalOverlay, Spinner, VStack } from '@chakra-ui/react'
import { observer } from 'mobx-react'
import React from 'react'
import BackButton from '../../../../../ui/button/BackButton'
import Card from '../../../../../ui/card/Card'
import { useGoBack } from '../../../../../common/react/hooks/useGoBack'
import { Subtitle } from '../../../../../ui/text/BodyTextComponent'
import PrimaryButton from '../../../../../ui/button/PrimaryButton'
import DefaultInput from '../../../../../ui/inputs/DefaultInput'
import { DropZoneFile } from '../../../../../ui/inputs/DropZoneFile'
import { colors } from '../../../../../ui/constants'
import DefaultDateInput from '../../../../../ui/inputs/DefaultDateInput'
import { type CreateCampaingStore } from './CreateCampaingStore'
import { useParams } from 'react-router-dom'

const createStyles = {
  dropArea: {
    width: '100%',
    height: 40,
    backgroundColor: colors.coolGray['200'],
    marginVertical: 8,
    borderRadius: 8
  }
}

export const CreateCampaign = observer(({ store }: { store: CreateCampaingStore }): JSX.Element => {
  const goBack = useGoBack()
  const { advertiserId } = useParams()
  if (advertiserId != null) { store.setAdvertiserId(advertiserId) }
  return (
    <>
        <BackButton text="Volver" />

        <Card>
            <Subtitle text="Nuevo Anuncio" />
            <VStack gap={4} marginY={'1rem'} width="100%">
                    <DefaultInput text="Titulo" isRequired value={store.title} onChangeText={(value) => { store.setTitle(value) }} />
                    <DefaultInput text="Descripcion" isRequired onChangeText={(value) => { store.setDescription(value) }} />
                    <DefaultInput text="Call To Action URL" type='url' onChangeText={(value) => { store.setWebsite(value) }} />
                    <HStack gap={4} width="100%">
                        <DefaultDateInput text="Inicio" isRequired type='date' value={store.startAt} onChange={(value) => { store.setInitDate(value) }} />
                        <DefaultDateInput text="Fin" isRequired type='date' value={store.endAt} onChange={(value) => { store.setEndDate(value) }} />
                    </HStack>
            </VStack>
        </Card>

        <Card>
            <VStack alignItems='start' gap={4} my={8} width="100%">
            <Subtitle text="Video" />
            <Center style={createStyles.dropArea}>
                <DropZoneFile onChange={(file) => { store.setCampaingMedia(file) }} />
            </Center>
            </VStack>
            { store.media != null && <video autoPlay
                muted
                width="375"
                height="250"
                controls
                src={URL.createObjectURL(store.media)} />}
        </Card>

        <PrimaryButton isFullWidth text={'Crear'} isDisabled={!store.isValid} onTap={() => {
          void store.createCampaingAndAddMedia().then(() => { goBack() }).catch(() => {})
        } } />

        <Modal isOpen={store.isFetching} onClose={() => {}}>
            <ModalOverlay />
                <ModalContent>
                    <ModalBody>
                        <Spinner
                            thickness='4px'
                            speed='0.65s'
                            emptyColor='gray.200'
                            size='xl'
                        />
                    </ModalBody>
                </ModalContent>
        </Modal>
    </>
  )
})
