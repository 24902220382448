import React, { useState } from 'react'
import { Image, HStack, useDisclosure, Box, Text, Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from '@chakra-ui/react'

// import { CaptionWithSkeleton, LabelWithSkeleton } from '../../../ui/text/BodyTextComponent'
// import { Heading4WithSkeleton } from '../../../ui/text/TitleComponent'
import { type RealEstate, RealEstateStatus } from '@howsin/common'
import { AuthStore } from '../../../auth/infrastructure/view/AuthStore'
// import Line from '../../../ui/line/Line'
import IconButton from '../../../ui/button/IconButton'
import { colors, text } from '../../../ui/constants'

const FeedLine = ({
  realEstate,
  onClick,
  onPublish,
  onArchive,
  onEdit
}: {
  realEstate: RealEstate
  onClick?: () => void
  onPublish?: () => void
  onArchive?: () => void
  onEdit?: () => void
}): JSX.Element => {
  const [item] = useState(realEstate)
  const modalConfirmDelete = useDisclosure()
  const achive = (): void => {
    onArchive?.()
    modalConfirmDelete.onClose()
  }

  const getStatusColor = (): string => {
    switch (item?.status) {
      case RealEstateStatus.available:
        return colors.feedBack.success.medium
      case RealEstateStatus.forReview:
        return colors.feedBack.info.dark
      case RealEstateStatus.sold:
        return colors.feedBack.error.dark
      default:
        return text.color
    }
  }

  return (
    <>
      <HStack spacing={4} w="100%" p={4} boxShadow="md">
        {/* Imagen de la propiedad */}
        <Box boxSize="125px">
          <Image
            src={realEstate.images[0]?.getUrl()}
            boxSize="100%"
            objectFit="cover" />
        </Box>

        {/* Datos básicos de la propiedad */}
        <Box flex="1">
          <Text fontSize="xl">{item.basicInfo.price.toString()}</Text>
          <Text>{item.address.getFullStreet()}</Text>
          <Text>{item.basicInfo.type}</Text>
          <Text color={getStatusColor()}>{item.status}</Text>
        </Box>

        {/* Botonera */}
        <HStack spacing={2}>
          <IconButton icon='edit'
            accessibilityName='Editar'
            isBordered={true}
            isDisabled={item === undefined}
            onTap={() => onClick?.()} />
          {AuthStore.getInstance().isAdmin &&
            <IconButton icon='publish' accessibilityName='Editar'
              isDisabled={item?.status !== RealEstateStatus.forReview}
              isBordered={true}
              onTap={() => onPublish?.()} />}
          <IconButton icon='rubbish'
            accessibilityName='Eliminar'
            isDisabled={item === undefined}
            isBordered={true}
            onTap={() => { modalConfirmDelete.onOpen() }} />
        </HStack>
    </HStack>
    <Modal isCentered isOpen={modalConfirmDelete.isOpen} onClose={modalConfirmDelete.onClose} size={'2xl'}>
      <ModalOverlay bg='blackAlpha.300' backdropFilter='blur(10px) hue-rotate(90deg)' />
      <ModalContent>
        <ModalHeader>Desea eliminar la Propiedad</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <HStack spacing={4} justifyContent={'center'} paddingTop={4}>
            <Button textColor={'red'} onClick={achive}>Confirmar</Button>
            <Button onClick={() => { modalConfirmDelete.onClose() }}>Cancelar</Button>
          </HStack>
        </ModalBody>
        <ModalFooter>
        </ModalFooter>
      </ModalContent>
    </Modal>
  </>
  )
}

export default FeedLine
