import React from 'react'
import Card from '../../../../ui/card/Card'
import { Button, Editable, EditablePreview, EditableTextarea, HStack, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useDisclosure, useToast } from '@chakra-ui/react'
import SecondaryButton from '../../../../ui/button/SecondaryButton'
import { Heading3 } from '../../../../ui/text/TitleComponent'
import RealEstateFileInput from './RealEstateFileInput'
import RealEstateAddressForm from './RealEstateAddressForm'
import RealEstateBasicInfoForm from './RealEstateBasicInfoForm'
import { borderRadius } from '../../../../ui/inputs/InputStyle'
import { useGoBack } from '../../../../common/react/hooks/useGoBack'
import { RealEstateStoreContext } from './RealEstateDetail'

export const RealEstateManageCard = (): JSX.Element => {
  const store = React.useContext(RealEstateStoreContext)
  const modalAddImage = useDisclosure()
  const modalDelImage = useDisclosure()
  const modalUpdateDescription = useDisclosure()
  const modalUpdateAddress = useDisclosure()
  const modalUpdateBasicInfo = useDisclosure()
  const modalUpdateStatus = useDisclosure()
  const modalDelete = useDisclosure()
  const modalConfirmDelete = useDisclosure()

  const toast = useToast()
  const goBack = useGoBack()
  const saveImages = (): void => {
    store.persistImages().then(() => {
      toast({
        title: 'Images Added!',
        status: 'success',
        position: 'top-right',
        isClosable: true
      })
    }).catch((error: Error) => {
      toast({
        title: 'Error.',
        description: 'Algo salió mal.' + error.message.toString(),
        position: 'top-right',
        status: 'error',
        isClosable: true
      })
    })
    modalAddImage.onClose()
  }

  const deleteImages = (): void => {
    store.deleteAllImages().then(() => {
      toast({
        title: 'Images Added!',
        status: 'success',
        position: 'top-right',
        isClosable: true
      })
    }).catch((error: Error) => {
      toast({
        title: 'Error.',
        description: 'Algo salió mal.' + error.message.toString(),
        position: 'top-right',
        status: 'error',
        isClosable: true
      })
    })
    modalDelImage.onClose()
  }

  const updateAddress = (): void => {
    store.saveAddress().then(() => {
      toast({
        title: 'Address',
        description: 'Address updated!',
        status: 'success',
        position: 'top-right',
        isClosable: true
      })
    }).catch((error: Error) => {
      toast({
        title: 'Address',
        description: 'Address update - Algo salió mal.' + error.message.toString(),
        position: 'top-right',
        status: 'error',
        isClosable: true
      })
    })
    modalUpdateAddress.onClose()
  }

  const updateBasicInfo = (): void => {
    store.saveBasicInfo().then(() => {
      toast({
        title: 'Basic Info',
        description: 'Basic Info updated!',
        status: 'success',
        position: 'top-right',
        isClosable: true
      })
    }).catch((error: Error) => {
      toast({
        title: 'Error.',
        description: 'Basic Info update -Algo salió mal.' + error.message.toString(),
        position: 'top-right',
        status: 'error',
        isClosable: true
      })
    })
    modalUpdateBasicInfo.onClose()
  }

  const forReview = (): void => {
    store.forReview().then(() => {
      toast({
        title: 'Status',
        description: 'Status Updated to for review!',
        status: 'success',
        position: 'top-right',
        isClosable: true
      })
    }).catch((error: Error) => {
      toast({
        title: 'Error.',
        description: 'Status - Algo salió mal.' + error.message.toString(),
        position: 'top-right',
        status: 'error',
        isClosable: true
      })
    })
    modalUpdateStatus.onClose()
  }

  const sold = (): void => {
    store.sold().then(() => {
      toast({
        title: 'Status',
        description: 'Status Updated to sold!',
        status: 'success',
        position: 'top-right',
        isClosable: true
      })
    }).catch((error: Error) => {
      toast({
        title: 'Error.',
        description: 'Status - Algo salió mal.' + error.message.toString(),
        position: 'top-right',
        status: 'error',
        isClosable: true
      })
    })
    modalUpdateStatus.onClose()
  }

  const publish = (): void => {
    store.publish().then(() => {
      toast({
        title: 'Status',
        description: 'Status Updated to published!',
        status: 'success',
        position: 'top-right',
        isClosable: true
      })
      modalUpdateStatus.onClose()
      goBack()
    }).catch((error: Error) => {
      toast({
        title: 'Error.',
        description: 'Status - Algo salió mal.' + error.message.toString(),
        position: 'top-right',
        status: 'error',
        isClosable: true
      })
    })
  }

  const updateDescription = (): void => {
    store.saveDescription().then(() => {
      toast({
        title: 'Descripcion!',
        description: 'La descripcion ha sido modificada.',
        status: 'success',
        position: 'top-right',
        isClosable: true
      })
    }).catch((error: Error) => {
      toast({
        title: 'Error.',
        description: 'Algo salió mal.' + error.message.toString(),
        position: 'top-right',
        status: 'error',
        isClosable: true
      })
    })
    modalUpdateDescription.onClose()
  }

  const achive = (): void => {
    store.archive().then(() => {
      toast({
        title: 'Eliminar!',
        description: 'Eliminado Correctamnente!!',
        status: 'success',
        position: 'top-right',
        isClosable: true
      })
    }).catch((error: Error) => {
      toast({
        title: 'Error.',
        description: 'Algo salió mal.' + error.message.toString(),
        position: 'top-right',
        status: 'error',
        isClosable: true
      })
    })
    modalConfirmDelete.onClose()
    goBack()
  }

  return (
        <><Card>
         <HStack justifyContent={'space-between'} paddingTop={4} spacing={4}>
            <Heading3 text='Administrar' />
            <Heading3 text={store.realEstate?.status} />
         </HStack>
        <HStack spacing={4} justifyContent={'center'} paddingTop={4}>
          <SecondaryButton
            text='Del Images'
            onTap={() => {
              modalDelImage.onOpen()
            } } />
          <SecondaryButton
            text='Add Images'
            onTap={() => {
              modalAddImage.onOpen()
            } } />
          <SecondaryButton
            text='Dirección'
            onTap={() => {
              modalUpdateAddress.onOpen()
            } } />
          <SecondaryButton
            text='Info. basica'
            onTap={() => {
              modalUpdateBasicInfo.onOpen()
            } } />
          <SecondaryButton
            text='Descripcion'
            onTap={() => {
              modalUpdateDescription.onOpen()
            } } />
          <SecondaryButton
            text='Estado'
            onTap={() => {
              modalUpdateStatus.onOpen()
            } } />
          <SecondaryButton
            text='Eliminar'
            onTap={() => {
              modalDelete.onOpen()
            } } />
          <SecondaryButton
            text='refresh'
            onTap={() => {
              store.fetch().then(() => {
                toast({
                  title: 'Refresh',
                  description: 'Refreshed!',
                  status: 'success',
                  position: 'top-right',
                  isClosable: true
                })
              }).catch((error: Error) => {
                toast({
                  title: 'Error.',
                  description: 'Algo salió mal.' + error.message.toString(),
                  position: 'top-right',
                  status: 'error',
                  isClosable: true
                })
              })
            } } />
        </HStack>
      </Card>
      <Modal isCentered isOpen={modalAddImage.isOpen} onClose={modalAddImage.onClose} size={'2xl'}>
          <ModalOverlay bg='blackAlpha.300' backdropFilter='blur(10px) hue-rotate(90deg)' />
          <ModalContent>
            <ModalHeader>Añadir Imagenes</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <RealEstateFileInput/>
            </ModalBody>
            <ModalFooter>
              <Button onClick={saveImages}>Confirmar</Button>
            </ModalFooter>
          </ModalContent>
      </Modal>
      <Modal isCentered isOpen={modalUpdateDescription.isOpen} onClose={modalUpdateDescription.onClose} size={'3xl'}>
          <ModalOverlay bg='blackAlpha.300' backdropFilter='blur(10px) hue-rotate(90deg)' />
          <ModalContent>
            <ModalHeader>Modificar Descripcion</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Editable defaultValue={store.description}>
                <EditablePreview />
                <EditableTextarea h={20} placeholder="Descripción" width="100%" borderRadius={borderRadius}
                  value={store.description}
                  onChange={(event) => { store.setDescription(event.target.value) }}
                  paddingX={'0.5rem'} />
              </Editable>
            </ModalBody>
            <ModalFooter>
              <Button onClick={updateDescription}>Confirmar</Button>
            </ModalFooter>
          </ModalContent>
        </Modal><Modal isCentered isOpen={modalUpdateAddress.isOpen} onClose={modalUpdateAddress.onClose} size={'2xl'} scrollBehavior='inside'>
          <ModalOverlay bg='blackAlpha.300' backdropFilter='blur(10px) hue-rotate(90deg)' />
          <ModalContent>
            <ModalHeader>Modificar direccion</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <RealEstateAddressForm store={store} />
            </ModalBody>
            <ModalFooter>
              <Button onClick={updateAddress}>Confirmar</Button>
            </ModalFooter>
          </ModalContent>
        </Modal><Modal isCentered isOpen={modalUpdateBasicInfo.isOpen} onClose={modalUpdateBasicInfo.onClose} size={'2xl'} scrollBehavior='inside'>
          <ModalOverlay bg='blackAlpha.300' backdropFilter='blur(10px) hue-rotate(90deg)' />
          <ModalContent>
            <ModalHeader>Modificar Informacion Basica</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <RealEstateBasicInfoForm store={store} />
            </ModalBody>
            <ModalFooter>
              <Button onClick={updateBasicInfo}>Confirmar</Button>
            </ModalFooter>
          </ModalContent>
        </Modal><Modal isCentered isOpen={modalUpdateStatus.isOpen} onClose={modalUpdateStatus.onClose} size={'2xl'}>
          <ModalOverlay bg='blackAlpha.300' backdropFilter='blur(10px) hue-rotate(90deg)' />
          <ModalContent>
            <ModalHeader>Cambiar Estado</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <HStack spacing={4} justifyContent={'center'} paddingTop={4}>
                <SecondaryButton
                  text='Publicar'
                  onTap={() => {
                    publish()
                  } } />
                <SecondaryButton
                  text='Vendido'
                  onTap={() => {
                    sold()
                  } } />
                <SecondaryButton
                  text='For Review'
                  onTap={() => {
                    forReview()
                  } } />
              </HStack>
            </ModalBody>
          </ModalContent>
        </Modal>
        <Modal isCentered isOpen={modalDelete.isOpen} onClose={modalDelete.onClose} size={'2xl'}>
          <ModalOverlay bg='blackAlpha.300' backdropFilter='blur(10px) hue-rotate(90deg)' />
          <ModalContent>
            <ModalHeader>Desea eliminar la Propiedad</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <HStack spacing={4} justifyContent={'center'} paddingTop={4}>
                <Button textColor={'red'} onClick={achive}>Confirmar</Button>
                <Button onClick={() => { modalDelete.onClose() }}>Cancelar</Button>
              </HStack>
            </ModalBody>
            <ModalFooter>
            </ModalFooter>
          </ModalContent>
        </Modal>
        <Modal isCentered isOpen={modalDelImage.isOpen} onClose={modalDelImage.onClose} size={'2xl'}>
          <ModalOverlay bg='blackAlpha.300' backdropFilter='blur(10px) hue-rotate(90deg)' />
          <ModalContent>
            <ModalHeader>Desea eliminar TODAS las imagenes</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <HStack spacing={4} justifyContent={'center'} paddingTop={4}>
                <Button textColor={'red'} onClick={deleteImages}>Confirmar</Button>
                <Button onClick={() => { modalDelImage.onClose() }}>Cancelar</Button>
              </HStack>
            </ModalBody>
            <ModalFooter>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
  )
}
export default RealEstateManageCard
