import { IconButton as IconChakra } from '@chakra-ui/react'
import IconComponent from '../icon/IconComponent'
import { colors } from '../constants'
import React from 'react'

const IconButton = ({ icon, accessibilityName, onTap, isDisabled, isBordered }: {
  icon: string
  accessibilityName: string
  onTap: () => void
  isDisabled?: boolean
  isBordered?: boolean
}): JSX.Element => {
  function getDisabledColor (disabled = false): string {
    return disabled ? colors.coolGray['400'] : colors.coolGray['900']
  }

  function getBorderColor (border = false): string {
    return border ? colors.coolGray['400'] : 'transparent'
  }

  function getBorderWidth (border = false): number {
    return border ? 1 : 0
  }

  function getBackgroundColor (disabled = false): string {
    return disabled ? getDisabledColor(disabled) : 'transparent'
  }

  return (
    <IconChakra
      backgroundColor={getBackgroundColor(isDisabled)}
      borderWidth={getBorderWidth(isBordered)}
      borderColor={getBorderColor(isBordered)}
      borderRadius='1.5rem'
      fontWeight='bold'
      maxHeight={'2.75rem'}
      padding={'0.5rem'}
      _hover={{
        bg: 'rgba(99, 99, 99, 0.2)',
        cursor: 'pointer'
      }}
      _active={{
        bg: 'rgba(99, 99, 99, 0.3)'
      }}
      _disabled={{
        bg: 'transparent',
        cursor: 'default'
      }}
      aria-label={accessibilityName}
      icon={<IconComponent icon={icon} color={getDisabledColor(isDisabled)}/>}
      isDisabled={isDisabled}
      onClick={onTap}
    />
  )
}

export default IconButton
