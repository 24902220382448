import { type EventBusService } from '../../domain/EventBusService'

export class InMemoryEventBus implements EventBusService {
  private queue: Record<string, Array<((data: any) => void)>> = {}

  publish<T>(eventName: string, data?: T): void {
    this.getEvents(eventName).forEach(subscriber => { subscriber(data) })
  }

  private getEvents (eventName: string): Array<((data: any) => void)> {
    return this.queue[eventName] ?? []
  }

  toSubscribe<T>(eventName: string, subscriber: (data: T) => void): void {
    this.queue[eventName] = [...this.getEvents(eventName), subscriber]
  }
}
