import { MobxStore } from '../../../common/store/MobxStore'
import { RealEstates } from '../../domain/RealEstates'
import { action, observable } from 'mobx'
import { type RealEstate, type RealEstateId } from '@howsin/common'
import { type FeedsApplicationService } from '../../application/FeedsApplicationService'

export abstract class FeedsStore extends MobxStore {
  @observable realEstates: RealEstates = new RealEstates()
  @observable pagination = 1

  protected constructor (
    private readonly feedsApplicationService: FeedsApplicationService
  ) {
    super()
    this.makeObservable()
  }

  async fetch (): Promise<void> {
    this.startFetching()
    const feeds = await this.getFeeds()
    this.addFeeds(feeds)
    this.endFetching()
  }

  refresh (): void {
    void this.fetch()
  }

  abstract getFeeds (): Promise<RealEstate[]>

  @action
  private addFeeds (realEstates: RealEstate[]): void {
    this.realEstates.add(...realEstates)
  }

  @action
  morePage (): void {
    this.pagination++
    void this.fetch()
  }

  abstract openRealEstate (id: RealEstateId): void

  abstract createRealEstate (): void

  async archive (id: RealEstateId): Promise<void> {
    this.startFetching()
    await this.feedsApplicationService.archive(id)
    this.endFetching()
  }

  abstract editRealEstate (id: RealEstateId): void
}
