import { action, observable } from 'mobx'
import * as PasswordlessServiceProvider from '../service/auth/passwordless/PasswordlessServiceProvider'
import { GoogleLoginProvider } from '../service/auth/provider/GoogleLoginProvider'
import { FacebookLoginProvider } from '../service/auth/provider/FacebookLoginProvider'
import { AppleLoginProvider } from '../service/auth/provider/AppleLoginProvider'
import { AuthApplicationService } from '../../application/AuthApplicationService'
import { ImpersonatingServiceProvider } from '../service/auth/provider/ImpersonatingServiceProvider'
import { UserType } from '@howsin/common'
import { AppLifeCyclePublisher } from '../../../event/application/AppLifeCyclePublisher'
import { MobxStore } from '../../../common/store/MobxStore'

export class AuthStore extends MobxStore {
  @observable isLogged: boolean = false
  @observable isAgent: boolean = false
  @observable isAdmin: boolean = false
  @observable email: string = ''
  @observable showModal: boolean = false

  private static instance: AuthStore
  static getInstance (): AuthStore {
    if (this.instance != null) {
      return this.instance
    }
    this.instance = new AuthStore(
      AuthApplicationService.getInstance(),
      AppLifeCyclePublisher.getInstance()
    )
    return this.instance
  }

  constructor (
    private readonly authApplicationService: AuthApplicationService,
    private readonly appLifeCyclePublisher: AppLifeCyclePublisher
  ) {
    super()
    this.createImpersonatingPrompt()
    authApplicationService.onAuthStateChanged(isLogged => {
      if (isLogged) {
        void this.goToDashboard()
      }
    })
    this.isLogged = authApplicationService.isLogged()

    this.makeObservable()
  }

  private createImpersonatingPrompt (): void {
    (window as any).promptImpersonating = async () => {
      const token = window.prompt('Put the token')
      if (token != null) {
        await ImpersonatingServiceProvider.getInstance()
          .addToken(token)
          .login()
        await this.goToDashboard()
      }
    }
  }

  @action
  updateEmail (value: string): void {
    this.email = value
  }

  async startPasswordless (): Promise<void> {
    await PasswordlessServiceProvider.getInstance().startLogin(this.email)
    this.openModal()
  }

  async loginWithGoogle (): Promise<void> {
    await GoogleLoginProvider.getInstance().login()
    await this.goToDashboard()
  }

  async loginWithFacebook (): Promise<void> {
    await FacebookLoginProvider.getInstance().login()
    await this.goToDashboard()
  }

  async loginWithApple (): Promise<void> {
    await AppleLoginProvider.getInstance().login()
    await this.goToDashboard()
  }

  async loginWithPasswordless (): Promise<void> {
    await PasswordlessServiceProvider.getInstance().login()
    await this.goToDashboard()
  }

  @action
  closeModal (): void {
    this.showModal = false
  }

  @action
  private openModal (): void {
    this.showModal = true
  }

  private async goToDashboard (): Promise<void> {
    const type = await this.authApplicationService.getRole()
    this.userLogged()
    this.setUserType(type)
    switch (type) {
      case UserType.agent:
        this.appLifeCyclePublisher.goToAgentDashboard()
        break
      case UserType.admin:
        this.appLifeCyclePublisher.goToAdminDashboard()
        break
    }
  }

  @action
  private userLogged (): void {
    this.isLogged = true
  }

  @action
  private setUserType (type: UserType): void {
    this.isAdmin = type === UserType.admin
    this.isAgent = type === UserType.agent
  }

  logOut (): void {
    localStorage.clear()
    window.location.reload()
  }
}
