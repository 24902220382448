import React from 'react'
import { HStack } from '@chakra-ui/react'

import IconButton from './IconButton'
import { Heading3 } from '../text/TitleComponent'
import { useGoBack } from '../../common/react/hooks/useGoBack'

const BackButton = ({ text }: { text: string }): JSX.Element => {
  const goBack = useGoBack()
  return (
        <HStack alignItems='center'>
            <IconButton icon={'back'} accessibilityName={'Atrás'} onTap={() => { goBack() }} />
            <Heading3 text={text} />
        </HStack>
  )
}
export default BackButton
