import React from 'react'
import { FormControl, FormLabel, Select } from '@chakra-ui/react'
import { borderRadius } from './InputStyle'

const DefaultSelect = ({ placeholder, optionsArray, accessibilityKey, onChange }: {
  placeholder: string
  optionsArray: any[]
  accessibilityKey: string
  onChange?: (value: string) => void
}): JSX.Element =>
  (
    <FormControl>
      <FormLabel>{placeholder}</FormLabel>
      <Select
        accessKey={accessibilityKey}
        // backgroundColor={colors.white}
        borderRadius={borderRadius}
        placeholder={placeholder}
        variant="outline"
        w="100%"
        onChange={(e) => onChange?.(e.target.value)}
      >
        {optionsArray.map(optionSelect => (
          <option key={optionSelect.value} value={optionSelect.value}>{optionSelect.text}</option>))}
      </Select>
    </FormControl>
  )

export default DefaultSelect
