import React from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import './date-picker.css'
import ReactDatePicker from 'react-datepicker'

interface Props {
  isClearable?: boolean
  onChange: (date: Date) => any
  isRequired?: boolean
  selectedDate: Date | undefined
  showPopperArrow?: boolean
}

const DatePicker = ({
  selectedDate,
  onChange,
  isRequired,
  isClearable,
  showPopperArrow

}: Props
): JSX.Element => {
  return (
    <ReactDatePicker
        selected={selectedDate}
        onChange={onChange}
        isClearable={isClearable}
        showPopperArrow={showPopperArrow}
        required={isRequired}
        dateFormat="dd/MM/yyyy"
        endDate={new Date()}
    />
  )
}

export default DatePicker
