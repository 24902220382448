import React, { useEffect } from 'react'
import { Center, Spinner, Wrap, WrapItem, useToast } from '@chakra-ui/react'
import { AgentStore } from './AgentStore'
import { observer } from 'mobx-react'
import { type RealEstate, RealEstateId } from '@howsin/common'
import FeedLine from '../../../feeds/infrastructure/view/FeedLine'
import RealEstateAgentHeader from '../../../feeds/infrastructure/view/detail/RealEstateAgentHeader'
import { useParams } from 'react-router-dom'
import { colors } from '../../../ui/constants'

const AgentDetail = observer(({ store = AgentStore.getInstance() }: { store?: AgentStore }): JSX.Element => {
  const { agentId } = useParams()
  useEffect(() => {
    if (agentId != null) {
      void store.fetch(agentId)
    }
    return () => {
      store.clean()
    }
  }, [agentId])
  const toast = useToast()

  return (
    <><Center padding={4} shadow={3} borderRadius={4} width="100%">
      {store.agent === undefined
        ? <></>
        : <RealEstateAgentHeader user={store.agent} />}
    </Center><>

      </>
      <Wrap width={'100%'} padding={4} shadow={3} borderRadius={4}>
      {store.realEstates.isEmpty()
        ? <Spinner
                thickness='4px'
                speed='0.65s'
                emptyColor={colors.white}
                color={colors.primary[400]}
                size='xxl'
              />
        : store.realEstates.toArray().slice(0).map((realEstate: RealEstate) => (
          <WrapItem key={realEstate.id.toString()} width={'100%'}>
            <FeedLine realEstate={realEstate}
              onClick={() => { store.openRealEstate(RealEstateId.fromString(realEstate.id.toString())) }}
              onEdit={() => { store.editRealEstate(RealEstateId.fromString(realEstate.id.toString())) }}
              onArchive={() => {
                void store.archive(RealEstateId.fromString(realEstate.id.toString())).then(() => {
                  toast({
                    title: 'Éxito.',
                    description: 'Post Eliminado.',
                    status: 'info',
                    position: 'top',
                    isClosable: true
                  })
                }).catch(() => {
                  toast({
                    title: 'Error.',
                    description: 'Algo salió mal.',
                    position: 'top',
                    status: 'error',
                    isClosable: true
                  })
                })
              } }
              onPublish={() => {
                void (store)
                  .publish(RealEstateId.fromString(realEstate.id.toString())).then(() => {
                    toast({
                      title: 'Éxito.',
                      description: 'Post publicado.',
                      status: 'success',
                      position: 'top',
                      isClosable: true
                    })
                  }).catch(() => {
                    toast({
                      title: 'Error.',
                      description: 'Algo salió mal.',
                      position: 'top',
                      status: 'error',
                      isClosable: true
                    })
                  })
              } } />
          </WrapItem>
        ))}
      </Wrap>
        </>
  )
})

export default AgentDetail
