import { VStack, Center, HStack, FormLabel } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import Card from '../../../../ui/card/Card'
import { type RealEstateDetailStore } from './RealEstateDetailStore'
import { Subtitle } from '../../../../ui/text/BodyTextComponent'
import SwitchButton from '../../../../ui/button/SwitchButton'
import { Wrapper } from '@googlemaps/react-wrapper'
import RealEstateMapView from '../../../../common/map/RealEstateMapView'
import { DotEnvConstantsService } from '../../../../common/service/constants/DotEnvConstantsService'
import DefaultSelect from '../../../../ui/inputs/DefaultSelect'
import DefaultEditableInput from '../../../../ui/inputs/DefaulEditabletInput'
import DefaultInput from '../../../../ui/inputs/DefaultInput'
// import RealEstateAddressAutocomplete from '../../../../common/map/RealEstateAddressAutocomplete'

const RealEstateAddressForm = ({ store }: { store: RealEstateDetailStore }): JSX.Element => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [address, setAddress] = useState(store.realEstate?.address)

  useEffect(() => {
    // return () => {
    //   store.address.street = ''
    //   store.address.number = ''
    //   store.address.zipCode = ''
    //   store.address.municipality = ''
    //   store.address.province = ''
    //   store.address.isHidden = false
    // }
  }, [store.address])

  return (
    <Card>
      {/* <RealEstateAddressAutocomplete store={store}/> TODO: fix, not complete on address change */}
      <VStack alignItems='start' gap={4} my={8} width="100%">
        <Subtitle text="Dirección" />
        <HStack gap={4} marginY={'1rem'} width="100%">
          <Center flexGrow={4}>
           { address?.street === undefined || address?.street === null || address?.street === ''
             ? (
            <DefaultInput text="Via" onChangeText={(value: string) => { store.setStreet(value) }}/>
               )
             : (
            <DefaultEditableInput text="Via" value={address?.street} onChangeText={(value: string) => {
              store.setStreet(value)
            }} />
               )}
          </Center>
          <Center flexGrow={1}>
          { store.realEstate?.address.number === undefined || store.realEstate?.address.number === null || store.realEstate?.address.number === ''
            ? (
            <DefaultInput text="Número" onChangeText={(value: string) => { store.setNumber(value) }}/>
              )
            : (
              <DefaultEditableInput text="Número" value={address?.number} onChangeText={(value: string) => {
                store.setNumber(value)
              }} />
              )
          }
          </Center>
        </HStack>
        <HStack gap={4} width="100%">
          <Center flexGrow={1}>
          { store.realEstate?.address.zipCode === undefined || store.realEstate?.address.zipCode === null || store.realEstate?.address.zipCode === ''
            ? (
            <DefaultInput text="Código postal" onChangeText={(value: string) => { store.setZipCode(value) }}/>
              )
            : (
              <DefaultEditableInput text="Código postal" value={address?.zipCode} onChangeText={(value: string) => {
                store.setZipCode(value)
              }} />
              )
          }
          </Center>
          <Center flexGrow={1}>
            { store.realEstate?.address.municipality === undefined || store.realEstate?.address.municipality === null || store.realEstate?.address.municipality === ''
              ? (
                <DefaultInput text="Población" onChangeText={(value: string) => { store.setCity(value) }}/>
                )
              : (
                <DefaultEditableInput text="Población" value={address?.municipality} onChangeText={(value: string) => {
                  store.setCity(value)
                }} />
                )
            }
          </Center>
          <Center flexGrow={1}>
            <DefaultSelect placeholder={store.realEstate?.address.province ?? 'Selecciona Provincia'}
                          onChange={(value: string) => { store.setState(value) }}
                          optionsArray={[
                            { value: 'Álava', text: 'Álava' },
                            { value: 'Albacete', text: 'Albacete' },
                            { value: 'Alicante', text: 'Alicante' },
                            { value: 'Almería', text: 'Almería' },
                            { value: 'Asturias', text: 'Asturias' },
                            { value: 'Ávila', text: 'Ávila' },
                            { value: 'Badajoz', text: 'Badajoz' },
                            { value: 'Barcelona', text: 'Barcelona' },
                            { value: 'Burgos', text: 'Burgos' },
                            { value: 'Cáceres', text: 'Cáceres' },
                            { value: 'Cádiz', text: 'Cádiz' },
                            { value: 'Cantabria', text: 'Cantabria' },
                            { value: 'Castellón', text: 'Castellón' },
                            { value: 'Ciudad Real', text: 'Ciudad Real' },
                            { value: 'Córdoba', text: 'Córdoba' },
                            { value: 'Coruña', text: 'Coruña' },
                            { value: 'Cuenca', text: 'Cuenca' },
                            { value: 'Girona', text: 'Girona' },
                            { value: 'Granada', text: 'Granada' },
                            { value: 'Guadalajara', text: 'Guadalajara' },
                            { value: 'Guipúzcoa', text: 'Guipúzcoa' },
                            { value: 'Huelva', text: 'Huelva' },
                            { value: 'Huesca', text: 'Huesca' },
                            { value: 'Islas Baleares', text: 'Islas Baleares' },
                            { value: 'Jaén', text: 'Jaén' },
                            { value: 'La Rioja', text: 'La Rioja' },
                            { value: 'Las Palmas', text: 'Las Palmas' },
                            { value: 'León', text: 'León' },
                            { value: 'Lleida', text: 'Lleida' },
                            { value: 'Lugo', text: 'Lugo' },
                            { value: 'Madrid', text: 'Madrid' },
                            { value: 'Málaga', text: 'Málaga' },
                            { value: 'Murcia', text: 'Murcia' },
                            { value: 'Navarra', text: 'Navarra' },
                            { value: 'Ourense', text: 'Ourense' },
                            { value: 'Palencia', text: 'Palencia' },
                            { value: 'Pontevedra', text: 'Pontevedra' },
                            { value: 'Salamanca', text: 'Salamanca' },
                            { value: 'Santa Cruz de Tenerife', text: 'Santa Cruz de Tenerife' },
                            { value: 'Segovia', text: 'Segovia' },
                            { value: 'Sevilla', text: 'Sevilla' },
                            { value: 'Soria', text: 'Soria' },
                            { value: 'Tarragona', text: 'Tarragona' },
                            { value: 'Teruel', text: 'Teruel' },
                            { value: 'Toledo', text: 'Toledo' },
                            { value: 'Valencia', text: 'Valencia' },
                            { value: 'Valladolid', text: 'Valladolid' },
                            { value: 'Vizcaya', text: 'Vizcaya' },
                            { value: 'Zamora', text: 'Zamora' },
                            { value: 'Zaragoza', text: 'Zaragoza' }
                          ]} accessibilityKey={'Selecciona Provincia'}
            />
          </Center>
        </HStack>
        <HStack gap={2} width="100%">
          <FormLabel>Ocultar Ubicacion</FormLabel>
          <SwitchButton size='md' value={address?.isHidden} onChange={(event) => { store.setIsHidden(event.target.checked) }}/>
        </HStack>
        <HStack gap={4} width="100%">
          <Center flexGrow={4}>
            <Wrapper apiKey={DotEnvConstantsService.getInstance().getGoogleMapsApiKey()}>
              <RealEstateMapView
                center={store?.getlatlng()}
                zoom={10}
                isHidden={store.realEstate?.address.isHidden ?? false}/>
            </Wrapper>
          </Center>
          </HStack>
      </VStack>
  </Card>
  )
}

export default RealEstateAddressForm
