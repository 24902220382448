import React, { useEffect } from 'react'
import { Avatar, HStack, VStack, Wrap, WrapItem, Link } from '@chakra-ui/react'
import { observer } from 'mobx-react'
import { type AdvertiserDetailStore } from './AdvertiserDetailStore'
import Card from '../../../../../ui/card/Card'
import { Body, Label } from '../../../../../ui/text/BodyTextComponent'
import { CopyTextComponent } from '../../../../../ui/text/ClipBoardTextComponent'
import { Heading3, Heading4 } from '../../../../../ui/text/TitleComponent'
import { useParams } from 'react-router-dom'
import PrimaryButton from '../../../../../ui/button/PrimaryButton'
import { type Ad } from '@howsin/common'
import MuxPlayer from '@mux/mux-player-react'
import moment from 'moment'
import SecondaryButton from '../../../../../ui/button/SecondaryButton'
import BackButton from '../../../../../ui/button/BackButton'

const AdvertisersDetail = observer(({ store }: { store: AdvertiserDetailStore }): JSX.Element => {
  const { advertiserId } = useParams()
  useEffect(() => {
    if (advertiserId != null) {
      void store.fetch(advertiserId)
    }
    return () => {
      void store.clean()
    }
  }, [advertiserId])

  return (
    <>
      <BackButton text="Anuanciantes" />
      <VStack width="100%" spacing={8}>
        <Heading3 text="Detalle de anunciante" />
        {/* <Center padding={4} shadow={3} borderRadius={4} width="100%"> */}
          <Card>
                <HStack justifyContent={'space-between'} spacing={4}>
                  <HStack spacing={4}>
                    <Avatar
                      size="xl"
                      name={store.advertiser?.name}
                      src={store.advertiser?.avatar.getUrl()}
                    />
                    <VStack align="flex-start" spacing={2}>
                      <Heading4 text={store.advertiser?.name} />
                      <Body text={store.advertiser?.nickName}/>
                    </VStack>
                  </HStack>
                    <VStack alignItems="flex-end" spacing={2}>
                      <CopyTextComponent text={store.advertiser?.id.toString()} />
                      <Body text={store.advertiser?.email} />
                      <Body text={store.advertiser?.website} />
                    </VStack>
                </HStack>
              </Card>
        {/* </Center> */}
      </VStack>

      <Wrap>
        {store.ads.map((ad: Ad) => (
          <WrapItem key={ad?.id.toString()}>
            <Card key={ad?.id.toString()}>
              <VStack align="flex-start" justifyContent="space-between" width="100%" spacing={2}>
                <Heading4 text={ad?.getTitle()} />
                <Body text={ad?.getSubtitle()} />
                <Body text={moment(ad?.createdAt).format('DD/MM/YYYY')} />
                <MuxPlayer
                    style={{ height: '50%', maxWidth: '100%' }}
                    playbackId={
                      ad?.content.getUrl()
                        .replace('https://stream.mux.com/', '')
                        .replace('.m3u8', '')
                    }
                    metadata={{
                      video_id: ad?.id.toString(),
                      video_title: ad?.getTitle()
                    }}
                    streamType="on-demand"
                    autoPlay = {false}
                  />
                 <HStack justifyContent={'space-between'} spacing={4}>
                            <Label text='Call To Action'/>
                            <Link href={ad?.getLink()}>
                                <Body text={ad?.getLink().slice(0, 15) + '...'}/>
                            </Link>
                </HStack>
                <VStack alignItems="flex-start" spacing={2}>
                    <HStack spacing={4}>
                        <Label text='Aspect Ratio'/>
                        <Body text={ad?.aspectRatio} />
                    </HStack>
                    <HStack spacing={4}>
                        <Label text='Duration'/>
                        <Body text={String(ad?.duration)} />
                    </HStack>
                </VStack>
                <HStack spacing={4}>
                  {ad?.status === 'enabled' && <SecondaryButton text={'Pausar'} onTap={() => {
                    void store.pause(ad)
                  }} />}
                  {ad?.status === 'paused' && <SecondaryButton text={'Activar'} onTap={() => {
                    void store.active(ad)
                  }} />}
                  {ad?.status === 'forReview' && <SecondaryButton text={'Publicar'} onTap={() => {
                    void store.publish(ad)
                  }} />}
                  <SecondaryButton text={'Borrar'} onTap={() => {
                    void store.archived(ad)
                  }} />
                </HStack>
              </VStack>
            </Card>
          </WrapItem>
        ))}
      </Wrap>
      <PrimaryButton isFullWidth text={'Nueva Campaña'} onTap={() => {
        store.goToCreateCampaing()
      }} />
    </>
  )
})

export default AdvertisersDetail
