import { MobxStore } from '../MobxStore'

export class NavbarAdminStore extends MobxStore {
  private static instance: NavbarAdminStore

  static getInstance (): NavbarAdminStore {
    if (this.instance != null) {
      return this.instance
    }
    this.instance = new NavbarAdminStore()
    return this.instance
  }

  logOut (): void {
    localStorage.clear()
    window.location.reload()
  }
}
