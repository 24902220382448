import { BackendService } from './BackendService'
import { FetchHttpService } from './http/FetchHttpService'
import { DotEnvConstantsService } from './constants/DotEnvConstantsService'
/*eslint-disable */
export class BackendServiceProvider {
  // eslint-disable-next-line no-use-before-define
  private static instance: BackendService

  static getInstance (): BackendService {
    if (this.instance === undefined) {
      BackendServiceProvider.instance = new BackendService(
        DotEnvConstantsService.getInstance().getBackendUrl(),
        FetchHttpService.getInstance())
    }
    return BackendServiceProvider.instance
  }
}
