import { FeedsStore } from '../../../../feeds/infrastructure/view/FeedsStore'
import { type RealEstate, type RealEstateId } from '@howsin/common'
import { BackOfficeApplicationService } from '../../../application/BackOfficeApplicationService'
import { AppLifeCyclePublisher } from '../../../../event/application/AppLifeCyclePublisher'
import { FeedsApplicationService } from '../../../../feeds/application/FeedsApplicationService'

export class FeedsAdminStore extends FeedsStore {
  private static instance: FeedsAdminStore

  static getInstance (): FeedsAdminStore {
    if (this.instance != null) {
      return this.instance
    }
    this.instance = new FeedsAdminStore(
      BackOfficeApplicationService.getInstance(),
      AppLifeCyclePublisher.getInstance(),
      FeedsApplicationService.getInstance()
    )
    return this.instance
  }

  constructor (
    private readonly backOfficeApplicationService: BackOfficeApplicationService,
    private readonly appLifeCyclePublisher: AppLifeCyclePublisher,
    feedsApplicationService: FeedsApplicationService
  ) {
    super(feedsApplicationService)
  }

  async getFeeds (): Promise<RealEstate[]> {
    return await this.backOfficeApplicationService.getFeeds()
  }

  openRealEstate (id: RealEstateId): void {
    this.appLifeCyclePublisher.goToViewRealEstateAdmin(id)
  }

  async publish (id: RealEstateId): Promise<void> {
    await this.backOfficeApplicationService.publish(id)
  }

  createRealEstate (): void {
    this.appLifeCyclePublisher.goToCreateRealEstate()
  }

  editRealEstate (id: RealEstateId): void {
    this.appLifeCyclePublisher.goToEditRealEstateAdmin(id)
  }
}
