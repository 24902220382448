import { type AuthService } from '../domain/AuthService'
import { AuthServiceProvider } from '../infrastructure/service/auth/AuthServiceProvider'
import { type UserType } from '@howsin/common'

export class AuthApplicationService {
  private static instance: AuthApplicationService

  static getInstance (): AuthApplicationService {
    if (this.instance != null) {
      return this.instance
    }
    this.instance = new AuthApplicationService(
      AuthServiceProvider.getInstance()
    )
    return this.instance
  }

  constructor (
    private readonly authService: AuthService
  ) {
  }

  isLogged (): boolean {
    return this.authService.isLogged()
  }

  async getRole (): Promise<UserType> {
    return await this.authService.getRole()
  }

  onAuthStateChanged (action: (isLogged: boolean) => void): void {
    this.authService.onAuthStateChanged(action)
  }
}
