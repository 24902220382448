/* eslint-disable @typescript-eslint/no-unused-vars */
import { DotEnvConstantsService } from '../../../../common/service/constants/DotEnvConstantsService'
import { type AuthService } from '../../../domain/AuthService'
import { FakeAuthService } from './FakeAuthService'
import { FirebaseAuthService } from './FirebaseAuthService'

// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class AuthServiceProvider {
  private static instance: AuthService

  static getInstance (): AuthService {
    if (this.instance != null) {
      return this.instance
    }
    if (DotEnvConstantsService.getInstance().isDemo()) {
      this.instance = FakeAuthService.getInstance()
      return this.instance
    }
    this.instance = FirebaseAuthService.getInstance()
    return this.instance
  }
}
