import { type Advertiser, type UserId } from '@howsin/common'
import { BackOfficeApplicationService } from '../../../application/BackOfficeApplicationService'
import { MobxStore } from '../../../../common/store/MobxStore'
import { action, observable } from 'mobx'
import { AppLifeCyclePublisher } from '../../../../event/application/AppLifeCyclePublisher'

export class AdvertisersStore extends MobxStore {
  private static instance: AdvertisersStore

  static getInstance (): AdvertisersStore {
    if (this.instance != null) {
      return this.instance
    }
    this.instance = new AdvertisersStore(
      BackOfficeApplicationService.getInstance(),
      AppLifeCyclePublisher.getInstance()
    )
    return this.instance
  }

  @observable advertisers: Advertiser[] = []

  constructor (
    private readonly backOfficeApplicationService: BackOfficeApplicationService,
    private readonly appLifeCyclePublisher: AppLifeCyclePublisher
  ) {
    super()
    this.makeObservable()
  }

  async fetch (): Promise<void> {
    this.startFetching()
    const advertisers = await this.backOfficeApplicationService.getAdvertisers()
    this.setAdvertisers(advertisers)
    this.endFetching()
  }

  async generateAndCopyToken (id: UserId): Promise<void> {
    const token = await this.backOfficeApplicationService.getToken(id)
    await navigator.clipboard.writeText(token)
  }

  @action
  private setAdvertisers (advertisers: Advertiser[]): void {
    this.advertisers = advertisers
  }

  goToCreateadvertiser (): void {
    this.appLifeCyclePublisher.goToCreateAdvertiser()
  }

  goToDetails (advertiser: Advertiser): void {
    this.appLifeCyclePublisher.goToAdvertiserDetail(advertiser)
  }
}
