import {
  Ad,
  type Address,
  Advertiser,
  Agent,
  type BasicInfo,
  CampaingId,
  type Image,
  RealEstate, type RealEstateFeature,
  type RealEstateId,
  UrlImage,
  User,
  type UserId
} from '@howsin/common'
import { type FetchHttpService } from './http/FetchHttpService'
import { FirebaseService } from './firebase/FirebaseService'
import { getStorage, ref, uploadBytes } from 'firebase/storage'
import { Base64 } from '../Base64'

export class BackendService {
  constructor (
    private readonly apiUrl: string,
    private readonly httpService: FetchHttpService
  ) {
  }

  setToken (accessToken: string): void {
    this.httpService.setToken(accessToken)
  }

  async whoAmI (): Promise<User> {
    const response = await this.httpService.getWithToken(
      this.apiUrl + '/auth/whoAmI'
    )
    return User.fromPrimitive(response.body)
  }

  async getAgents (): Promise<Agent[]> {
    const response = await this.httpService.getWithToken(
      this.apiUrl + '/backoffice/agents'
    )
    return response.body.map((data: any) => Agent.fromPrimitive(data))
  }

  async getToken (id: UserId): Promise<string> {
    const response = await this.httpService.getWithToken(
      this.apiUrl + '/backoffice/impersonating/' + id.toString()
    )
    return response.body.token
  }

  async getRealEstatesReview (): Promise<RealEstate[]> {
    const response = await this.httpService.getWithToken(
      this.apiUrl + '/backoffice/realEstate/draft'
    )
    return response.body.map((data: any) => RealEstate.fromPrimitive(data))
  }

  async getRealEstates (): Promise<RealEstate[]> {
    const response = await this.httpService.getWithToken(
      this.apiUrl + '/portal/feeds'
    )
    return response.body.map((data: any) => RealEstate.fromPrimitive(data))
  }

  async fetchRealEstate (realEstateId: RealEstateId): Promise<RealEstate> {
    const response = await this.httpService.getWithToken(
      this.apiUrl + '/portal/realEstate/' + realEstateId.toString()
    )
    return RealEstate.fromPrimitive(response.body)
  }

  async fetchFullRealEstate (realEstateId: RealEstateId): Promise<RealEstate> {
    const response = await this.httpService.getWithToken(
      this.apiUrl + '/backoffice/realEstate/' + realEstateId.toString()
    )
    const realEstate = RealEstate.fromPrimitive(response.body)
    return realEstate
  }

  async createAgent (userId: UserId, email: string, name: string, nickName: string, mobile: string, instagram?: string): Promise<void> {
    await this.httpService.postWithToken(
      this.apiUrl + '/auth/createAgent',
      {
        id: userId.toString(),
        email,
        name,
        nickName,
        mobile: {
          countryCode: 34,
          number: parseInt(mobile, 10)
        },
        instagram
      }
    )
  }

  async uploadAvatar (avatar: File, userId?: UserId): Promise<Image> {
    const formData = new FormData()
    formData.append('avatar', avatar)
    if (userId != null) {
      formData.append('userId', userId.toString())
    }
    const response = await this.httpService.multiPartWithToken(
      this.apiUrl + '/storage/user/avatar', formData)
    return new UrlImage(response.body.avatar as string)
  }

  async sendAvatar (avatar: Image, userId?: UserId): Promise<void> {
    await this.httpService.postWithToken(
      this.apiUrl + '/auth/addProfile',
      {
        userId: userId?.toString(),
        avatar: avatar.getUrl()
      }
    )
  }

  async createRealEstate (realEstateId: RealEstateId, basicInfo: BasicInfo, address: Address, description: string): Promise<void> {
    await this.httpService.postWithToken(
      this.apiUrl + '/realEstate',
      {
        id: realEstateId.toString(),
        basicInfo: {
          ...basicInfo.toPrimitive(),
          price: basicInfo.price.toInt()
        },
        address: address.toPrimitive(),
        description
      }
    )
  }

  async uploadVideo (realEstateId: RealEstateId | CampaingId, video: File):
  Promise<{ video: string, aspectRatio: string, duration: string }> {
    FirebaseService.getInstance()
    const storage = getStorage()
    const storageRef = ref(storage, Base64.create(video.name).toString())

    await uploadBytes(storageRef, video)
    let endpoint = '/storage/realEstate/temporalVideo'
    if (realEstateId instanceof CampaingId) {
      endpoint = '/storage/campaing/temporalVideo'
    }
    const response = await this.httpService.postWithToken(this.apiUrl + endpoint, {
      video: storageRef.fullPath,
      realEstateId: realEstateId.toString()
    })
    return {
      video: response.body.streamVideo,
      aspectRatio: response.body.aspectRatio,
      duration: response.body.duration
    }
    // const response = await this.httpService.multiPartWithToken(
    //   this.apiUrl + '/storage/realEstate/video', formData)
    // return new UrlVideo(response.body.video)
  }

  async addVideo (
    realEstateId: RealEstateId,
    video: { video: string, aspectRatio: string, duration: string }
  ): Promise<void> {
    await this.httpService.postWithToken(
      this.apiUrl + '/realEstate/video/' + realEstateId.toString(),
      video
    )
  }

  async uploadImage (realEstateId: RealEstateId, image: File): Promise<Image> {
    const formData = new FormData()
    formData.append('id', realEstateId.toString())
    formData.append('image', image)
    const response = await this.httpService.multiPartWithToken(
      this.apiUrl + '/storage/realEstate/image', formData)
    return new UrlImage(response.body.image as string)
  }

  async addImage (realEstateId: RealEstateId, image: Image): Promise<Image> {
    return await this.httpService.postWithToken(
      this.apiUrl + '/realEstate/image/' + realEstateId.toString(),
      { image: image.getUrl() }
    ).then(async (response) => {
      if (response.status === 201) {
        console.log(response.body.image)
        return await Promise.resolve(response.body.image)
      }
    }).catch(async (error) => {
      return await Promise.reject(error)
    })
  }

  async deleteImage (realEstateId: RealEstateId, imageUrl: string): Promise<void> {
    await this.httpService.deleteWithToken(
      this.apiUrl + '/realEstate/image/' + realEstateId.toString(),
      {
        image: imageUrl
      }
    ).then(async (response) => {
      if (response.status === 200) {
        await Promise.resolve()
      }
    }).catch(async (error) => {
      return await Promise.reject(error)
    })
  }

  async addAdditionalImage (realEstateId: RealEstateId, image: Image): Promise<string> {
    return await this.httpService.putWithToken(
      this.apiUrl + '/realEstate/image/' + realEstateId.toString(),
      { image: image.getUrl() }
    ).then(async (response) => {
      if (response.status === 201) {
        console.log(response.body.image)
        return await Promise.resolve(response.body.image)
      }
    }).catch(async (error) => {
      console.log(error)
      throw error
    })
  }

  async toReview (realEstateId: RealEstateId): Promise<void> {
    await this.httpService.postWithToken(
      this.apiUrl + '/realEstate/toReview/' + realEstateId.toString()
    )
  }

  async addFeatures (realEstateId: RealEstateId, features: RealEstateFeature[]): Promise<void> {
    await this.httpService.postWithToken(
      this.apiUrl + '/realEstate/features/' + realEstateId.toString(),
      { features: features.map(feature => feature.toString()) }
    )
  }

  async publish (realEstateId: RealEstateId): Promise<void> {
    Promise.all([
      this.httpService.postWithToken(
        this.apiUrl + '/realEstate/publish/' + realEstateId.toString()
      ),
      this.httpService.postWithToken(
        this.apiUrl + '/realEstate/publishFeed/' + realEstateId.toString()
      )
    ]).then(async (response) => {
      if (response[0].status === 200 && response[1].status === 200) {
        await Promise.resolve()
      }
    }).catch(async (error) => {
      return await Promise.reject(error)
    }
    )
  }

  async archiveRealEstate (realEstateId: RealEstateId): Promise<void> {
    this.httpService.deleteWithToken(
      this.apiUrl + '/realEstate/archive/' + realEstateId.toString()
    ).then(async (response) => {
      if (response.status === 200) {
        await Promise.resolve()
      }
    }
    ).catch(async (error) => {
      return await Promise.reject(error)
    }
    )
  }

  async dashboard (): Promise<{
    newBuyers: Record<string, number>
    views: Record<string, number>
    favorites: Record<string, number>
    likes: Record<string, number>
    shared: Record<string, number>
    realEstates: Record<string, string>
  }> {
    const response = await this.httpService.getWithToken(
      this.apiUrl + '/backoffice/dashboard'
    )
    return response.body
  }

  async saveDescription (realEstateId: RealEstateId, description: string): Promise<void> {
    await this.httpService.putWithToken(
      this.apiUrl + '/realEstate/description/' + realEstateId.toString(),
      { text: description }
    )
  }

  async saveBasicInfo (realEstateId: RealEstateId, basicInfo: BasicInfo): Promise<void> {
    await this.httpService.putWithToken(
      this.apiUrl + '/realEstate/basicInfo/' + realEstateId.toString(),
      { params: basicInfo.toPrimitive() }
    )
  }

  async saveAddress (realEstateId: RealEstateId, address: Address): Promise<void> {
    await this.httpService.putWithToken(
      this.apiUrl + '/realEstate/address/' + realEstateId.toString(),
      { params: address.toPrimitive() }
    )
  }

  async saveStatus (realEstateId: RealEstateId, status: string): Promise<void> {
    await this.httpService.putWithToken(
      this.apiUrl + '/realEstate/status/' + realEstateId.toString(),
      { status }
    )
  }

  async changeStatusToSold (realEstateId: RealEstateId): Promise<void> {
    await this.httpService.postWithToken(
      this.apiUrl + '/realEstate/sold/' + realEstateId.toString()
    )
  }

  async getAgentDetail (id: UserId): Promise<Agent> {
    const response = await this.httpService.getWithToken(
      this.apiUrl + '/backoffice/agent/' + id.toString()
    )
    return Agent.fromPrimitive(response.body)
  }

  async getRealEstateByAgentId (id: UserId): Promise<RealEstate[]> {
    const response = await this.httpService.getWithToken(
      this.apiUrl + '/backoffice/agent/realEstates/' + id.toString()
    )
    return response.body.map((data: any) => RealEstate.fromPrimitive(data))
  }

  async createAdvertiser (userId: UserId, email: string, name: string, nickName: string, website?: string): Promise<void> {
    await this.httpService.postWithToken(
      this.apiUrl + '/auth/createAdvertiser',
      {
        id: userId.toString(),
        email,
        name,
        nickName,
        website
      }
    )
  }

  async getAdvertisers (): Promise<Advertiser[]> {
    const response = await this.httpService.getWithToken(
      this.apiUrl + '/backoffice/advertisers'
    )
    return response.body.map((data: any) => Advertiser.fromPrimitive(data))
  }

  async getAdvertiser (advertiserId: string): Promise<Advertiser> {
    return await this.httpService.getWithToken(
      this.apiUrl + '/backoffice/advertiser/' + advertiserId
    ).then((response) => {
      return Advertiser.fromPrimitive(response.body)
    })
  }

  async publishCampaing (campaingId: CampaingId): Promise<void> {
    await this.httpService.postWithToken(
      this.apiUrl + '/campaing/publish/' + campaingId.toString()
    ).then(async (response) => {
      if (response.status === 200) {
        await Promise.resolve()
      }
    }).catch(async (error) => {
      return await Promise.reject(error)
    })
  }

  async toReviewCampaing (campaingId: CampaingId): Promise<void> {
    await this.httpService.postWithToken(
      this.apiUrl + '/campaing/toReview/' + campaingId.toString()
    ).then(async (response) => {
      if (response.status === 200) {
        await Promise.resolve()
      }
    }).catch(async (error) => {
      return await Promise.reject(error)
    })
  }

  toDraftCampaing (campaingId: CampaingId): void | PromiseLike<void> {
    return this.httpService.postWithToken(
      this.apiUrl + '/campaing/toDraft/' + campaingId.toString()
    ).then(async (response) => {
      if (response.status === 200) {
        await Promise.resolve()
      }
    }).catch(async (error) => {
      await Promise.reject(error)
    })
  }

  async createCampaing (
    campaingId: CampaingId,
    advertiserId: string,
    title: string,
    description: string,
    startAt: string,
    endAt: string,
    website: string
  ): Promise<void> {
    await this.httpService.postWithToken(
      this.apiUrl + '/campaing',
      {
        id: campaingId.toString(),
        advertiserId,
        title,
        description,
        startAt,
        endAt,
        website
      }
    ).then(async (response) => {
      if (response.status === 201) {
        await Promise.resolve()
      }
    }).catch(async (error) => {
      return await Promise.reject(error)
    })
  }

  async createAd (
    campaingId: CampaingId,
    advertiserId: string,
    title: string,
    description: string,
    link: string,
    streamVideo: string,
    aspectRatio: string,
    duration: string
  ): Promise<void> {
    await this.httpService.postWithToken(
      this.apiUrl + '/campaing/ad/',
      {
        campaingId: campaingId.toString(),
        advertiserId,
        title,
        description,
        link,
        streamVideo,
        aspectRatio,
        duration
      }
    ).then(async (response) => {
      if (response.status === 201) {
        await Promise.resolve()
      }
    }).catch(async (error) => {
      return await Promise.reject(error)
    }
    )
  }

  async getAdsFromAdvertiser (advertiserId: string): Promise<Ad[]> {
    return await this.httpService.getWithToken(
      this.apiUrl + '/campaing/' + advertiserId + '/ads/'
    ).then((response) => {
      return response.body.map((data: any) => Ad.fromPrimitive(data))
    })
  }

  async archivedCampaing (ad: Ad): Promise<void> {
    await this.httpService.deleteWithToken(
      this.apiUrl + '/campaing/archive/' + ad.campaingId.toString()
    ).then(async (response) => {
      if (response.status === 200) {
        await Promise.resolve()
      }
    }).catch(async (error) => {
      return await Promise.reject(error)
    }
    )
  }

  async pauseCampaing (ad: Ad): Promise<void> {
    await this.httpService.postWithToken(
      this.apiUrl + '/campaing/pause/' + ad.campaingId.toString()
    ).then(async (response) => {
      if (response.status === 200) {
        await Promise.resolve()
      }
    }).catch(async (error) => {
      return await Promise.reject(error)
    }
    )
  }

  async activateCampaing (ad: Ad): Promise<void> {
    await this.httpService.postWithToken(
      this.apiUrl + '/campaing/activate/' + ad.campaingId.toString()
    ).then(async (response) => {
      if (response.status === 200) {
        await Promise.resolve()
      }
    }).catch(async (error) => {
      return await Promise.reject(error)
    }
    )
  }
}
