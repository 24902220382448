import {
  type Address,
  type BasicInfo,
  type RealEstate,
  RealEstateId,
  type Image
} from '@howsin/common'
import { type BackendService } from '../../common/service/BackendService'
import { BackendServiceProvider } from '../../common/service/BackendServiceProvider'

export class FeedsApplicationService {
  private static instance: FeedsApplicationService

  static getInstance (): FeedsApplicationService {
    if (this.instance != null) {
      return this.instance
    }
    this.instance = new FeedsApplicationService(
      BackendServiceProvider.getInstance()
    )
    return this.instance
  }

  constructor (
    private readonly backendService: BackendService
  ) {
  }

  async fetchRealEstate (realEstateId: string): Promise<RealEstate> {
    return await this.backendService.fetchRealEstate(RealEstateId.fromString(realEstateId))
  }

  async fetchFullRealEstate (realEstateId: string): Promise<RealEstate> {
    return await this.backendService.fetchFullRealEstate(RealEstateId.fromString(realEstateId))
  }

  async archive (realEstateId: RealEstateId): Promise<void> {
    await this.backendService.archiveRealEstate(realEstateId)
  }

  async deleteImage (realEstateId: RealEstateId, image: string): Promise<void> {
    await this.backendService.deleteImage(realEstateId, image)
  }

  async addImageFile (realEstateId: RealEstateId, file: File): Promise<Image> {
    return await this.backendService.uploadImage(realEstateId, file)
  }

  async addImageUrl (realEstateId: RealEstateId, urlImage: Image): Promise<any> {
    await this.backendService.addAdditionalImage(realEstateId, urlImage)
  }

  async saveDescription (realEstateId: RealEstateId, description: string): Promise<void> {
    await this.backendService.saveDescription(realEstateId, description)
  }

  async saveBasicInfo (realEstateId: RealEstateId, basicInfo: BasicInfo): Promise<void> {
    await this.backendService.saveBasicInfo(realEstateId, basicInfo)
  }

  async saveAddress (realEstateId: RealEstateId, address: Address): Promise<void> {
    await this.backendService.saveAddress(realEstateId, address)
  }

  private async saveStatus (realEstateId: RealEstateId, status: string): Promise<void> {
    await this.backendService.saveStatus(realEstateId, status)
  }

  async forReview (realEstateId: RealEstateId): Promise<void> {
    await this.backendService.toReview(realEstateId)
  }

  async publish (realEstateId: RealEstateId): Promise<void> {
    await this.backendService.publish(realEstateId)
  }

  async sold (realEstateId: RealEstateId): Promise<void> {
    await this.backendService.changeStatusToSold(realEstateId)
  }
}
