import { action, makeObservable, observable } from 'mobx'

// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export abstract class MobxStore {
  @observable isFetching = false

  protected makeObservable (): void {
    makeObservable(this)
  }

  @action
  protected startFetching (): void {
    this.isFetching = true
  }

  @action
  protected endFetching (): void {
    this.isFetching = false
  }
}
