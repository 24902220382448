import React from 'react'
import {
  Center,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay
} from '@chakra-ui/react'
import { colors } from '../../../ui/constants'
import { Heading4 } from '../../../ui/text/TitleComponent'
import DefaultInput from '../../../ui/inputs/DefaultInput'
import PrimaryButton from '../../../ui/button/PrimaryButton'
import { AuthStore } from './AuthStore'
import { observer } from 'mobx-react'

const styles = {
  container: {
    padding: 16,
    minHeight: '100%',
    backgroundImage: 'url(\'./Background.png\')',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
  },
  loginContainer: {
    borderRadius: 24,
    minWidth: 320
  }
}

export const Login = observer(({ store = AuthStore.getInstance() }: { store?: AuthStore }): JSX.Element => (
  <>
    <HStack style={styles.container}>
      <Center shadow={2} padding={'3rem'} flexDir='column' alignItems={'center'} background={colors.white}
              style={styles.loginContainer}>
        <Heading4 text='Inicia Sesión'/>
        <HStack marginY={'1rem'} w={'100%'}>
          <DefaultInput text='Email' type='email' value={store.email} onChangeText={(value) => {
            store.updateEmail(value)
          }}/>
        </HStack>
        <HStack marginTop={4} marginBottom={4} w='100%'>
          <PrimaryButton isFullWidth text='Entrar' onTap={() => {
            void store.startPasswordless()
          }}/>
        </HStack>
        {/* <Divider my={4}/>
        <Caption text='O accede con tu red social favorita'/>
        <HStack justifyContent={'space-between'} marginTop={4} gap={4}>
          <IconButton accessibilityName={'Inicia sesión con Google'}
                      isBordered
                      icon={'google'}
                      onTap={() => {
                        void store.loginWithGoogle()
                      }}
          />
          <IconButton accessibilityName={'Inicia sesión con Facebook'}
                      isBordered
                      icon={'facebook'}
                      onTap={() => {
                        void store.loginWithFacebook()
                      }}
          />
          <IconButton accessibilityName={'Inicia sesión con Apple'}
                      isBordered
                      icon={'apple'}
                      onTap={() => {
                        void store.loginWithApple()
                      }}
          />
        </HStack> */}
      </Center>
    </HStack>
    <Modal isOpen={store.showModal} onClose={() => { store.closeModal() }}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody>
          Deberías abrir tu correo para seguir con el proceso.
        </ModalBody>
      </ModalContent>
    </Modal>
  </>
))
