import { Icon } from '@chakra-ui/react'
import * as React from 'react'

const FloorIcon = ({ boxSize, color }: {
  boxSize: number | string
  color: string
}): JSX.Element => (
    <Icon viewBox="0 0 59 52" boxSize={boxSize} color={color}>
        <path
            fill='currentColor'
            d="M57.3 3.2H42.5c-.6 0-1 .4-1 1v14.6H27.7c-.6 0-1 .4-1 1v14.6H13c-.6 0-1 .4-1 1V51c0 .6.4 1 1 1s1-.4 1-1V36.4h13.8c.6 0 1-.4 1-1V20.8h13.8c.6 0 1-.4 1-1V5.2h13.8c.6 0 1-.4 1-1s-.6-1-1.1-1ZM25.3 8c0 .6.4 1 .9 1.1.6 0 1-.4 1.1-.9l.5-7c0-.3-.1-.6-.3-.8-.2-.2-.5-.3-.8-.3l-7 .5c-.6 0-1 .5-.9 1.1 0 .5.5 1 1.1.9l4.3-.3L.3 26.2c-.4.4-.4 1 0 1.4.2.2.5.3.7.3.2 0 .5-.1.7-.3L25.6 3.7 25.3 8Z"
        />
    </Icon>
)

export default FloorIcon
