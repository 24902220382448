import { Icon } from '@chakra-ui/react'
import * as React from 'react'

const PublishIcon = ({ boxSize, color }: {
  boxSize: number | string
  color: string
}): JSX.Element => (
    <Icon viewBox="0 0 1000 1000" boxSize={boxSize} color={color}>
         <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" id="file-upload">
          <g data-name="Layer 2">
            <path d="M27,20a1,1,0,0,0-1,1v6a1,1,0,0,1-1,1H7a1,1,0,0,1-1-1V21a1,1,0,0,0-2,0v6a3,3,0,0,0,3,3H25a3,3,0,0,0,3-3V21A1,1,0,0,0,27,20Z"></path>
            <path d="M10,10a1,1,0,0,0,.707-.293L15,5.414V23a1,1,0,0,0,2,0V5.42l4.29,4.29a1.014,1.014,0,0,0,1.42,0,1.008,1.008,0,0,0,0-1.42l-6-6a1.007,1.007,0,0,0-1.417,0l-6,6A1,1,0,0,0,10,10Z"></path>
          </g>
          </svg>
    </Icon>
)

export default PublishIcon
