import React, { type ReactText } from 'react'
import { type IconType } from 'react-icons'
import { Flex, type FlexProps, Icon, Link } from '@chakra-ui/react'
import { colors } from '../constants'

interface NavItemProps extends FlexProps {
  icon: IconType
  onClick: () => void
  children: ReactText
}

export const NavItem = ({ icon, children, onClick, ...rest }: NavItemProps): JSX.Element => {
  return (
    <Link
      onClick={onClick}
      style={{ textDecoration: 'none' }}
      _focus={{ boxShadow: 'none' }}
    >
      <Flex
        align="center"
        p="4"
        borderRadius="sm"
        role="group"
        cursor="pointer"
        _hover={{
          bg: colors.primary['100'],
          color: colors.primary['900']
        }}
        {...rest}
      >
        <Icon
          mr="4"
          fontSize="16"
          _groupHover={{
            color: colors.primary['900']
          }}
          as={icon}
        />
        {children}
      </Flex>
    </Link>
  )
}
