import {
  useLocation,
  Navigate
} from 'react-router-dom'
import { AuthStore } from '../../auth/infrastructure/view/AuthStore'
import { UserType } from '@howsin/common'
import React from 'react'

export const RequireAuth = ({ children, type, store = AuthStore.getInstance() }: { children: JSX.Element, type: UserType, store?: AuthStore }): JSX.Element => {
  const location = useLocation()
  if (!store.isLogged) {
    return (<Navigate to="/" state={{ from: location }} replace />)
  }

  switch (type) {
    case UserType.admin:
      if (store.isAdmin) {
        return children
      }
      break
    case UserType.agent:
      if (store.isAgent) {
        return children
      }
      break
  }

  return (<Navigate to="/" state={{ from: location }} replace />)
}
