import { Box, Flex, Spacer, ButtonGroup } from '@chakra-ui/react'
import { colors } from '../constants'
import { HowsinIcon } from '../icon/HowsinIcon'
import React from 'react'
import { AuthStore } from '../../auth/infrastructure/view/AuthStore'
import { NavbarAgent } from './NavbarAgent'
import { NavbarAdmin } from './NavbarAdmin'
import { observer } from 'mobx-react'
import IconButton from '../button/IconButton'

export const Navbar = observer(({ store = AuthStore.getInstance() }: { store?: AuthStore }): JSX.Element => {
  return (
    <Flex minWidth='max-content' height='100%' backgroundColor={colors.primary['400']} alignItems='center' gap='2'>
      <Box p='2'>
        <HowsinIcon/>
      </Box>
      <Spacer/>
      <ButtonGroup gap='2' paddingEnd={4}>
      {
        store.isAgent && <NavbarAgent />
      }
      {
        store.isAdmin && <NavbarAdmin />
      }
      {store.isLogged && <IconButton icon={'logout'} accessibilityName={'Salir'} onTap={() => { store.logOut() }}/>}
      </ButtonGroup>
    </Flex>
  )
})
