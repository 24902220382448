import { GoogleAuthProvider, getAuth, signInWithPopup } from 'firebase/auth'
import { type BackendService } from '../../../../../common/service/BackendService'
import { BackendServiceProvider } from '../../../../../common/service/BackendServiceProvider'
import { LoginProvider } from './LoginProvider'

export class GoogleLoginProvider extends LoginProvider {
  async getCredential (): Promise<string> {
    const auth = getAuth()
    const result = await signInWithPopup(auth, this.provider)
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return GoogleAuthProvider.credentialFromResult(result)!.accessToken!
  }

  private static instance: GoogleLoginProvider

  static getInstance (): GoogleLoginProvider {
    if (this.instance != null) {
      return this.instance
    }
    this.instance = new GoogleLoginProvider(BackendServiceProvider.getInstance())
    return this.instance
  }

  private readonly provider: GoogleAuthProvider

  private constructor (backendService: BackendService) {
    super(backendService)
    this.provider = new GoogleAuthProvider()
    this.provider.addScope('email')
  }
}
