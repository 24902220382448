import { Icon } from '@chakra-ui/react'
import * as React from 'react'

const ChevronUpIcon = ({ boxSize, color }: {
  boxSize: number | string
  color: string
}): JSX.Element => (
    <Icon viewBox="0 0 407.436 407.436" boxSize={boxSize} color={color}>
        <path
            fill='currentColor'
            d="M203.718 91.567 0 294.621l21.179 21.248 182.539-181.945 182.54 181.945 21.178-21.248z"
        />
    </Icon>
)

export default ChevronUpIcon
