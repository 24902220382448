import { type RealEstate } from '@howsin/common'

export class RealEstates {
  private readonly realEstateIds = new Set<string>()

  constructor (
    private realEstates: RealEstate[] = []
  ) {
    this.saveIds(realEstates)
  }

  add (...realEstates: RealEstate[]): void {
    this.saveIds(realEstates)
    this.realEstates = [...this.realEstates, ...realEstates]
      .reduce<RealEstate[]>((acc, realEstate) => {
      if (acc.find(data => data.id.toString() === realEstate.id.toString()) != null) {
        return acc
      }
      acc.push(realEstate)
      return acc
    }, [])
  }

  private saveIds (realEstates: RealEstate[]): void {
    for (const realEstate of realEstates) {
      this.realEstateIds.add(realEstate.id.toString())
    }
  }

  toArray (): RealEstate[] {
    return [...this.realEstates]
  }

  isEmpty (): boolean {
    return (this.realEstates.length === 0)
  }
}
