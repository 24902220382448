import { FeedsStore } from '../../../../feeds/infrastructure/view/FeedsStore'
import { type RealEstate, type RealEstateId } from '@howsin/common'
import { PortalApplicationService } from '../../../application/PortalApplicationService'
import { AppLifeCyclePublisher } from '../../../../event/application/AppLifeCyclePublisher'
import { FeedsApplicationService } from '../../../../feeds/application/FeedsApplicationService'

export class FeedsAgentStore extends FeedsStore {
  private static instance: FeedsAgentStore

  static getInstance (): FeedsAgentStore {
    if (this.instance != null) {
      return this.instance
    }
    this.instance = new FeedsAgentStore(
      PortalApplicationService.getInstance(),
      AppLifeCyclePublisher.getInstance(),
      FeedsApplicationService.getInstance()
    )
    return this.instance
  }

  constructor (
    private readonly portalApplicationService: PortalApplicationService,
    private readonly appLifeCyclePublisher: AppLifeCyclePublisher,
    feedsApplicationService: FeedsApplicationService
  ) {
    super(feedsApplicationService)
  }

  async getFeeds (): Promise<RealEstate[]> {
    return await this.portalApplicationService.getFeeds()
  }

  openRealEstate (id: RealEstateId): void {
    this.appLifeCyclePublisher.goToViewRealEstateAgent(id)
  }

  createRealEstate (): void {
    this.appLifeCyclePublisher.goToCreateRealEstate()
  }

  editRealEstate (id: RealEstateId): void {
    // TODO: implement
    throw new Error('Method not implemented.')
  }
}
