import { type UserType } from '@howsin/common'
import { connectAuthEmulator, getAuth } from 'firebase/auth'
import { BackendServiceProvider } from '../../../../common/service/BackendServiceProvider'
import { DotEnvConstantsService } from '../../../../common/service/constants/DotEnvConstantsService'
import { FirebaseService } from '../../../../common/service/firebase/FirebaseService'
import { type AuthService } from '../../../domain/AuthService'

export class FakeAuthService implements AuthService {
  private static instance: FakeAuthService

  static getInstance (): AuthService {
    if (this.instance != null) {
      return this.instance
    }
    this.instance = new FakeAuthService()
    return this.instance
  }

  private constructor () {
    FirebaseService.getInstance()
  }

  isLogged (): boolean {
    const auth = getAuth()
    const authEmulator = DotEnvConstantsService.getInstance().getAuthEmulator()
    connectAuthEmulator(auth, authEmulator)
    return auth.currentUser != null
  }

  async getRole (): Promise<UserType> {
    const user = await BackendServiceProvider.getInstance().whoAmI()
    return user.type
  }

  onAuthStateChanged (action: (isLogged: boolean) => void): void {
    const auth = getAuth()
    const authEmulator = DotEnvConstantsService.getInstance().getAuthEmulator()
    connectAuthEmulator(auth, authEmulator)
    auth.onAuthStateChanged(maybeUer => {
      const isLogged = maybeUer != null
      if (maybeUer != null) {
        void maybeUer.getIdToken()
          .then(token => {
            BackendServiceProvider.getInstance().setToken(token)
            action(isLogged)
          })
      } else {
        action(isLogged)
      }
    })
  }
}
