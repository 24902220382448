import { type Ad, type Advertiser } from '@howsin/common'
import { action, observable } from 'mobx'
import { MobxStore } from '../../../../../common/store/MobxStore'
import { AppLifeCyclePublisher } from '../../../../../event/application/AppLifeCyclePublisher'
import { BackOfficeApplicationService } from '../../../../application/BackOfficeApplicationService'

export class AdvertiserDetailStore extends MobxStore {
  private static instance: AdvertiserDetailStore
  static getInstance (): AdvertiserDetailStore {
    if (this.instance != null) {
      return this.instance
    }
    this.instance = new AdvertiserDetailStore(
      BackOfficeApplicationService.getInstance(),
      AppLifeCyclePublisher.getInstance()
    )
    return this.instance
  }

  @observable advertiser: Advertiser | undefined
  @observable ads: Ad[] = []

  constructor (
    private readonly backOfficeApplicationService: BackOfficeApplicationService,
    private readonly appLifeCyclePublisher: AppLifeCyclePublisher
  ) {
    super()
    this.makeObservable()
  }

  async clean (): Promise<void> {
    this.advertiser = undefined
  }

  async fetch (advertiserId: string): Promise<void> {
    this.startFetching()
    const advertiser = await this.backOfficeApplicationService.getAdvertiser(advertiserId)
    this.setAdvertiser(advertiser)
    const ads = await this.backOfficeApplicationService.getAdsFromAdvertiser(advertiserId)
    this.setAds(ads)
    this.endFetching()
  }

  @action
  setAdvertiser (advertiser: any): void {
    this.advertiser = advertiser
  }

  @action
  setAds (ads: Ad[]): void {
    this.ads = ads
  }

  goToCreateCampaing (): void {
    if (this.advertiser == null) {
      return
    }
    this.appLifeCyclePublisher.goToCreateCampaing(this.advertiser)
  }

  @action
  async archived (ad: Ad): Promise<void> {
    await this.backOfficeApplicationService.archivedCampaing(ad)
  }

  @action
  async publish (ad: Ad): Promise<void> {
    await this.backOfficeApplicationService.publishCampaing(ad.campaingId)
  }

  @action
  async active (ad: Ad): Promise<void> {
    await this.backOfficeApplicationService.activateCampaing(ad)
  }

  @action
  async pause (ad: Ad): Promise<void> {
    await this.backOfficeApplicationService.pauseCampaing(ad)
  }
}
