import React from 'react'
import algoliasearch from 'algoliasearch/lite'
import 'instantsearch.css/themes/satellite.css'
import {
  Hits,
  InstantSearch,
  Pagination,
  RangeInput,
  Menu,
  SearchBox
} from 'react-instantsearch-hooks-web'
import {
  Text,
  Image,
  Flex,
  Box,
  HStack,
  VStack
} from '@chakra-ui/react'
import { Heading3, Heading4 } from '../../../ui/text/TitleComponent'
import useVideoPoster from '../../../common/react/hooks/useVideoPoster'
import { AppLifeCyclePublisher } from '../../../event/application/AppLifeCyclePublisher'
import { RealEstateId, Uuid } from '@howsin/common'
const searchClient = algoliasearch('GUKPI1LNMR', '8d89b336ed7a9013031d2965e72f6c48')

interface PropertyHit {
  id: string
  title: string
  subtitle: string
  content: string
  basicInfo: {
    rooms: number
    price: {
      value: number
      currency: string
    }
    livingAreaSize: number
    type: string
    bathrooms: number
  }
  address: {
    zipCode: string
    number: string
    province: string
    street: string
    municipality: string
  }
  agent: {
    avatar: string
    email: string
    id: string
    nickName: string
    type: string
  }
}

const Hit: React.FC<any> = ({ hit }: { hit: PropertyHit }) => {
  // const flexDirection = useBreakpointValue({ base: 'column', md: 'row' }) as 'column' | 'row'
  const flexDirection = 'row'
  const appLifeCyclePublisher = AppLifeCyclePublisher.getInstance()
  const goDetails = (id: string): void => {
    appLifeCyclePublisher.goToEditRealEstateAdmin(new RealEstateId(new Uuid(id)))
  }
  return (
    <Box p={4} onClick={() => { goDetails(hit.id) }}>
      <Flex direction={flexDirection} justify="space-between">
        <HStack spacing={150} justifyContent={'space-between'}>
          <VStack align="flex-start" spacing={2} justifyContent={'space-between'}>
            <HStack spacing={4}>
                <Box color='gray.500' fontWeight='semibold' letterSpacing='wide' fontSize='xs' textTransform='uppercase' ml='2'>
                  <HStack>
                    <Image src={hit.agent.avatar} alt="Avatar del agente" boxSize="40px" borderRadius="full" />
                    <Text>{hit.agent.nickName}</Text>
                  </HStack>
                </Box>
            </HStack>
            <HStack spacing={4}>
              <Box color='gray.500' fontWeight='semibold' letterSpacing='wide' fontSize='xs' textTransform='uppercase' ml='2'>
                <Text>{hit.title} - {hit.basicInfo.type}</Text>
                <Text>{hit.basicInfo.rooms} hab, {hit.basicInfo.bathrooms} baños, {hit.basicInfo.livingAreaSize} m2</Text>
              </Box>
            </HStack>
            <HStack spacing={4}>
              <Box color='gray.500' fontWeight='semibold' letterSpacing='wide' fontSize='xs' ml='2'>
                <Text>{hit.address.street}, {hit.address.municipality}, {hit.address.province}</Text>
              </Box>
            </HStack>
          </VStack>
          <Flex direction={flexDirection} justify="right">
              <Image height="130px" width="auto" src={useVideoPoster(hit.content)} alt="Miniatura del video" />
          </Flex>
        </HStack>
      </Flex>
    </Box>
  )
}

export const Search = (): JSX.Element => {
  return (
    <InstantSearch indexName="prod_feeds" searchClient={searchClient}>
      <SearchBox style={{ width: '100%' }}/>
      <Flex mt={6} w={'full'} p={4}>
          <Box flex="2" pr={4} color='gray.500' fontWeight='semibold' letterSpacing='wide' fontSize='xs' ml='2'>
            <VStack align="flex-start" spacing={4} justifyContent={'space-between'}>
              <Heading4 text="Filtros" />
              <Heading3 text="Agentes" />
                <Menu attribute="agent.nickName" />
              <Heading3 text="Hab" />
                <RangeInput attribute="basicInfo.rooms" />
              <Heading3 text="Baños" />
                <RangeInput attribute="basicInfo.bathrooms" />
              <Heading3 text="Precio" />
                <RangeInput attribute="basicInfo.price.value" />
              <Heading3 text="Tamaño" />
                <RangeInput attribute="basicInfo.livingAreaSize" />
            </VStack>
          </Box>
        <Box flex="4" w="full">
          <Hits hitComponent={Hit}/>
        </Box>
        </Flex>
        <Pagination style={{ alignContent: 'center' }} />
    </InstantSearch>
  )
}
