import { MobxStore } from './MobxStore'
import { AppLifeCycleSubscribers } from '../../event/application/AppLifeCycleSubscribers'

export class AppLifeCycleStore extends MobxStore {
  private navigation?: (router: string) => void

  constructor (
    appLifeCycleSubscribers: AppLifeCycleSubscribers
  ) {
    super()
    appLifeCycleSubscribers.onGoToAdminDashboard(() => {
      this.navigation?.('/backOffice/')
    })
    appLifeCycleSubscribers.onGoToCreateAgent(() => {
      this.navigation?.('/backOffice/createAgents')
    })
    appLifeCycleSubscribers.onGoToViewRealEstateAdmin((realEstateId) => {
      this.navigation?.('/backOffice/feeds/' + realEstateId.toString())
    })
    appLifeCycleSubscribers.onGoToAgentDashboard(() => {
      this.navigation?.('/portal/feeds')
    })
    appLifeCycleSubscribers.onGoToViewRealEstateAgent((realEstateId) => {
      this.navigation?.('/portal/feeds/' + realEstateId.toString())
    })
    appLifeCycleSubscribers.onGoToCreateRealEstate(() => {
      this.navigation?.('/portal/create')
    })
    appLifeCycleSubscribers.onGoToEditRealEstateAdmin((realEstateId) => {
      this.navigation?.('/backOffice/feeds/' + realEstateId.toString())
    })
    appLifeCycleSubscribers.onGoToAgentDetailsAdmin((agentId) => {
      this.navigation?.('/backOffice/agent/' + agentId)
    })
    appLifeCycleSubscribers.onGoToCreateAdvertiser(() => {
      this.navigation?.('/backOffice/createAdvertisers')
    })
    appLifeCycleSubscribers.onGoToAdvertisersList(() => {
      this.navigation?.('/backOffice/advertisers')
    })
    appLifeCycleSubscribers.onGoToAdvertiserDetail((advertiser) => {
      this.navigation?.('/backOffice/advertiser/' + advertiser.id.toString())
    })
    appLifeCycleSubscribers.onGoToCreateCampaing((advertiser) => {
      this.navigation?.('/backOffice/createCampaing/' + advertiser.id.toString())
    })
  }

  setNavigation (navigation: (router: string) => void): void {
    this.navigation = navigation
  }

  private static instance: AppLifeCycleStore
  static getInstance (): AppLifeCycleStore {
    if (this.instance != null) {
      return this.instance
    }
    this.instance = new AppLifeCycleStore(
      AppLifeCycleSubscribers.getInstance()
    )
    return this.instance
  }
}
