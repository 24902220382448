import { action, observable } from 'mobx'
import { MobxStore } from '../../../../../common/store/MobxStore'
import { BackOfficeApplicationService } from '../../../../application/BackOfficeApplicationService'
import { UrlImage } from '@howsin/common'

export class CreateAdvertiserStore extends MobxStore {
  private static instance: CreateAdvertiserStore
  static getInstance (): CreateAdvertiserStore {
    if (this.instance != null) {
      return this.instance
    }
    this.instance = new CreateAdvertiserStore(
      BackOfficeApplicationService.getInstance()
    )
    return this.instance
  }

  @observable isValid = false
  @observable name = ''
  @observable nickName = ''
  @observable email = ''
  @observable website? = ''
  @observable avatar?: File

  constructor (
    private readonly backOfficeApplicationService: BackOfficeApplicationService
  ) {
    super()
    this.makeObservable()
  }

  @action
  clean (): void {
    this.isValid = false
    this.name = ''
    this.nickName = ''
    this.email = ''
    this.website = ''
  }

  @action
  setName (value: string): void {
    this.name = value
    this.validatingData()
  }

  @action
  setNickName (value: string): void {
    this.nickName = value
    this.validatingData()
  }

  @action
  setEmail (value: string): void {
    this.email = value
    this.validatingData()
  }

  @action
  setWebsite (value: string): void {
    this.website = value
    this.validatingData()
  }

  @action
  setAvatar (file: File): void {
    this.avatar = file
    this.validatingData()
  }

  @action
  validatingData (): void {
    this.isValid = this.name.length > 0 && this.nickName.length > 0 && this.email.length > 0
  }

  async createAdvertiser (): Promise<void> {
    if (this.isValid && this.avatar != null) {
      this.startFetching()
      const userId = await this.backOfficeApplicationService.createAdvertiser(
        this.name,
        this.nickName,
        this.email,
        this.website
      )
      const avatar = await this.backOfficeApplicationService.uploadAvatar(userId, this.avatar)
      await this.backOfficeApplicationService.sendAvatar(userId, new UrlImage(avatar.getUrl()))
      this.endFetching()
    }
  }
}
