import { type Ad, type Advertiser, type Agent, type CampaingId, type Image, type RealEstate, type RealEstateId, UserId, Uuid } from '@howsin/common'
import { type BackendService } from '../../common/service/BackendService'
import { BackendServiceProvider } from '../../common/service/BackendServiceProvider'

export class BackOfficeApplicationService {
  private static instance: BackOfficeApplicationService

  static getInstance (): BackOfficeApplicationService {
    if (this.instance != null) {
      return this.instance
    }
    this.instance = new BackOfficeApplicationService(
      BackendServiceProvider.getInstance()
    )
    return this.instance
  }

  constructor (
    private readonly backendService: BackendService
  ) {
  }

  async getAgents (): Promise<Agent[]> {
    return await this.backendService.getAgents()
  }

  async getToken (id: UserId): Promise<string> {
    return await this.backendService.getToken(id)
  }

  async getFeeds (): Promise<RealEstate[]> {
    return await this.backendService.getRealEstatesReview()
  }

  async createAgent (
    name: string,
    nickName: string,
    email: string,
    mobile: string,
    avatar: File,
    instagram?: string
  ): Promise<UserId> {
    const userId = new UserId(new Uuid().toString())
    await this.backendService.createAgent(
      userId,
      email,
      name,
      nickName,
      mobile,
      instagram
    )
    return userId
  }

  async createAdvertiser (
    name: string,
    nickName: string,
    email: string,
    website?: string
  ): Promise<UserId> {
    const userId = new UserId(new Uuid().toString())
    await this.backendService.createAdvertiser(
      userId,
      email,
      name,
      nickName,
      website
    )
    return userId
  }

  async uploadAvatar (userId: UserId, avatar: File): Promise<Image> {
    return await this.backendService.uploadAvatar(avatar, userId)
  }

  async sendAvatar (userId: UserId, avatar: Image): Promise<void> {
    await this.backendService.sendAvatar(avatar, userId)
  }

  async publish (realEstateId: RealEstateId): Promise<void> {
    await this.backendService.publish(realEstateId)
  }

  async getDashboard (): Promise<{
    newBuyers: Record<string, number>
    views: Record<string, number>
    favorites: Record<string, number>
    likes: Record<string, number>
    shared: Record<string, number>
    realEstates: Record<string, string>
  }> {
    return await this.backendService.dashboard()
  }

  async getAgentDetail (id: UserId): Promise<Agent> {
    return await this.backendService.getAgentDetail(id)
  }

  async getRealEstates (id: UserId): Promise<RealEstate []> {
    return await this.backendService.getRealEstateByAgentId(id)
  }

  async archive (realEstateId: RealEstateId): Promise<void> {
    await this.backendService.archiveRealEstate(realEstateId)
  }

  async getAdvertisers (): Promise<Advertiser[]> {
    return await this.backendService.getAdvertisers()
  }

  async getAdvertiser (advertiserId: string): Promise<Advertiser> {
    return await this.backendService.getAdvertiser(advertiserId)
  }

  async publishCampaing (campaingId: CampaingId): Promise<void> {
    await this.backendService.publishCampaing(campaingId)
  }

  async archivedCampaing (ad: Ad): Promise<void> {
    await this.backendService.archivedCampaing(ad)
  }

  async pauseCampaing (ad: Ad): Promise<void> {
    await this.backendService.pauseCampaing(ad)
  }

  async activateCampaing (ad: Ad): Promise<void> {
    await this.backendService.activateCampaing(ad)
  }

  async toReviewCampaing (campaingId: CampaingId): Promise<void> {
    await this.backendService.toReviewCampaing(campaingId)
  }

  async toDraftCampaing (campaingId: CampaingId): Promise<void> {
    await this.backendService.toDraftCampaing(campaingId)
  }

  async uploadAdMedia (campaingId: CampaingId, media: File):
  Promise<{ video: string, aspectRatio: string, duration: string }> {
    return await this.backendService.uploadVideo(campaingId, media)
  }

  async createCampaing (campaingId: CampaingId, advertiserId: string, title: string, description: string, startAt: string, endAt: string, website: string): Promise<void> {
    await this.backendService.createCampaing(campaingId, advertiserId, title, description, startAt, endAt, website)
  }

  async getAdsFromAdvertiser (advertiserId: string): Promise<Ad[]> {
    return await this.backendService.getAdsFromAdvertiser(advertiserId)
  }

  async createAd (
    campaingId: CampaingId,
    advertiserId: string,
    title: string,
    description: string,
    link: string,
    mediaUrl: string,
    aspectRatio: string,
    duration: string
  ): Promise<void> {
    await this.backendService.createAd(
      campaingId,
      advertiserId,
      title,
      description,
      link,
      mediaUrl,
      aspectRatio,
      duration
    )
  }
}
