import React from 'react'
import { type RealEstate } from '@howsin/common'
import Card from '../../../../ui/card/Card'
import { HStack, VStack, Link } from '@chakra-ui/react'
import { Body, Label } from '../../../../ui/text/BodyTextComponent'
import { Heading3 } from '../../../../ui/text/TitleComponent'
import { CopyTextComponent } from '../../../../ui/text/ClipBoardTextComponent'

export const RealEstateMetadataCard = ({ realEstate }: { realEstate: RealEstate | undefined }): JSX.Element => {
  return (
        <Card>
            <Heading3 text='Metadata'/>
            <HStack justifyContent={'space-between'} spacing={4}>
                <HStack spacing={4}>
                    <VStack align="flex-start" spacing={2}>
                        <CopyTextComponent text={realEstate?.id.toString()} />
                        { Boolean(realEstate?.webUrl?.startsWith('http')) && (
                            <Link href={realEstate?.webUrl} isExternal>
                                <Body text={'Agent web post'}/>
                            </Link>
                        )}
                    </VStack>
                </HStack>
                <VStack alignItems="flex-end" spacing={2}>
                    <HStack spacing={4}>
                        <Label text='Aspect Ratio'/>
                        <Body text={realEstate?.aspectRatio} />
                    </HStack>
                    <HStack spacing={4}>
                        <Label text='Duration'/>
                        <Body text={String(realEstate?.duration)} />
                    </HStack>
                </VStack>
            </HStack>
        </Card>
  )
}
