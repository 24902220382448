import React from 'react'
import { HStack, VStack } from '@chakra-ui/react'

import IconComponent from '../../../ui/icon/IconComponent'
import { Body, BodyWithSkeleton } from '../../../ui/text/BodyTextComponent'
import { colors } from '../../../ui/constants'
import { type BasicInfo } from '@howsin/common'

const FeedBasicInfo = ({
  basicInfo
}: { basicInfo?: BasicInfo }): JSX.Element => {
  const isFetching = basicInfo === undefined
  return (
        <HStack width='100%' justifyContent={'space-around'}>
            <VStack gap={6} alignItems={'center'}>
                <HStack>
                    <IconComponent isLarge icon={'room'} color={colors.coolGray['500']}/>
                </HStack>
                <BodyWithSkeleton isFetching={isFetching}
                                  text={basicInfo?.rooms.toString() ?? '0'}
                />
            </VStack>
            <VStack gap={6} alignItems={'center'}>
                <HStack>
                    <IconComponent isLarge icon={'bath'} color={colors.coolGray['500']}/>
                </HStack>
                <BodyWithSkeleton isFetching={isFetching}
                                  text={basicInfo?.bathrooms.toString() ?? '0'}
                />
            </VStack>
            {
                basicInfo?.floor != null && (
                    <VStack gap={6} alignItems={'center'}>
                        <HStack>
                            <IconComponent isLarge icon={'floor'} color={colors.coolGray['500']}/>
                        </HStack>
                        <Body text={basicInfo?.floor.toString() + 'º'}/>
                    </VStack>
                )
            }
            <VStack gap={6} alignItems={'center'}>
                <HStack>
                    <IconComponent isLarge icon={'size'} color={colors.coolGray['500']}/>
                </HStack>
                <BodyWithSkeleton isFetching={isFetching}
                                  text={(basicInfo?.livingAreaSize.toString() ?? '0') + 'm2'}
                />
            </VStack>
        </HStack>
  )
}

export default FeedBasicInfo
