import { Icon } from '@chakra-ui/react'
import * as React from 'react'

const RoomIcon = ({ boxSize, color }: {
  boxSize: number | string
  color: string
}): JSX.Element => (
    <Icon viewBox="0 0 56 48" boxSize={boxSize} color={color}>
        <path
            fill='currentColor'
            d="M49.9 47.1c-.6 0-1-.4-1-1v-4.5H6.2v4.5c0 .6-.4 1-1 1s-1-.4-1-1v-4.5H1.3c-.6 0-1-.4-1-1v-10c0-.2.1-.5.2-.6l5.9-12.7V3.2C6.4 1.4 7.7.1 9.6.1h36.3c1.7 0 2.8 1.1 2.8 3.1v13.7c.2.1.3.2.4.4L55 30.1c.1.2.2.4.2.6v10c0 .6-.4 1-1 1h-3.3v4.5c0 .5-.4.9-1 .9ZM2.3 39.6h50.9v-7.9H2.3v7.9Zm.6-10h49.7l-5.1-11h-4.6v.7c0 1.2-1 2.2-2.1 2.2h-8.7c-1.2 0-2.1-1-2.1-2.2v-.7h-5v.7c0 1.2-1 2.2-2.1 2.2h-8.7c-1.2 0-2.1-1-2.1-2.2v-.7H8l-5.1 11Zm29.2-15.2s-.1.1-.1.2v4.7c0 .1.1.2.1.2h8.7s.1-.1.1-.2v-4.7c0-.1-.1-.2-.1-.2h-8.7Zm-17.9 0s-.1.1-.1.2v4.7c0 .1.1.2.1.2h8.7s.1-.1.1-.2v-4.7c0-.1-.1-.2-.1-.2h-8.7Zm28.7 2.3h3.8V3.2c0-1.1-.4-1.1-.8-1.1H9.6c-.4 0-1.2.1-1.2 1.1v13.5h3.7v-2c0-1.2 1-2.2 2.1-2.2h8.7c1.2 0 2.1 1 2.1 2.2v2h5v-2c0-1.2 1-2.2 2.1-2.2h8.7c1.2 0 2.1 1 2.1 2.2v2Z"
        />
    </Icon>
)

export default RoomIcon
