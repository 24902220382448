import { colors } from '../constants'

export interface TextProps { text: string | undefined }
export const highColor = colors.coolGray['900']
export const mediumColor = colors.coolGray['500']
export const maxWidth = '66ch'
export const xxlSize = '1.5rem'
export const xlSize = '1.25rem'
export const lgSize = '1.125rem'
export const mdSize = '1rem'
export const xsSize = '0.75rem'
