export class Base64 {
  static create (text: string): Base64 {
    return new Base64(btoa(text))
  }

  constructor (private readonly value: string) {}

  toString (): string {
    return this.value
  }

  decoding (): string {
    return atob(this.toString())
  }
}
