import { LoginProvider } from './LoginProvider'
import {
  getAuth,
  signInWithCustomToken
} from 'firebase/auth'
import { type BackendService } from '../../../../../common/service/BackendService'
import { BackendServiceProvider } from '../../../../../common/service/BackendServiceProvider'

export class ImpersonatingServiceProvider extends LoginProvider {
  private token: string = ''

  addToken (token: string): this {
    this.token = token
    return this
  }

  async getCredential (): Promise<string> {
    const auth = getAuth()
    const credential = await signInWithCustomToken(auth, this.token)
    return await credential.user.getIdToken()
  }

  private static instance: ImpersonatingServiceProvider

  static getInstance (): ImpersonatingServiceProvider {
    if (this.instance != null) {
      return this.instance
    }
    this.instance = new ImpersonatingServiceProvider(BackendServiceProvider.getInstance())
    return this.instance
  }

  private constructor (backendService: BackendService) {
    super(backendService)
  }
}
