import React, { useEffect } from 'react'
import Card from '../../../../ui/card/Card'
import { Avatar, HStack, Skeleton, VStack } from '@chakra-ui/react'
import { Body } from '../../../../ui/text/BodyTextComponent'
import { Heading4 } from '../../../../ui/text/TitleComponent'
import { type Agent } from '@howsin/common'
import { CopyTextComponent } from '../../../../ui/text/ClipBoardTextComponent'

export const RealEstateAgentHeader = ({ user }: { user: Agent | undefined }): JSX.Element => {
  const [agent, setAgent] = React.useState<Agent | undefined>(user)

  useEffect(() => {
    setAgent(user)

    return () => {
      setAgent(undefined)
    }
  }, [user])

  if (agent === undefined) {
    return (
      <Card>
        <HStack justifyContent={'space-between'} spacing={4}>
          <Skeleton height="100px" width={'100%'}/>
        </HStack>
      </Card>
    )
  }

  return (
    <Card>
      <HStack justifyContent={'space-between'} spacing={4}>
        <HStack spacing={4}>
          <Avatar
            size="xl"
            name={agent?.name}
            src={agent?.avatar.getUrl()}
          />
          <VStack align="flex-start" spacing={2}>
            <Heading4 text={agent?.name} />
            <Body text={agent?.nickName}/>
          </VStack>
        </HStack>
        <VStack alignItems="flex-end" spacing={2}>
          <CopyTextComponent text={agent?.id.toString()} />
          <Body text={agent?.email} />
          <Body
              text={`+${agent?.mobile.countryCode.toString() ?? ''}  
              ${agent?.mobile.number.toString() ?? ''}`} />
        </VStack>
      </HStack>
    </Card>
  )
}

export default RealEstateAgentHeader
