import React, { useEffect, useState, useRef } from 'react'
import { Image as ImageEntity, type Video } from '@howsin/common'
import MuxVideo from '@mux/mux-video-react'
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Center,
  HStack,
  Skeleton,
  VStack,
  useDisclosure,
  Image,
  useToast
} from '@chakra-ui/react'

import Card from '../../../../ui/card/Card'
import IconButton from '../../../../ui/button/IconButton'
import { colors } from '../../../../ui/constants'
import IconComponent from '../../../../ui/icon/IconComponent'
import { AuthStore } from '../../../../auth/infrastructure/view/AuthStore'
import { RealEstateStoreContext } from './RealEstateDetail'

const RealEstateGalleryCard = (): JSX.Element => {
  // const isFetching = realEstate === undefined
  const store = React.useContext(RealEstateStoreContext)

  const [content, setcontent] = useState(
    [store?.realEstate?.video,
      ...(store?.realEstate?.images ?? [])
    ].filter(element => element !== undefined))

  const { isOpen, onOpen, onClose } = useDisclosure()
  const cancelRef = useRef<HTMLButtonElement | null>(null)
  const [selectedIndex, setSelectedIndex] = useState(0)
  const toast = useToast()

  useEffect(() => {
    setcontent(
      [store?.realEstate?.video,
        ...(store?.realEstate?.images ?? []
        )].filter(element => element !== undefined))

    return () => {
      setcontent([])
    }
  }, [store.realEstate?.images])

  const previous = (): void => {
    const newIndex = selectedIndex - 1
    if (newIndex < 0) {
      setSelectedIndex(content.length - 1)
    } else {
      setSelectedIndex(newIndex)
    }
  }

  const next = (): void => {
    const newIndex = selectedIndex + 1
    if (newIndex >= content.length) {
      setSelectedIndex(0)
    } else {
      setSelectedIndex(newIndex)
    }
  }

  if (content.length === 0) {
    return (
      <Card>
        <Skeleton height={400}
                  width="100%"
        />
      </Card>
    )
  }

  const onConfirm = async (): Promise<void> => {
    onClose()
    store.deleteImageFromRealEstate(
      (content[selectedIndex] as ImageEntity).getUrl()
    ).then(async () => {
      setcontent(content.filter((_, index) => index !== selectedIndex))
      setSelectedIndex(0)
      await Promise.resolve()
    }
    ).catch((err: any) => {
      throw err
    })
  }

  const getContent = (): JSX.Element => {
    if (content[selectedIndex] instanceof ImageEntity) {
      return <>
      <Box height="100%" width="100%" position="relative">
        <Image
          height="100%"
          src={(content[selectedIndex] as ImageEntity).getUrl()}
          fit="contain"
          alt=""
        />
        {(AuthStore.getInstance().isAdmin) && (content[selectedIndex] instanceof ImageEntity) &&
          <>
            <Button
              onClick={onOpen}
              style={{
                position: 'absolute',
                top: '275px',
                right: '20px',
                background: 'transparent',
                border: 'none',
                cursor: 'pointer'
              }}
            >
              <IconComponent isLarge icon={'rubbish'} color={colors.primary[400]}/>
            </Button>
        <AlertDialog
              isOpen={isOpen}
              leastDestructiveRef={cancelRef}
              onClose={onClose}
            >
                <AlertDialogOverlay>
                  <AlertDialogContent>
                    <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                      Delete Image
                    </AlertDialogHeader>
                    <AlertDialogBody>
                      Are you sure?
                    </AlertDialogBody>
                    <AlertDialogFooter>
                      <Button ref={cancelRef} onClick={onClose}>
                        Cancel
                      </Button>
                      <Button colorScheme='red' onClick={() => {
                        onConfirm().then(
                          () => {
                            toast({
                              title: 'Image deleted successfully',
                              status: 'success',
                              duration: 3000,
                              isClosable: true,
                              position: 'top-right'
                            })
                          }
                        ).catch(
                          () => {
                            toast({
                              title: 'Error deleting image',
                              status: 'error',
                              duration: 3000,
                              isClosable: true,
                              position: 'top-right'
                            })
                          }
                        )
                      } } ml={3}>
                        Delete
                      </Button>
                    </AlertDialogFooter>
                  </AlertDialogContent>
                </AlertDialogOverlay>
              </AlertDialog></>
    }
      </Box>
      </>
    }
    return <MuxVideo
        style={{ height: '100%', maxWidth: '100%' }}
        playbackId={
        (content[selectedIndex] as Video).getUrl()
          .replace('https://stream.mux.com/', '')
          .replace('.m3u8', '')
        }
        metadata={{
          video_id: store?.realEstate?.id.toString(),
          video_title: store?.realEstate?.address.getFullAddress()
        }}
        placeholder="Loading..."
        streamType="on-demand"
        controls
        autoPlay
        muted
        onPointerEnterCapture={() => { console.log('Pointer entered') }}
        onPointerLeaveCapture={() => { console.log('Pointer left') }}
      />
  }

  return (
    <Card>
      <HStack maxHeight='600px' overflow='hidden'>
        <HStack flexGrow={1}>
          <IconButton onTap={previous} icon="chevronLeft" accessibilityName="Previous image" />
          <Center height="100%" flexGrow={1}>
            {getContent()}
          </Center>
          <IconButton onTap={next} icon="chevronRight" accessibilityName="Next image" />
        </HStack>
        <VStack maxWidth='140px' maxHeight='inherit' overflowY='scroll'>
          {content.filter(element => element instanceof ImageEntity).map((image, index) =>
              <Box width="120px" height="120px" key={index}>
                  <Image width="100%" height="100%" objectFit="cover" src={image?.getUrl()}
                      alt={image?.getUrl()}/>
                  </Box>
          )}
        </VStack>
      </HStack>
    </Card>
  )
}

export default RealEstateGalleryCard
