import { Icon } from '@chakra-ui/react'
import * as React from 'react'

const BathIcon = ({ boxSize, color }: {
  boxSize: number | string
  color: string
}): JSX.Element => (
    <Icon viewBox="0 0 52 47" boxSize={boxSize} color={color}>
        <path
            d="M22.2 12.3v1c0 .6.4 1 1 1s1-.4 1-1v-1c0-.6-.4-1-1-1s-1 .5-1 1ZM9.7 12.3v1c0 .6.4 1 1 1s1-.4 1-1v-1c0-.6-.4-1-1-1s-1 .5-1 1Z"
            fill='currentColor'
        />
        <path
            d="M47.7 21.3H5.5v-14c0-2.8 2.4-5.1 5.2-5.1 1.4 0 2.8.6 3.7 1.5-1.8.6-3.4 1.8-4.4 3.5l-.2.4c-.2.3-.2.7 0 1 .2.3.5.5.9.5h12c.4 0 .7-.2.9-.5.2-.3.2-.7 0-1l-.2-.4c-1.3-2.4-3.8-3.9-6.6-3.9v-.1c-1.4-2-3.6-3.1-6-3.1-4 0-7.2 3.2-7.2 7.1v14c-1.8.2-3.1 1.7-3.1 3.5 0 1.3.7 2.4 1.7 3l1 6.7c0 1.9.6 3.6 1.7 5l-1.2 2.8c-.3.8-.3 1.7 0 2.5.4.8 1 1.4 1.8 1.8.4.2.8.2 1.2.2 1.3 0 2.6-.8 3.1-2.1l.8-1.9c.4.1.8.1 1.3.1H40c.4 0 .9 0 1.3-.1l.8 1.9c.7 1.7 2.6 2.5 4.3 1.8.8-.3 1.5-1 1.8-1.8.3-.8.4-1.7 0-2.5L47 39.3c1.1-1.4 1.7-3.1 1.7-5l1-6.7c1-.6 1.7-1.7 1.7-3-.1-1.7-1.7-3.3-3.7-3.3Zm-31-16c1.6 0 3.1.7 4.1 1.8h-8.2c1.1-1.1 2.5-1.8 4.1-1.8ZM7.9 44.1c-.3.7-1.1 1-1.7.7-.3-.1-.6-.4-.7-.7-.1-.3-.1-.6 0-1l.8-2c.2.1.3.2.5.3.6.4 1.2.7 1.9 1l-.8 1.7Zm38.3 0c-.1.3-.4.6-.7.7-.7.3-1.5-.1-1.7-.7l-.7-1.6c.7-.3 1.3-.6 1.9-1 .1-.1.3-.2.4-.3l.8 2c.2.2.2.6 0 .9Zm.4-9.8v.1c0 1.6-.6 3.1-1.6 4.2-.9 1.1-2.1 1.8-3.5 2.1h-.1c-.5.1-1 .2-1.5.2H11.8c-.5 0-1-.1-1.5-.2h-.1c-1.4-.3-2.6-1.1-3.5-2.1-1-1.1-1.6-2.6-1.6-4.2v-.1l-.9-6h43.4l-1 6Zm1.1-7.9H4c-.9 0-1.6-.7-1.6-1.5 0-.9.7-1.5 1.6-1.5h43.7c.9 0 1.6.7 1.6 1.5s-.7 1.5-1.6 1.5Z"
            fill='currentColor'
        />
        <path
            d="M19.1 16.4v1c0 .6.4 1 1 1s1-.4 1-1v-1c0-.6-.4-1-1-1s-1 .5-1 1ZM12.9 16.4v1c0 .6.4 1 1 1s1-.4 1-1v-1c0-.6-.4-1-1-1s-1 .5-1 1ZM16 12.3v1c0 .6.4 1 1 1s1-.4 1-1v-1c0-.6-.4-1-1-1s-1 .5-1 1Z"
            fill='currentColor'
        />
    </Icon>
)

export default BathIcon
