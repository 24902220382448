import React, { useEffect } from 'react'
import { Heading3 } from '../../../../ui/text/TitleComponent'
import {
  Wrap, Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer, Avatar, WrapItem, Box
} from '@chakra-ui/react'

import { AdvertisersStore } from './AdvertisersStore'
import { observer } from 'mobx-react'
import { SecondaryLinkButton } from '../../../../ui/button/LinkButton'
import PrimaryButton from '../../../../ui/button/PrimaryButton'
// import { AppLifeCyclePublisher } from '../../../event/application/AppLifeCyclePublisher'
import { CopyTextComponent } from '../../../../ui/text/ClipBoardTextComponent'

const Advertisers = observer(({ store = AdvertisersStore.getInstance() }: { store?: AdvertisersStore }): JSX.Element => {
  useEffect(() => {
    void store.fetch()
  }, [store])

  // const toast = useToast()

  // const goDetails = (id: string): void => {
  //   const appLifeCyclePublisher = AppLifeCyclePublisher.getInstance()
  //   appLifeCyclePublisher.goToAdvertisersDetailAdmin(id)
  // }

  return (
    <Box w={'100%'}>
      <Wrap>
        <WrapItem>
          <Heading3 text="Anunaciantes " />
        </WrapItem>
        <WrapItem>
          <PrimaryButton isFullWidth text={'Nuevo Anunciante'} onTap={() => {
            store.goToCreateadvertiser()
          }} />
        </WrapItem>
      </Wrap>
      <Wrap marginTop={8}>
        <TableContainer style={{ width: '100%' }}>
          <Table size="md">
            <Thead>
              <Tr>
                <Th></Th>
                <Th>Nombre</Th>
                <Th>Email</Th>
                <Th>Opciones</Th>
              </Tr>
            </Thead>
            <Tbody>
              {store.advertisers.map(advertiser => (
                <Tr key={advertiser.id.toString()}>
                  <Td>
                    <WrapItem>
                      <Avatar size="sm" name={advertiser.name}
                              src={advertiser.avatar.getUrl()} />
                    </WrapItem>
                  </Td>
                  <Td><SecondaryLinkButton
                      text={advertiser.name}
                      onTap={() => { store.goToDetails(advertiser) }}/></Td>
                  <Td>
                    <CopyTextComponent text={advertiser.id.toString()} />
                  </Td>
                  <Td>{advertiser.email}</Td>
                  <Td>
                    {/* <SecondaryLinkButton
                      text={'Ver'}
                      onTap={() => goDetails(advertiser.id.toString())}/> */}
                    {/* <SecondaryLinkButton text={'Impersonate'} onTap={() => {
                      void store.generateAndCopyToken(advertiser.id)
                      toast({
                        title: 'Copiado!',
                        status: 'success',
                        duration: 9000,
                        isClosable: true
                      })
                    }} /> */}
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </Wrap>
    </Box>
  )
})

export default Advertisers
