import { type Address, type BasicInfo, type RealEstate, type RealEstateFeature, RealEstateId } from '@howsin/common'
import { type BackendService } from '../../common/service/BackendService'
import { BackendServiceProvider } from '../../common/service/BackendServiceProvider'

export class PortalApplicationService {
  private static instance: PortalApplicationService

  static getInstance (): PortalApplicationService {
    if (this.instance != null) {
      return this.instance
    }
    this.instance = new PortalApplicationService(
      BackendServiceProvider.getInstance()
    )
    return this.instance
  }

  constructor (
    private readonly backendService: BackendService
  ) {
  }

  async getFeeds (): Promise<RealEstate[]> {
    return await this.backendService.getRealEstates()
  }

  async createRealEstate (basicInfo: BasicInfo, address: Address, description: string): Promise<RealEstateId> {
    const realEstateId = new RealEstateId()
    await this.backendService.createRealEstate(
      realEstateId,
      basicInfo,
      address,
      description
    )
    return realEstateId
  }

  async addVideoRealEstate (realEstateId: RealEstateId, file: File): Promise<any> {
    const video = await this.backendService.uploadVideo(realEstateId, file)
    await this.backendService.addVideo(realEstateId, video)
  }

  async addImageRealEstate (realEstateId: RealEstateId, file: File): Promise<any> {
    const video = await this.backendService.uploadImage(realEstateId, file)
    await this.backendService.addImage(realEstateId, video)
  }

  async toReview (realEstateId: RealEstateId): Promise<void> {
    await this.backendService.toReview(realEstateId)
  }

  async addFeatures (realEstateId: RealEstateId, features: RealEstateFeature[]): Promise<any> {
    await this.backendService.addFeatures(realEstateId, features)
  }
}
