import React, { type ReactNode } from 'react'
import { Box } from '@chakra-ui/react'
import { colors } from '../constants'

const Card = ({ children }: { children: ReactNode }): JSX.Element =>
  (
    <Box
      backgroundColor={colors.white}
      borderRadius="lg"
      boxShadow="rgba(99, 99, 99, 0.2) 0px 2px 8px 0px"
      overflow="hidden"
      padding="1rem"
      width='100%'
    >
      {children}
    </Box>
  )

export default Card
