import React from 'react'
import Dropzone from 'react-dropzone'
import { Caption } from '../text/BodyTextComponent'

export const DropZoneFile = ({ onChange, isMultiFiles }: { onChange: (file: File) => void, isMultiFiles?: boolean }): JSX.Element =>
  (
    <Dropzone onDropAccepted={(data) => {
      if (data.length === 1 && isMultiFiles !== true) {
        onChange(data[0])
      }
      if (isMultiFiles === true) {
        data.forEach(file => { onChange(file) })
      }
    }}>
      {({ getRootProps, getInputProps }) => (
        <div {...getRootProps()}>
          <input {...getInputProps()} />
          <Caption text={'Pulsa aquí para subir tus archivos'} />
        </div>
      )}
    </Dropzone>
  )
