import { type EventBusService } from '../domain/EventBusService'
import * as EventBusProvider from '../infrastructure/service/EventBusServiceProvider'
import { Events } from '../domain/Events'
import { type Advertiser, type RealEstateId } from '@howsin/common'

export class AppLifeCyclePublisher {
  private static instance: AppLifeCyclePublisher

  static getInstance (): AppLifeCyclePublisher {
    if (this.instance != null) {
      return this.instance
    }
    this.instance = new AppLifeCyclePublisher(
      EventBusProvider.getInstance()
    )
    return this.instance
  }

  constructor (
    private readonly eventBus: EventBusService
  ) {}

  goToAdminDashboard (): void {
    this.eventBus.publish(Events.goToAdminDashboard)
  }

  goToAgentDashboard (): void {
    this.eventBus.publish(Events.goToAgentDashboard)
  }

  goToViewRealEstateAgent (realEstateId: RealEstateId): void {
    this.eventBus.publish(Events.goToViewRealEstateAgent, realEstateId)
  }

  goToCreateRealEstate (): void {
    this.eventBus.publish(Events.goToCreateRealEstate)
  }

  goToCreateAgent (): void {
    this.eventBus.publish(Events.goToCreateAgent)
  }

  goToViewRealEstateAdmin (realEstateId: RealEstateId): void {
    this.eventBus.publish(Events.goToViewRealEstateAdmin, realEstateId)
  }

  goToEditRealEstateAdmin (realEstateId: RealEstateId): void {
    this.eventBus.publish(Events.goToEditRealEstateAdmin, realEstateId)
  }

  goToAgentsDetailAdmin (agentId: string): void {
    this.eventBus.publish(Events.goToAgentDetailsAdmin, agentId)
  }

  goToCreateAdvertiser (): void {
    this.eventBus.publish(Events.goToCreateAdvertiser)
  }

  goToAdvertiserDetail (advertiser: Advertiser): void {
    this.eventBus.publish(Events.goToAdvertiserDetail, advertiser)
  }

  goToAdvertisersList (): void {
    this.eventBus.publish(Events.goToAdvertisersList)
  }

  goToCreateCampaing (advertiser: Advertiser): void {
    this.eventBus.publish(Events.goToCreateCampaing, advertiser)
  }
}
