import { type ConstantsService } from './ConstantsService'

export class DotEnvConstantsService implements ConstantsService {
  private static instance: DotEnvConstantsService

  static getInstance (): DotEnvConstantsService {
    if (!this.instance) { // eslint-disable-line
      this.instance = new DotEnvConstantsService()
    }
    return this.instance
  }

  getBackendUrl (): string {
    if (this.isDev()) {
      return process.env.REACT_APP_DEV_BACKEND_URL ?? ''
    }
    if (this.isDemo()) {
      return process.env.REACT_APP_LOCAL_BACKEND_URL ?? ''
    } else if (this.isDemo()) {
      return process.env.REACT_APP_LOCAL_BACKEND_URL ?? ''
    }
    return process.env.REACT_APP_BACKEND_URL ?? ''
  }

  getMode (): string {
    return process.env.REACT_APP_APP_MODE ?? 'prod'
  }

  isDev (): boolean {
    return this.getMode() === 'dev'
  }

  getAuthEmulator (): string {
    return process.env.REACT_APP_AUTH_EMULATOR ?? ''
  }

  isDemo (): boolean {
    return this.getMode() === 'demo'
  }

  isProd (): boolean {
    return this.getMode() === 'prod'
  }

  getBusinessUrl (): string {
    return 'https://howsin.com'
  }

  getPortalOffice (): string {
    return 'https://pro.howsin.com'
  }

  getGoogleMapsApiKey (): string {
    return process.env.REACT_APP_GOOGLE_MAPS_API_KEY ?? ''
  }
}
