/* eslint-disable react/no-children-prop */
import React from 'react'
import { Divider, VStack, Image, Skeleton, Box, HStack } from '@chakra-ui/react'

import SecondaryButton from '../../../ui/button/SecondaryButton'
import { CaptionWithSkeleton, LabelWithSkeleton } from '../../../ui/text/BodyTextComponent'
import { Heading4WithSkeleton } from '../../../ui/text/TitleComponent'
import FeedBasicInfo from './FeedBasicInfo'
import FeedMetrics from './FeedMetrics'
import { type RealEstate } from '@howsin/common'
import Card from '../../../ui/card/Card'
// import { AuthStore } from '../../../auth/infrastructure/view/AuthStore'

const FeedCard = ({
  realEstate,
  onClick,
  onPublish,
  onArchive,
  onEdit
}: {
  realEstate?: RealEstate
  onClick?: () => void
  onPublish?: () => void
  onArchive?: () => void
  onEdit?: () => void
}): JSX.Element => {
  const isFetching = realEstate === undefined
  return (
        <Card>
            <Skeleton width='250px' height='250px' isLoaded={!isFetching}>
                {(realEstate != null) && <Image
                    boxSize='250px'
                    objectFit='cover'
                    src={realEstate.images[0]?.getUrl()}
                    alt='Real estate image'
                    w='100%'
                />}
            </Skeleton>

            <VStack paddingY={4} shadow={3} borderRadius={4}>
                <VStack alignItems='flex-start' width={'100%'}>
                    <HStack width='100%' justifyContent='space-between'>
                      <LabelWithSkeleton text={realEstate?.basicInfo.operationType === 'buy' ? 'Compra' : 'Alquiler'}
                                              isFetching={isFetching}/>
                      <Heading4WithSkeleton text={realEstate?.basicInfo.price.toString() ?? ''}
                                              isFetching={isFetching}/>
                    </HStack>
                    {/* <CaptionWithSkeleton text={realEstate?.basicInfo.type ?? ''}
                                         isFetching={isFetching}/> */}
                    <CaptionWithSkeleton
                      text={realEstate != null ? realEstate.address.street : ' '}
                      isFetching={isFetching}/>
                </VStack>
                <Box padding={4} width='100%'
                     minWidth={'100%'}>
                    <FeedBasicInfo basicInfo={realEstate?.basicInfo}/>
                </Box>
                <Divider/>
                <Box padding='4' width='100%'>
                    <FeedMetrics metrics={realEstate?.metrics}/>
                </Box>

              <SecondaryButton isFullWidth text='Ver'
                                 isDisabled={realEstate === undefined}
                                 onTap={() => onClick?.() }/>
              {/* <SecondaryButton isFullWidth text='Editar'
                                 isDisabled={realEstate === undefined}
                                 onTap={() => onEdit?.() }/> */}
              <SecondaryButton isFullWidth text='Publicar'
                               isDisabled={realEstate === undefined}
                               onTap={() => onPublish?.() }/>
              <SecondaryButton isFullWidth text='Archivar'
                              isDisabled={realEstate === undefined}
                              onTap={() => onArchive?.() }/>
            </VStack>
        </Card>
  )
}

export default FeedCard
