import { Center, Spinner } from '@chakra-ui/react'
import React, { useEffect } from 'react'
import { AuthStore } from './AuthStore'

const FinishSignUp = ({ store = AuthStore.getInstance() }: { store?: AuthStore }): JSX.Element => {
  useEffect(() => {
    void store.loginWithPasswordless()
  }, [store])

  return (
        <Center height='100vh' color='white'>
            <Spinner
                thickness='4px'
                speed='0.65s'
                emptyColor='gray.200'
                color='blue.500'
                size='xl'
            />
        </Center>
  )
}

export default FinishSignUp
