import { type EventBusService } from '../domain/EventBusService'
import * as EventBusProvider from '../infrastructure/service/EventBusServiceProvider'
import { Events } from '../domain/Events'
import { type Advertiser, type RealEstateId } from '@howsin/common'

export class AppLifeCycleSubscribers {
  private static instance: AppLifeCycleSubscribers

  static getInstance (): AppLifeCycleSubscribers {
    if (this.instance != null) {
      return this.instance
    }
    this.instance = new AppLifeCycleSubscribers(
      EventBusProvider.getInstance()
    )
    return this.instance
  }

  constructor (
    private readonly eventBus: EventBusService
  ) {}

  onGoToAdminDashboard (subscriber: () => void): void {
    this.eventBus.toSubscribe(Events.goToAdminDashboard, subscriber)
  }

  onGoToViewRealEstateAdmin (subscriber: (realEstateId: RealEstateId) => void): void {
    this.eventBus.toSubscribe(Events.goToViewRealEstateAdmin, subscriber)
  }

  onGoToAgentDashboard (subscriber: () => void): void {
    this.eventBus.toSubscribe(Events.goToAgentDashboard, subscriber)
  }

  onGoToViewRealEstateAgent (subscriber: (realEstateId: RealEstateId) => void): void {
    this.eventBus.toSubscribe(Events.goToViewRealEstateAgent, subscriber)
  }

  onGoToCreateRealEstate (subscriber: () => void): void {
    this.eventBus.toSubscribe(Events.goToCreateRealEstate, subscriber)
  }

  onGoToCreateAgent (subscriber: () => void): void {
    this.eventBus.toSubscribe(Events.goToCreateAgent, subscriber)
  }

  onGoToEditRealEstateAdmin (subscriber: (realEstateId: RealEstateId) => void): void {
    this.eventBus.toSubscribe(Events.goToEditRealEstateAdmin, subscriber)
  }

  onGoToAgentDetailsAdmin (subscriber: (agentId: string) => void): void {
    this.eventBus.toSubscribe(Events.goToAgentDetailsAdmin, subscriber)
  }

  onGoToCreateAdvertiser (subscriber: () => void): void {
    this.eventBus.toSubscribe(Events.goToCreateAdvertiser, subscriber)
  }

  onGoToAdvertisersList (subscriber: () => void): void {
    this.eventBus.toSubscribe(Events.goToAdvertisersList, subscriber)
  }

  onGoToAdvertiserDetail (subscriber: (advertiser: Advertiser) => void): void {
    this.eventBus.toSubscribe(Events.goToAdvertiserDetail, subscriber)
  }

  onGoToCreateCampaing (subscriber: (advertiser: Advertiser) => void): void {
    this.eventBus.toSubscribe(Events.goToCreateCampaing, subscriber)
  }
}
