import {
  Address,
  Amount,
  BasicInfo,
  Coordinates,
  type RealEstate,
  RealEstateType,
  type RealEstateStatus
} from '@howsin/common'
import { MobxStore } from '../../../../common/store/MobxStore'
import { FeedsApplicationService } from '../../../application/FeedsApplicationService'
import { AuthStore } from '../../../../auth/infrastructure/view/AuthStore'
import { RealEstateOperationType } from '@howsin/common/dist/src/realEstate/RealEstateOperationType'

export class RealEstateDetailStore extends MobxStore {
  realEstate?: RealEstate | undefined
  images: File[] = []
  address = {
    street: '',
    number: ' ',
    floor: '',
    municipality: '',
    province: '',
    zipCode: '',
    coordinates: {
      latitude: 0,
      longitude: 0
    },
    isHidden: false
  }

  basicInfo = {
    rooms: 0,
    bathrooms: 0,
    livingAreaSize: 0,
    price: {
      value: 0,
      currency: 'EUR'
    },
    type: RealEstateType.apartment,
    operationType: RealEstateOperationType.buy,
    floor: 0 as number | undefined,
    buildAreaSize: 0,
    plotAreaSize: 0
  }

  description: string | undefined
  status: RealEstateStatus | undefined

  private constructor (
    private readonly feedsApplicationService: FeedsApplicationService
  ) {
    super()
  }

  async fetch (realEstateId?: string): Promise<RealEstate | null> {
    let realEstate: RealEstate
    if (realEstateId == null) {
      realEstateId = this.realEstate?.id.toString() ?? ''
    }

    if (AuthStore.getInstance().isAdmin) {
      realEstate = await this.feedsApplicationService.fetchFullRealEstate(realEstateId)
    } else {
      realEstate = await this.feedsApplicationService.fetchRealEstate(realEstateId)
    }

    this.setRealEstate(realEstate)
    this.setAddress(realEstate)
    this.setBasicInfo(realEstate)
    this.description = realEstate.description
    this.status = JSON.parse(JSON.stringify(realEstate.status))
    return realEstate
  }

  clean (): void {
    this.realEstate = undefined
    this.images = []
    this.description = undefined
    this.status = undefined
  }

  private setRealEstate (realEstate: RealEstate): void {
    this.realEstate = realEstate
  }

  private setBasicInfo (realEstate: RealEstate): void {
    this.basicInfo.rooms = realEstate.basicInfo.rooms
    this.basicInfo.bathrooms = realEstate.basicInfo.bathrooms
    this.basicInfo.livingAreaSize = realEstate.basicInfo.livingAreaSize
    this.basicInfo.price.value = realEstate.basicInfo.price.toInt() / 100
    this.basicInfo.price.currency = 'EUR'
    this.basicInfo.type = realEstate.basicInfo.type
    this.basicInfo.operationType = realEstate.basicInfo.operationType
    this.basicInfo.floor = realEstate.basicInfo.floor
    this.basicInfo.buildAreaSize = realEstate.basicInfo.builtAreaSize ?? 0
    this.basicInfo.plotAreaSize = realEstate.basicInfo.plotAreaSize ?? 0
  }

  private setAddress (realEstate: RealEstate): void {
    this.address.street = realEstate.address.street
    this.address.number = realEstate.address.number ?? ''
    this.address.floor = realEstate.address.floor ?? ''
    this.address.municipality = realEstate.address.municipality
    this.address.province = realEstate.address.province
    this.address.zipCode = realEstate.address.zipCode
    this.address.coordinates.latitude = realEstate.address.coordinates.toPrimitive().latitude
    this.address.coordinates.longitude = realEstate.address.coordinates.toPrimitive().latitude
  }

  private static instance: RealEstateDetailStore
  static create (): RealEstateDetailStore {
    if (this.instance != null) {
      return this.instance
    }
    this.instance = new RealEstateDetailStore(
      FeedsApplicationService.getInstance()
    )
    return this.instance
  }

  async deleteImageFromRealEstate (imageUrl: string): Promise<void> {
    if (this.realEstate == null) {
      return
    }
    await this.feedsApplicationService.deleteImage(
      this.realEstate.id, imageUrl)
  }

  addImageToImages (image: File): void {
    this.images.push(image)
  }

  removeImageFromImages (image: File): void {
    this.images = this.images.filter((item: File) => item !== image)
  }

  async addImageToRealEstate (file: File): Promise<void> {
    if (this.realEstate == null) {
      throw new Error('RealEstate is null')
    }
    const image = await this.feedsApplicationService.addImageFile(this.realEstate.id, file)
    const url = await this.feedsApplicationService.addImageUrl(this.realEstate.id, image)
    await Promise.resolve(url)
  }

  async persistImages (): Promise<void> {
    if (this.realEstate == null) {
      return
    }
    Promise.all(this.images.map(async (file) => {
      await this.addImageToRealEstate(file)
    })).then(async () => {
      this.images = []
      this.clean()
      await Promise.resolve()
    }).catch(async (err) => {
      return await Promise.reject(err)
    })
  }

  async deleteAllImages (): Promise<void> {
    if (this.realEstate == null) {
      return
    }
    Promise.all(this.realEstate.images.map(async (file) => {
      await this.deleteImageFromRealEstate(file.getUrl())
    })).then(async (response) => {
      this.images = []
      this.clean()
      await Promise.resolve()
    }).catch(async (err) => {
      return await Promise.reject(err)
    })
  }

  setStreet (value: string): void {
    this.address.street = value
  }

  setNumber (value: string): void {
    if (this.address == null) {
      return
    }
    this.address.number = value
  }

  setCity (value: string): void {
    if (this.address == null) {
      return
    }
    this.address.municipality = value
  }

  setState (value: string): void {
    if (this.address == null) {
      return
    }
    this.address.province = value
  }

  setZipCode (value: string): void {
    if (this.address == null) {
      return
    }
    this.address.zipCode = value
  }

  setRooms (value: string): void {
    if (this.basicInfo == null) {
      return
    }
    this.basicInfo.rooms = Number(value)
  }

  setBathrooms (value: string): void {
    if (this.basicInfo == null) {
      return
    }
    this.basicInfo.bathrooms = Number(value)
  }

  setLivingAreaSize (value: string): void {
    if (this.basicInfo == null) {
      return
    }
    this.basicInfo.livingAreaSize = Number(value)
  }

  setBuildAreaSize (value: string): void {
    if (this.basicInfo == null) {
      return
    }
    this.basicInfo.buildAreaSize = Number(value)
  }

  setPlotAreaSize (value: string): void {
    if (this.basicInfo == null) {
      return
    }
    this.basicInfo.plotAreaSize = Number(value)
  }

  setPrice (value: string): void {
    if (this.basicInfo == null) {
      return
    }
    this.basicInfo.price.value = Number(value.replace(/[^0-9]/g, ''))
  }

  setType (value: string): void {
    if (this.basicInfo == null) {
      return
    }
    this.basicInfo.type = value as RealEstateType
  }

  setFloor (value: number): void {
    if (this.basicInfo == null) {
      return
    }
    this.basicInfo.floor = value
  }

  setDescription (value: string): void {
    this.description = value
  }

  setIsHidden (value: boolean): void {
    if (this.status == null) {
      return
    }
    this.address.isHidden = value
  }

  setOperationType (value: string): void {
    if (this.basicInfo == null) {
      return
    }
    this.basicInfo.operationType = value as RealEstateOperationType
  }

  getlatlng (): google.maps.LatLng {
    if (this.address == null) {
      return new google.maps.LatLng(0, 0)
    }
    const center = {
      lat: this.realEstate?.address.coordinates.toPrimitive().latitude ?? 0,
      lng: this.realEstate?.address.coordinates.toPrimitive().longitude ?? 0
    }

    return new google.maps.LatLng(center)
  }

  // TODO: add feature
  // addFeature (feature: string): void {
  //   if (this.realEstate == null) {
  //     return
  //   }
  //   this.feedsApplicationService.addFeature(this.realEstate.id, feature)
  // }

  // TODO: removeFeature
  // removeFeature (feature: string): void {
  //   if (this.realEstate == null) {
  //     return
  //   }
  //   this.feedsApplicationService.removeFeature(this.realEstate.id, feature)
  // }

  async saveBasicInfo (): Promise<void> {
    if (this.realEstate == null) {
      return
    }
    await this.feedsApplicationService.saveBasicInfo(
      this.realEstate.id,
      new BasicInfo(
        this.basicInfo.rooms,
        this.basicInfo.bathrooms,
        this.basicInfo.livingAreaSize,
        Amount.defaultCurrency(this.basicInfo.price.value),
        this.basicInfo.type,
        this.basicInfo.operationType,
        this.basicInfo.floor,
        this.basicInfo.buildAreaSize,
        this.basicInfo.plotAreaSize
      )
    ).then(async (promise) => {
      if (this.realEstate != null) {
        void this.fetch(this.realEstate?.id.toString())
      }
      return promise
    }).catch((err: Error) => {
      throw err
    }
    )
  }

  async saveAddress (): Promise<void> {
    if (this.realEstate == null) {
      return
    }
    const latlong = await this.getGeoCode(
      this.address.street + ', ' +
      this.address.number + ', ' +
      this.address.municipality + ', ' +
      this.address.province + ', ' +
      this.address.zipCode
    )
    this.feedsApplicationService.saveAddress(
      this.realEstate.id,
      new Address(
        this.address.street,
        this.address.number,
        this.address.floor,
        this.address.municipality,
        this.address.province,
        this.address.zipCode,
        new Coordinates(
          latlong.lat(),
          latlong.lng()),
        this.address.isHidden
      )).then((promise) => {
      return promise
    }).catch((err) => {
      return err
    }
    )
  }

  async saveDescription (): Promise<void> {
    if (this.realEstate == null || this.description == null) {
      return
    }
    await this.feedsApplicationService.saveDescription(this.realEstate.id, this.description)
      .then((promise) => {
        if (this.realEstate != null) {
          void this.fetch(this.realEstate?.id.toString())
        }
        return promise
      }).catch((err) => {
        throw err
      }
      )
  }

  async forReview (): Promise<void> {
    if (this.realEstate == null) {
      throw new Error('RealEstate is null')
    }
    await this.feedsApplicationService.forReview(this.realEstate?.id)
  }

  async sold (): Promise<void> {
    if (this.realEstate == null) {
      throw new Error('RealEstate is null')
    }
    await this.feedsApplicationService.sold(this.realEstate?.id)
  }

  async publish (): Promise<void> {
    if (this.realEstate == null) {
      throw new Error('RealEstate is null')
    }
    await this.feedsApplicationService.publish(this.realEstate?.id)
  }

  async getGeoCode (address: string): Promise<google.maps.LatLng> {
    const geocoder = new google.maps.Geocoder()
    return await new Promise((resolve, reject) => {
      void geocoder.geocode({ address }, (results, status) => {
        if (status === google.maps.GeocoderStatus.OK) {
          if ((results != null) && results?.length > 0) {
            resolve(results[0].geometry.location)
          } else {
            reject(new Error('No results found'))
          }
        } else {
          reject(new Error('Couldnt\'t find the location ' + address))
        }
      })
    })
  }

  async archive (): Promise<void> {
    this.startFetching()
    if (this.realEstate == null) {
      return
    }
    this.feedsApplicationService.archive(this.realEstate.id)
      .then((promise) => {
        this.clean()
        return promise
      }).catch((err) => {
        return err
      }
      )
    this.endFetching()
  }
}
