import { Icon } from '@chakra-ui/react'
import * as React from 'react'

const SizeIcon = ({ boxSize, color }: {
  boxSize: number | string
  color: string
}): JSX.Element => (
    <Icon viewBox="0 0 49 49" boxSize={boxSize} color={color}>
        <path
            fill='currentColor'
            d="M32.1 15.7H1.5c-.6 0-1 .4-1 1v30.6c0 .6.4 1 1 1h30.6c.6 0 1-.4 1-1V16.7c0-.6-.5-1-1-1Zm-1 30.6H2.5V17.7h28.6v28.6ZM32.9 5.1c-.1-.1-.1-.2-.2-.2L28.2 1c-.4-.4-1-.3-1.4.1-.4.4-.3 1.1.1 1.4l2.4 2.1H4.1l2.4-2.1c.4-.4.5-1 .1-1.4-.4-.4-1-.5-1.4-.1L.7 4.9c-.1.1-.1.2-.2.2 0 0 0 .1-.1.1-.1.1-.1.3-.1.4 0 .1 0 .3.1.4 0 0 0 .1.1.1.1.1.1.2.2.2l4.5 3.9c.2.2.4.2.7.2.3 0 .6-.1.8-.3.4-.4.3-1.1-.1-1.4L4.2 6.6h25.2L27 8.7c-.4.4-.5 1-.1 1.4.2.2.5.3.8.3.2 0 .5-.1.7-.2l4.5-3.9c.1-.1.1-.2.2-.2l.1-.1c.1-.1.1-.3.1-.4-.2-.2-.2-.3-.4-.5.1 0 .1 0 0 0ZM46.9 42.1l-2.1 2.4V19.3l2.1 2.4c.4.4 1 .5 1.4.1.4-.4.5-1 .1-1.4l-3.9-4.5c-.2-.2-.4-.3-.7-.3-.3 0-.6.1-.7.3l-3.9 4.5c-.4.4-.3 1.1.1 1.4.4.4 1.1.3 1.4-.1l2.1-2.4v25.2l-2.1-2.4c-.4-.4-1-.5-1.4-.1-.4.4-.5 1-.1 1.4l3.9 4.5c.1.1.2.1.2.2 0 0 .1 0 .1.1.1.1.3.1.4.1.1 0 .3 0 .4-.1l.1-.1c.1-.1.2-.1.2-.2l3.9-4.5c.4-.4.3-1.1-.1-1.4-.4-.3-1-.3-1.4.1Z"
        />
    </Icon>
)

export default SizeIcon
