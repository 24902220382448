import React from 'react'
import { VStack, HStack } from '@chakra-ui/react'

import IconComponent from '../../../ui/icon/IconComponent'
import { CaptionWithSkeleton } from '../../../ui/text/BodyTextComponent'
import { type Metrics } from '@howsin/common'

const FeedMetrics = ({ metrics }: { metrics?: Metrics }): JSX.Element => {
  const isFetching = metrics === undefined
  return (
        <HStack width='100%' justifyContent={'space-around'}>
            <VStack gap={4} alignItems={'center'}>
                <IconComponent
                    isLarge
                    icon={'heart'}
                    color='grey'
                />
                <CaptionWithSkeleton isFetching={isFetching}
                                     text={metrics?.likes.toString() ?? '0'}
                />
            </VStack>
            <VStack gap={4} alignItems={'center'}>
                <IconComponent
                    isLarge
                    icon={'bookmark'}
                    color='grey'
                />
                <CaptionWithSkeleton isFetching={isFetching}
                                     text={metrics?.favorites.toString() ?? '0'}
                />
            </VStack>
            <VStack gap={4} alignItems={'center'}>
                <IconComponent
                    isLarge
                    icon={'share'}
                    color='grey'
                />
                <CaptionWithSkeleton isFetching={isFetching}
                                     text={metrics?.shared.toString() ?? '0'}
                />
            </VStack>
        </HStack>
  )
}

export default FeedMetrics
