import { Icon } from '@chakra-ui/react'
import * as React from 'react'

const ChevronLeftIcon = ({ boxSize, color }: {
  boxSize: number | string
  color: string
}): JSX.Element => (
    <Icon viewBox="0 0 16 16" boxSize={boxSize} color={color}>
        <path
            fill='currentColor'
            d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
        />
    </Icon>
)

export default ChevronLeftIcon
