import { initializeApp } from 'firebase/app'
import { browserLocalPersistence, getAuth, setPersistence } from 'firebase/auth'

// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class FirebaseService {
  private static instance: FirebaseService

  static getInstance (): FirebaseService {
    if (this.instance != null) {
      return this.instance
    }
    this.instance = new FirebaseService()
    return this.instance
  }

  constructor () {
    initializeApp({
      apiKey: 'AIzaSyDFdbw_itfdGR8uEvGZdcwmdXgp5xtPWAQ',
      authDomain: 'cliphouse-81254.firebaseapp.com',
      databaseURL: 'https://cliphouse-81254-default-rtdb.europe-west1.firebasedatabase.app',
      projectId: 'cliphouse-81254',
      storageBucket: 'cliphouse-81254.appspot.com',
      messagingSenderId: '52426280699',
      appId: '1:52426280699:web:16e4c867863321128f2a5e',
      measurementId: 'G-QVR2XB4BP2'
    })
    void setPersistence(getAuth(), browserLocalPersistence)
  }
}
