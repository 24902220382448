import { Icon } from '@chakra-ui/react'
import * as React from 'react'

const ChevronDownIcon = ({ boxSize, color }: {
  boxSize: number | string
  color: string
}): JSX.Element => (
    <Icon viewBox="0 0 407.437 407.437" boxSize={boxSize} color={color}>
        <path
            fill='currentColor'
            d="m386.258 91.567-182.54 181.945L21.179 91.567 0 112.815 203.718 315.87l203.719-203.055z"
        />
    </Icon>
)

export default ChevronDownIcon
