import { Button } from '@chakra-ui/react'
import IconComponent from '../icon/IconComponent'
import React from 'react'
import { colors } from '../constants'

const PrimaryButton = ({ text, onTap, iconLeft, iconRight, isLoading, isDisabled, isFullWidth }: {
  text: string
  onTap: () => void
  iconLeft?: any
  iconRight?: any
  isLoading?: boolean
  isDisabled?: boolean
  isFullWidth?: boolean
}): JSX.Element => {
  function getWidth (fullWidth = false): string {
    return fullWidth ? '100%' : 'fit-content'
  }

  function getDisabledColor (disabled = false): string {
    return disabled ? colors.coolGray['400'] : colors.primary['900']
  }

  return (
    <Button
      leftIcon={<IconComponent icon={iconLeft} color={getDisabledColor(isDisabled)}/>}
      rightIcon={<IconComponent icon={iconRight} color={getDisabledColor(isDisabled)}/>}
      bg={colors.primary['400']}
      border="0"
      borderRadius="1.5rem"
      color={colors.primary['900']}
      fontWeight="bold"
      maxHeight={'2.75rem'}
      minWidth={'4rem'}
      p={'0.5rem 1rem'}
      w={getWidth(isFullWidth)}
      _hover={{
        bg: colors.primary['500'],
        cursor: 'pointer'
      }}
      _active={{
        bg: colors.primary['600']
      }}
      _disabled={{
        bg: colors.coolGray['200'],
        color: colors.coolGray['400'],
        cursor: 'default'
      }}
      isDisabled={isDisabled}
      isLoading={isLoading}
      onClick={onTap}
    >
      {text}
    </Button>
  )
}

export default PrimaryButton
