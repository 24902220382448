export const colors = {
  primary: {
    50: '#FFFBEB',
    100: '#FEF3C7',
    200: '#FDE68A',
    300: '#FCD34D',
    400: '#FBBF24',
    500: '#F59E0B',
    600: '#D97706',
    700: '#B45309',
    800: '#92400E',
    900: '#78350F'
  },
  secondary: {
    50: '#F5F3FF',
    100: '#EDE9FE',
    200: '#DDD6FE',
    300: '#C4B5FD',
    400: '#A78BFA',
    500: '#8B5CF6',
    600: '#7C3AED',
    700: '#6D28D9',
    800: '#5B21B6',
    900: '#4C1D95'
  },
  coolGray: {
    50: '#F9FAFB',
    100: '#F3F4F6',
    200: '#E5E7Eb',
    300: '#D1D5DB',
    400: '#9CA3AF',
    500: '#6B7280',
    600: '#4B5563',
    700: '#374151',
    800: '#1F2937',
    900: '#111827'
  },
  feedBack: {
    info: {
      light: '#BAE6FD',
      medium: '#0EA5E9',
      dark: '#0369A1'
    },
    success: {
      light: '#BBF7D0',
      medium: '#22C55E',
      dark: '#15803D'
    },
    error: {
      light: '#FECACA',
      medium: '#EF4444',
      dark: '#B91C1C'
    }
  },
  white: '#fff',
  black: '#000'
}

export const text = {
  color: colors.coolGray['900'],
  size: {
    default: 16,
    bold: 'bold',
    large: 26,
    extraLarge: 32,
    h1: 26
  }
}
