import React, { type HTMLInputTypeAttribute } from 'react'
import { Box, FormControl, FormLabel, Input } from '@chakra-ui/react'
import { observer } from 'mobx-react'
import { backgroundColor, borderRadius, containerStyle, borderWidth, borderColor, padding } from './InputStyle'

const DefaultInput = observer(({ text, onChangeText, type, isDisabled, value, isRequired }: {
  text: string
  onChangeText: (value: string) => void
  value?: string
  type?: HTMLInputTypeAttribute
  isDisabled?: boolean
  isRequired?: boolean
}): JSX.Element =>
  (
    <FormControl>
      { (value != null || value !== '') &&
        <FormLabel>{text}</FormLabel>
      }
      <Box style={containerStyle}>
        <Input
          bg={backgroundColor}
          borderColor={borderColor}
          borderRadius={borderRadius}
          borderWidth={borderWidth}
          padding={padding}
          placeholder={text}
          type={type ?? 'text'}
          value={value}
          required={isRequired}
          onChange={(e) => { onChangeText(e.target.value) }}
          isDisabled={isDisabled}
          width='100%'
        />
      </Box>
    </FormControl>
  ))

export default DefaultInput
