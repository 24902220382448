import { Icon } from '@chakra-ui/react'
import * as React from 'react'

const LocationIcon = ({ boxSize, color }: {
  boxSize: number | string
  color: string
}): JSX.Element => (
    <Icon viewBox="0 0 20 20" boxSize={boxSize} color={color}>
        <path
            fill='currentColor'
            d="M10 20S3 10.87 3 7a7 7 0 1 1 14 0c0 3.87-7 13-7 13zm0-11a2 2 0 1 0 0-4 2 2 0 0 0 0 4z"
        />
    </Icon>
)

export default LocationIcon
