import React from 'react'
import {
  Center,
  Checkbox,
  HStack,
  FormLabel,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Spinner,
  Textarea,
  VStack, Box, Image, SimpleGrid
} from '@chakra-ui/react'

import BackButton from '../../../../ui/button/BackButton'
import PrimaryButton from '../../../../ui/button/PrimaryButton'
import DefaultInput from '../../../../ui/inputs/DefaultInput'
import { Subtitle } from '../../../../ui/text/BodyTextComponent'
import { colors } from '../../../../ui/constants'
import DefaultSelect from '../../../../ui/inputs/DefaultSelect'
import { observer } from 'mobx-react'
import Card from '../../../../ui/card/Card'
import { CreateRealEstateStore } from './CreateRealEstateStore'
import { DropZoneFile } from '../../../../ui/inputs/DropZoneFile'
import { useGoBack } from '../../../../common/react/hooks/useGoBack'
import { RealEstateFeature } from '@howsin/common'
import { borderRadius } from '../../../../ui/inputs/InputStyle'
import { Wrapper } from '@googlemaps/react-wrapper'
// import RealEstateMapView from '../../../../common/map/RealEstateMapView'
// import RealEstateAddressAutocomplete from '../../../../common/map/RealEstateAddressAutocomplete'
import SwitchButton from '../../../../ui/button/SwitchButton'
import { DotEnvConstantsService } from '../../../../common/service/constants/DotEnvConstantsService'

const createStyles = {
  dropArea: {
    backgroundColor: colors.coolGray['200'],
    borderRadius: 8,
    cursor: 'pointer',
    minHeight: 80,
    marginVertical: 8,
    width: '100%'
  }
}

const CreateRealEstate = observer(({ store = CreateRealEstateStore.getInstance() }: { store?: CreateRealEstateStore }) => {
  const goBack = useGoBack()

  return (
    <>
      <BackButton text="Nueva publicación" />

      {/* <Subtitle text="Buscar direccion" />
      <RealEstateAddressAutocomplete store={store}/> */}

      <Card>
        <VStack alignItems='start' gap={4} my={8} width="100%">
          <Subtitle text="Dirección" />
          <HStack gap={4} marginY={'1rem'} width="100%">
            <Center flexGrow={4}>
              <DefaultInput text="Via" value={store.street} onChangeText={(value) => {
                store.setStreet(value)
              }} />
            </Center>
            <Center flexGrow={1}>
              <DefaultInput text="Número" value={store.number} onChangeText={(value) => {
                store.setNumber(value)
              }} />
            </Center>
          </HStack>
          <HStack gap={4} width="100%">
            <Center flexGrow={1}>
              <DefaultInput text="Código postal" value={store.zipCode} onChangeText={(value) => {
                store.setZipCode(value)
              }} />
            </Center>
            <Center flexGrow={1}>
              <DefaultInput text="Población" value={store.municipality} onChangeText={(value) => {
                store.setCity(value)
              }} />
            </Center>
            <Center flexGrow={1}>
              <DefaultSelect placeholder="Provincia"
                             onChange={(value) => { store.setState(value) }}
                             optionsArray={[
                               { value: 'Álava', text: 'Álava' },
                               { value: 'Albacete', text: 'Albacete' },
                               { value: 'Alicante', text: 'Alicante' },
                               { value: 'Almería', text: 'Almería' },
                               { value: 'Asturias', text: 'Asturias' },
                               { value: 'Ávila', text: 'Ávila' },
                               { value: 'Badajoz', text: 'Badajoz' },
                               { value: 'Barcelona', text: 'Barcelona' },
                               { value: 'Burgos', text: 'Burgos' },
                               { value: 'Cáceres', text: 'Cáceres' },
                               { value: 'Cádiz', text: 'Cádiz' },
                               { value: 'Cantabria', text: 'Cantabria' },
                               { value: 'Castellón', text: 'Castellón' },
                               { value: 'Ciudad Real', text: 'Ciudad Real' },
                               { value: 'Córdoba', text: 'Córdoba' },
                               { value: 'Coruña', text: 'Coruña' },
                               { value: 'Cuenca', text: 'Cuenca' },
                               { value: 'Girona', text: 'Girona' },
                               { value: 'Granada', text: 'Granada' },
                               { value: 'Guadalajara', text: 'Guadalajara' },
                               { value: 'Guipúzcoa', text: 'Guipúzcoa' },
                               { value: 'Huelva', text: 'Huelva' },
                               { value: 'Huesca', text: 'Huesca' },
                               { value: 'Islas Baleares', text: 'Islas Baleares' },
                               { value: 'Jaén', text: 'Jaén' },
                               { value: 'La Rioja', text: 'La Rioja' },
                               { value: 'Las Palmas', text: 'Las Palmas' },
                               { value: 'León', text: 'León' },
                               { value: 'Lleida', text: 'Lleida' },
                               { value: 'Lugo', text: 'Lugo' },
                               { value: 'Madrid', text: 'Madrid' },
                               { value: 'Málaga', text: 'Málaga' },
                               { value: 'Murcia', text: 'Murcia' },
                               { value: 'Navarra', text: 'Navarra' },
                               { value: 'Ourense', text: 'Ourense' },
                               { value: 'Palencia', text: 'Palencia' },
                               { value: 'Pontevedra', text: 'Pontevedra' },
                               { value: 'Salamanca', text: 'Salamanca' },
                               { value: 'Santa Cruz de Tenerife', text: 'Santa Cruz de Tenerife' },
                               { value: 'Segovia', text: 'Segovia' },
                               { value: 'Sevilla', text: 'Sevilla' },
                               { value: 'Soria', text: 'Soria' },
                               { value: 'Tarragona', text: 'Tarragona' },
                               { value: 'Teruel', text: 'Teruel' },
                               { value: 'Toledo', text: 'Toledo' },
                               { value: 'Valencia', text: 'Valencia' },
                               { value: 'Valladolid', text: 'Valladolid' },
                               { value: 'Vizcaya', text: 'Vizcaya' },
                               { value: 'Zamora', text: 'Zamora' },
                               { value: 'Zaragoza', text: 'Zaragoza' }
                             ]} accessibilityKey={'Selecciona Provincia'}
              />
            </Center>
          </HStack>
          <HStack gap={2} width="100%">
            <FormLabel>Ocultar Ubicacion</FormLabel>
             <SwitchButton size='md' value={store.isHidden} onChange={(event) => { store.setIsHidden(event.target.checked) }}/>
          </HStack>
           <HStack gap={4} width="100%">
            <Center flexGrow={4}>
              <Wrapper apiKey={DotEnvConstantsService.getInstance().getGoogleMapsApiKey()}>
                {/* <RealEstateMapView
                  center={store?.latlng}
                  zoom={10}
                  isHidden={store.isHidden}/> */}
              </Wrapper>
            </Center>
            </HStack>
        </VStack>
      </Card>

      <Card>
        <VStack alignItems='start' gap={4} my={8} width="100%">
          <Subtitle text="Características" />
          <HStack gap={4} marginY={'1rem'} width="40%">
            <DefaultSelect placeholder="Tipo de Operación"
                           onChange={(value) => { store.setOperationType(value) }}
                           optionsArray={[
                             { text: 'Compra', value: 'buy' },
                             { text: 'Alquiler', value: 'rent' }
                           ]} accessibilityKey={'Selecciona Tipo Operación'}
            />
          </HStack>
          <HStack gap={4} marginY={'1rem'} width="40%">
            <DefaultSelect placeholder="Tipo"
                           onChange={(value) => { store.setType(value) }}
                           optionsArray={[
                             { text: 'Piso', value: 'flat' },
                             { text: 'Apartamento', value: 'apartment' },
                             { text: 'Ático', value: 'penthouse' },
                             { text: 'Casa', value: 'house' },
                             { text: 'Chalet', value: 'chalet' },
                             { text: 'Villa', value: 'villa' },
                             { text: 'Duplex', value: 'duplex' },
                             { text: 'Atico Duplex', value: 'duplex penthouse' },
                             { text: 'Loft', value: 'loft' },
                             { text: 'Casa Rustica', value: 'townhouse' },
                             { text: 'Terreno', value: 'plot' }
                           ]} accessibilityKey={'Selecciona Tipo'}
            />
          </HStack>
          <HStack gap={4} width="100%">
            <Center width="20%">
              <DefaultInput text="Dormitorios"
                            value={store.rooms.toString()}
                            type='number'
                            onChangeText={(value) => { store.setRooms(value) }}
                            isRequired
              />
            </Center>
            <Center width="20%">
              <DefaultInput text="Baños"
                            value={store.bathrooms.toString()}
                            type='number'
                            onChangeText={(value) => { store.setBathrooms(value) }}
                            isRequired
              />
            </Center>
          </HStack>
          <HStack gap={4} width="100%">
              <Center width="30%">
                <DefaultInput text="Superfície Util"
                              value={store.livingAreaSize?.toString()}
                              type='number'
                              onChangeText={(value) => { store.setLivingAreaSize(value) }}
                              isRequired
                />
              </Center>
              <Center width="30%">
                <DefaultInput text="Superfície Construida"
                              value={store.buildAreaSize?.toString()}
                              type='number'
                              onChangeText={(value) => { store.setBuildAreaSize(value) }}
                />
              </Center>
              {(store.type === 'plot' || store.type === 'villa' || store.type === 'chalet' || store.type === 'townhouse') && (
                  <Center width="30%">
                    <DefaultInput text="Superficie del Terreno"
                                  value={store.plotAreaSize?.toString()}
                                  type='number'
                                  onChangeText={(value) => { store.setPlotAreaSize(value) }}

                    />
                  </Center>)
              }
            </HStack>
          <HStack gap={4} marginY={'1rem'} width="50%">
            <Center width="49%">
              <DefaultInput text="Precio" value={store.getPrice()} onChangeText={(value) => { store.setAmount(value) }} />
            </Center>
          </HStack>
          <VStack alignItems='start' width="100%">
            <FormLabel>Descripción</FormLabel>
            <Textarea h={20} placeholder="Descripción" width="100%" borderRadius={borderRadius}
                      value={store.description}
                      onChange={(value) => { store.setDescription(value.target.value) }}
                      paddingX={'0.5rem'} />
          </VStack>
          <HStack width="100%" marginY={'1rem'} flexWrap={'wrap'}>
            <VStack flexGrow={1} alignItems='start'>
              <Checkbox onChange={(event) => {
                if (event.target.checked) {
                  store.addFeature(RealEstateFeature.elevator)
                } else {
                  store.removeFeature(RealEstateFeature.elevator)
                }
              }}>Ascensor</Checkbox>
              <Checkbox onChange={(event) => {
                if (event.target.checked) {
                  store.addFeature(RealEstateFeature.builtInCabinets)
                } else {
                  store.removeFeature(RealEstateFeature.builtInCabinets)
                }
              }}>Armarios empotrados</Checkbox>
              <Checkbox onChange={(event) => {
                if (event.target.checked) {
                  store.addFeature(RealEstateFeature.terrace)
                } else {
                  store.removeFeature(RealEstateFeature.terrace)
                }
              }}>Terraza</Checkbox>
              <Checkbox onChange={(event) => {
                if (event.target.checked) {
                  store.addFeature(RealEstateFeature.garage)
                } else {
                  store.removeFeature(RealEstateFeature.garage)
                }
              }}>Garage</Checkbox>
            </VStack>
            <VStack flexGrow={1} alignItems='start'>
              <Checkbox onChange={(event) => {
                if (event.target.checked) {
                  store.addFeature(RealEstateFeature.balcony)
                } else {
                  store.removeFeature(RealEstateFeature.balcony)
                }
              }}>Balcón</Checkbox>
              <Checkbox onChange={(event) => {
                if (event.target.checked) {
                  store.addFeature(RealEstateFeature.pool)
                } else {
                  store.removeFeature(RealEstateFeature.pool)
                }
              }}>Piscina</Checkbox>
              <Checkbox onChange={(event) => {
                if (event.target.checked) {
                  store.addFeature(RealEstateFeature.storageRoom)
                } else {
                  store.removeFeature(RealEstateFeature.storageRoom)
                }
              }}>Trastero</Checkbox>
              <Checkbox onChange={(event) => {
                if (event.target.checked) {
                  store.addFeature(RealEstateFeature.parkland)
                } else {
                  store.removeFeature(RealEstateFeature.parkland)
                }
              }}>Zonas Verdes</Checkbox>
            </VStack>
            <VStack flexGrow={1} alignItems='start'>
              <Checkbox onChange={(event) => {
                if (event.target.checked) {
                  store.addFeature(RealEstateFeature.airConditioning)
                } else {
                  store.removeFeature(RealEstateFeature.airConditioning)
                }
              }}>A/C</Checkbox>
              <Checkbox onChange={(event) => {
                if (event.target.checked) {
                  store.addFeature(RealEstateFeature.individualHeating)
                } else {
                  store.removeFeature(RealEstateFeature.individualHeating)
                }
              }}>Calefacción individual</Checkbox>
              <Checkbox onChange={(event) => {
                if (event.target.checked) {
                  store.addFeature(RealEstateFeature.centralHeating)
                } else {
                  store.removeFeature(RealEstateFeature.centralHeating)
                }
              }}>Calefacción Central</Checkbox>
              <Checkbox onChange={(event) => {
                if (event.target.checked) {
                  store.addFeature(RealEstateFeature.gymnasium)
                } else {
                  store.removeFeature(RealEstateFeature.gymnasium)
                }
              }}>Gimnasio</Checkbox>
            </VStack>
            <VStack flexGrow={1} alignItems='start'>
              <Checkbox onChange={(event) => {
                if (event.target.checked) {
                  store.addFeature(RealEstateFeature.dressingRoom)
                } else {
                  store.removeFeature(RealEstateFeature.dressingRoom)
                }
              }}>Vestidor</Checkbox>
              <Checkbox onChange={(event) => {
                if (event.target.checked) {
                  store.addFeature(RealEstateFeature.highCeilings)
                } else {
                  store.removeFeature(RealEstateFeature.highCeilings)
                }
              }}>Techos altos</Checkbox>
              <Checkbox onChange={(event) => {
                if (event.target.checked) {
                  store.addFeature(RealEstateFeature.indoor)
                } else {
                  store.removeFeature(RealEstateFeature.indoor)
                }
              }}>Interior</Checkbox>
              <Checkbox onChange={(event) => {
                if (event.target.checked) {
                  store.addFeature(RealEstateFeature.exterior)
                } else {
                  store.removeFeature(RealEstateFeature.exterior)
                }
              }}>Exterior</Checkbox>
            </VStack>
            <VStack flexGrow={1} alignItems='start'>
              <Checkbox onChange={(event) => {
                if (event.target.checked) {
                  store.addFeature(RealEstateFeature.firstFloor)
                } else {
                  store.removeFeature(RealEstateFeature.firstFloor)
                }
              }}>Primeras Plantas</Checkbox>
              <Checkbox onChange={(event) => {
                if (event.target.checked) {
                  store.addFeature(RealEstateFeature.topFloors)
                } else {
                  store.removeFeature(RealEstateFeature.topFloors)
                }
              }}>Últimas Plantas</Checkbox>
            </VStack>
          </HStack>
        </VStack>
      </Card>

      <Card>
        <VStack alignItems='start' gap={4} my={8} width="100%">
          <Subtitle text="Video" />
          <Center style={createStyles.dropArea}>
            <DropZoneFile onChange={(file) => { store.addVideo(file) }} />
          </Center>
        </VStack>
        { store.video != null && <video autoPlay
              muted
              width="375"
              height="250"
              controls
              src={URL.createObjectURL(store.video)} />}
      </Card>

      <Card>
        <VStack alignItems='start' gap={4} my={8} width="100%">
          <Subtitle text="Imágenes" />
          <Center style={createStyles.dropArea}>
            <DropZoneFile onChange={(file) => { store.addImage(file) }} isMultiFiles/>
          </Center>
        </VStack>
        { store.images.length !== 0 && (<HStack maxWidth='inherit' display={'flex'} overflowX='scroll'>
            <SimpleGrid columns={7} spacing={3}>
            { store.images.map(image =>
              <Box width="120px" height="120px" key={image.name}>
                <Image width="100%" height="100%" objectFit="cover" src={URL.createObjectURL(image)} alt=""/>
              </Box>
            )}
                </SimpleGrid>
          </HStack>
        )}
      </Card>

      <PrimaryButton isFullWidth text={'Publicar'} onTap={() => {
        void store.send()
          .then(() => { goBack() })
      }} />

      <Modal isOpen={store.isFetching} onClose={() => {}}>
        <ModalOverlay />
        <ModalContent>
          <ModalBody>
            <Spinner
              thickness='4px'
              speed='0.65s'
              emptyColor='gray.200'
              size='xl'
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
})
export default CreateRealEstate
