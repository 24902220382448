import { Icon } from '@chakra-ui/react'
import * as React from 'react'

const CheckCircleIcon = ({ boxSize, color }: {
  boxSize: number | string
  color: string
}): JSX.Element => (
    <Icon viewBox="0 0 24 24" boxSize={boxSize} color={color}>
        <path
            fill='currentColor'
            d="M1 12C1 5.925 5.925 1 12 1s11 4.925 11 11-4.925 11-11 11S1 18.075 1 12zm16.28-2.72a.75.75 0 0 0-1.06-1.06l-5.97 5.97-2.47-2.47a.75.75 0 0 0-1.06 1.06l3 3a.75.75 0 0 0 1.06 0l6.5-6.5z"
        />
    </Icon>
)

export default CheckCircleIcon
