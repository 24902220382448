import { Icon } from '@chakra-ui/react'
import * as React from 'react'

const MenuIcon = ({ boxSize, color }: {
  boxSize: number | string
  color: string
}): JSX.Element => (
    <Icon viewBox="0 0 30 30" boxSize={boxSize} color={color}>
        <path
            fill='currentColor'
            d="M3 7a1 1 0 1 0 0 2h24a1 1 0 1 0 0-2H3zm0 7a1 1 0 1 0 0 2h24a1 1 0 1 0 0-2H3zm0 7a1 1 0 1 0 0 2h24a1 1 0 1 0 0-2H3z"
        />
    </Icon>
)

export default MenuIcon
