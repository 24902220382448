/* eslint-disable no-void */
import React, { useEffect } from 'react'
import { Center, HStack, Wrap, WrapItem, Image, useToast } from '@chakra-ui/react'
import { observer } from 'mobx-react'
import { Uuid } from '@howsin/common'
import FeedCard from './FeedCard'
import { PrimaryLinkButton } from '../../../ui/button/LinkButton'
import { Heading3 } from '../../../ui/text/TitleComponent'
import { type FeedsStore } from './FeedsStore'
import { type FeedsAdminStore } from '../../../backOffice/infrastructure/view/feeds/FeedsAdminStore'
import SecondaryButton from '../../../ui/button/SecondaryButton'
// import { colors } from '../../../ui/constants'

// const feedIndexStyles = {
//   greyText: {
//     color: colors.coolGray['500'],
//     marginTop: 1
//   }
// }

const Feeds = observer(({ store }: { store: FeedsStore }) => {
  useEffect(() => {
    void store.fetch()
  }, [store])
  const toast: any = useToast()
  const LoadFeedList = (): any => {
    return (
    <>
      <Wrap>
        {/* <Button onClick={ void store.refresh() }>
          Fetch
        </Button> */}
        {store.realEstates.toArray().slice(0).map(realEstate => (
          <WrapItem key={realEstate.id.toString()}>
            <FeedCard realEstate={realEstate}
                      onClick={() => { store.openRealEstate(realEstate.id) }}
                      onEdit={() => { store.editRealEstate(realEstate.id) }}
                      onArchive={() => {
                        void store.archive(realEstate.id).then(() => {
                          toast({
                            title: 'Éxito.',
                            description: 'Post Eliminado.',
                            status: 'info',
                            position: 'top',
                            isClosable: true
                          })
                        }).catch(() => {
                          toast({
                            title: 'Error.',
                            description: 'Algo salió mal.',
                            position: 'top',
                            status: 'error',
                            isClosable: true
                          })
                        })
                      }}
                      onPublish={() => {
                        void (store as FeedsAdminStore)
                          .publish(realEstate.id).then(() => {
                            toast({
                              title: 'Éxito.',
                              description: 'Post publicado.',
                              status: 'success',
                              position: 'top',
                              isClosable: true
                            })
                          }).catch(() => {
                            toast({
                              title: 'Error.',
                              description: 'Algo salió mal.',
                              position: 'top',
                              status: 'error',
                              isClosable: true
                            })
                          })
                      }} />
          </WrapItem>
        ))}
        {store.isFetching && [new Uuid(), new Uuid(), new Uuid()]
          .map((id) => (<WrapItem key={id.toString()}>
            <FeedCard />
          </WrapItem>))
        }
      </Wrap>
      <HStack justifyContent="center" width="100%" marginY={'1rem'}>
        <PrimaryLinkButton text="Show more" onTap={() => { store.morePage() }} />
      </HStack>
      </>
    )
  }

  const LoadCallToAction = (): any => {
    return (
      <>
        <HStack justifyContent="center" width="100%">
          <Center flexGrow={1}>
            <Image src={require('./assets/howsin-call-to-action-feed.png')} style={ { width: 540, height: 360 } }/>
          </Center>
        </HStack>
        <HStack justifyContent="center" width="100%">
          <Center flexGrow={1}>
            <SecondaryButton text={'+ Nueva publicación'} onTap={() => { store.createRealEstate() } }/>
          </Center>
        </HStack>
      </>
    )
  }

  return (
      <>
      <Heading3 text="Feed"/>
        {(store.realEstates.isEmpty() && !store.isFetching)
          ? LoadCallToAction()
          : LoadFeedList()}
      </>)
})
export default Feeds
