import React, { useEffect } from 'react'
import { ChakraProvider, Grid, GridItem, theme } from '@chakra-ui/react'
import { Route, Routes, useNavigate } from 'react-router-dom'
import { Login } from './auth/infrastructure/view/Login'
import Footer from './ui/footer/Footer'
import { Navbar } from './ui/navbar/Navbar'
import { AppLifeCycleStore } from './common/store/AppLifeCycleStore'
import FinishSignUp from './auth/infrastructure/view/FinishSignUp'
import { observer } from 'mobx-react'
import { RequireAuth } from './common/auth/RequireAuth'
import { UserType } from '@howsin/common'
import { BackOffice } from './backOffice/infrastructure/view/BackOffice'
import Agents from './backOffice/infrastructure/view/Agents'
import Feeds from './feeds/infrastructure/view/Feeds'
import { FeedsAdminStore } from './backOffice/infrastructure/view/feeds/FeedsAdminStore'
import { FeedsAgentStore } from './portal/infrastructure/view/feeds/FeedsAgentStore'
import { Portal } from './portal/infrastructure/view/Portal'
import CreateRealEstate from './portal/infrastructure/view/create/CreateRealEstate'
import RealEstateDetail from './feeds/infrastructure/view/detail/RealEstateDetail'
import { CreateAgent } from './backOffice/infrastructure/view/createAgent/CreateAgent'
import { Dashboard } from './backOffice/infrastructure/view/Dashboard'
import { Search } from './backOffice/infrastructure/view/Search'
import AgentDetail from './backOffice/infrastructure/view/AgentDetail'
import { CreateAdvertiser } from './backOffice/infrastructure/view/advertisers/createAdvertiser/CreateAdvertiser'
import Advertisers from './backOffice/infrastructure/view/advertisers/Advertisers'
import { AdvertiserDetailStore } from './backOffice/infrastructure/view/advertisers/advettiserDetail/AdvertiserDetailStore'
import AdvertisersDetail from './backOffice/infrastructure/view/advertisers/advettiserDetail/AdvertiserDetail'
import { CreateCampaingStore } from './backOffice/infrastructure/view/campaings/createCampaing/CreateCampaingStore'
import { CreateCampaign } from './backOffice/infrastructure/view/campaings/createCampaing/CreateCampaing'

export const App = observer(({ store = AppLifeCycleStore.getInstance() }: { store?: AppLifeCycleStore }): JSX.Element => {
  const navigate = useNavigate()

  console.log('App Howsin MODE => ', process.env.NODE_ENV)
  console.log('App Howsin APP_MODE => ', process.env.REACT_APP_APP_MODE)

  useEffect(() => {
    store?.setNavigation(navigate)
  }, [store, navigate])

  return (
    <ChakraProvider theme={theme}>
      <Grid
        templateAreas={`"header"
                    "main"`}
        gridTemplateRows={'56px 1fr auto'}
        h="100vh"
        color="blackAlpha.700"
        fontWeight="bold"
      >
        <GridItem area={'header'}>
          <Navbar />
        </GridItem>
        <GridItem area={'main'}>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path='.well-known/assetlinks.json' element={'public/.well-know/assetlinks.json'}/>
            <Route path="/finishSignUp" element={<FinishSignUp />} />
            <Route path="/backOffice/" element={
              <RequireAuth type={UserType.admin}>
                <BackOffice />
              </RequireAuth>}>
              <Route path="dashboard" element={<Dashboard />} />
              <Route path="agents" element={<Agents />} />
              <Route path="createAgents" element={<CreateAgent />} />
              <Route path="advertisers" element={<Advertisers />} />
              <Route path='advertiser/:advertiserId' element={<AdvertisersDetail store={AdvertiserDetailStore.getInstance()}/>} />
              <Route path="createAdvertisers" element={<CreateAdvertiser />} />
              <Route path="feeds" element={<Feeds store={FeedsAdminStore.getInstance()} />} />
              <Route path="feeds/:realEstateId" element={<RealEstateDetail />} />
              <Route path="search" element={<Search />} />
              <Route path="agent/:agentId" element={<AgentDetail />} />
              <Route path="createCampaing/:advertiserId" element={<CreateCampaign store={CreateCampaingStore.getInstance()}/>} />
            </Route>
            <Route path="/portal/" element={
              <RequireAuth type={UserType.agent}>
                <Portal />
              </RequireAuth>}>
              <Route path="create" element={<CreateRealEstate />} />
              <Route path="feeds" element={<Feeds store={FeedsAgentStore.getInstance()} />} />
              <Route path="feeds/:realEstateId" element={<RealEstateDetail />} />
            </Route>
          </Routes>
          <Footer />
        </GridItem>
      </Grid>
    </ChakraProvider>
  )
})
