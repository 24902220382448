import { action, observable } from 'mobx'
import { MobxStore } from '../../../../common/store/MobxStore'
import { PortalApplicationService } from '../../../application/PortalApplicationService'
import { Address, Amount, BasicInfo, Coordinates, EnumUtils, type RealEstateFeature, RealEstateType } from '@howsin/common'
import { RealEstateOperationType } from '@howsin/common/dist/src/realEstate/RealEstateOperationType'

export class CreateRealEstateStore extends MobxStore {
  // Basic Info
  @observable rooms = 0
  @observable bathrooms = 0
  @observable livingAreaSize = 0
  @observable price = Amount.defaultCurrency(0)
  @observable type = RealEstateType.apartment
  @observable operationType: RealEstateOperationType = RealEstateOperationType.buy
  @observable floor?: number
  @observable buildAreaSize?: number
  @observable plotAreaSize?: number

  // Address
  @observable street = ''
  @observable number?: string
  @observable municipality = ''
  @observable province = ''
  @observable zipCode = ''
  @observable coordinates: Coordinates = new Coordinates(0, 0)
  // @observable latlng: google.maps.LatLng = new google.maps.LatLng(0, 0)
  @observable isHidden: boolean = false

  @observable description = ''

  @observable video?: File
  @observable images: File[] = []
  @observable features: RealEstateFeature[] = []

  private constructor (
    private readonly portalApplicationService: PortalApplicationService
  ) {
    super()
    this.makeObservable()
  }

  private static instance: CreateRealEstateStore

  static getInstance (): CreateRealEstateStore {
    if (this.instance != null) {
      return this.instance
    }
    this.instance = new CreateRealEstateStore(
      PortalApplicationService.getInstance()
    )
    return this.instance
  }

  @action
  clear (): void {
    this.rooms = 0
    this.bathrooms = 0
    this.livingAreaSize = 0
    this.price = Amount.defaultCurrency(0)
    this.type = RealEstateType.apartment
    this.operationType = RealEstateOperationType.buy
    this.floor = undefined
    this.buildAreaSize = 0
    this.plotAreaSize = 0

    this.street = ''
    this.number = ''
    this.municipality = ''
    this.province = ''
    this.zipCode = ''
    this.coordinates = new Coordinates(0, 0)
    this.isHidden = false

    this.description = ''

    this.video = undefined
    this.images = []
    this.features = []
  }

  async send (): Promise<void> {
    this.startFetching()
    const realEstateId = await this.portalApplicationService.createRealEstate(
      new BasicInfo(
        this.rooms,
        this.bathrooms,
        this.livingAreaSize,
        this.price,
        this.type,
        this.operationType,
        this.floor,
        this.buildAreaSize,
        this.plotAreaSize
      ),
      new Address(
        this.street,
        this.number?.toString(),
        this.floor?.toString(),
        this.municipality,
        this.province,
        this.zipCode,
        this.coordinates,
        this.isHidden
      ),
      this.description
    )

    await Promise.all([
      await this.portalApplicationService.addFeatures(realEstateId, this.features),
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      await this.portalApplicationService.addVideoRealEstate(realEstateId, this.video!),
      // eslint-disable-next-line @typescript-eslint/no-confusing-void-expression
      await this.images.reduce(async (promise, file) => {
        await promise
        await this.portalApplicationService.addImageRealEstate(realEstateId, file)
      }, Promise.resolve())
    ])

    await this.portalApplicationService.toReview(realEstateId)

    this.endFetching()
  }

  @action
  addFeature (feature: RealEstateFeature): void {
    this.features.push(feature)
  }

  @action
  removeFeature (feature: string): void {
    this.features = this.features.filter(entity => entity !== feature)
  }

  @action
  setStreet (value: string): void {
    this.street = value
  }

  @action
  setNumber (value: string): void {
    this.number = value
  }

  @action
  setState (value: string): void {
    this.province = value
  }

  @action
  setZipCode (value: string): void {
    this.zipCode = value
  }

  @action
  setCity (value: string): void {
    this.municipality = value
  }

  @action
  setRooms (value: string): void {
    const rooms = parseInt(value, 10)
    if (Number.isInteger(rooms)) {
      this.rooms = rooms
    }
  }

  @action
  setBathrooms (value: string): void {
    const bathrooms = parseInt(value, 10)
    if (Number.isInteger(bathrooms)) {
      this.bathrooms = bathrooms
    }
  }

  @action
  setLivingAreaSize (value: string): void {
    const livingAreaSize = parseInt(value, 10)
    if (Number.isInteger(livingAreaSize)) {
      this.livingAreaSize = livingAreaSize
    }
  }

  @action
  setAmount (value: string): void {
    const price = parseInt(value, 10)
    if (Number.isInteger(price)) {
      this.price = Amount.defaultCurrency(price * 100)
    }
  }

  getPrice (): string {
    return (this.price.toInt() / 100).toString()
  }

  @action
  setDescription (value: string): void {
    this.description = value
  }

  @action
  setType (value: string): void {
    const type = EnumUtils.enumFromStringValue(RealEstateType, value)
    if (type != null) {
      this.type = type
    }
  }

  @action
  addImage (file: File): void {
    this.images.push(file)
  }

  @action
  addVideo (file: File): void {
    this.video = file
  }

  @action
  setLatitude (value: string): void {
    this.coordinates = new Coordinates(parseFloat(value), this.coordinates.toPrimitive().longitude)
  }

  @action
  setLongitude (value: string): void {
    this.coordinates = new Coordinates(this.coordinates.toPrimitive().latitude, parseFloat(value))
  }

  @action
  setIsHidden (value: boolean): void {
    this.isHidden = value
  }

  getIsHidden (): boolean {
    return this.isHidden
  }

  @action
  setOperationType (value: string): void {
    this.operationType = EnumUtils.enumFromStringValue(RealEstateOperationType, value) ?? RealEstateOperationType.buy
  }

  @action
  setBuildAreaSize (value: string): void {
    this.buildAreaSize = parseInt(value, 10)
  }

  @action
  setPlotAreaSize (value: string): void {
    this.plotAreaSize = parseInt(value, 10)
  }

  // @action
  // setNormalizedAddress (place: google.maps.GeocoderResult): void {
  //   for (const component of place.address_components) {
  //     const componentType = component.types[0]
  //     switch (componentType) {
  //       case 'street_number':
  //         this.number = component.long_name
  //         break
  //       case 'route':
  //         this.street = component.long_name
  //         break
  //       case 'postal_code':
  //         this.zipCode = component.long_name
  //         break
  //       case 'locality':
  //         this.municipality = component.long_name
  //         break
  //       case 'state':
  //         this.province = component.long_name
  //         break
  //       case 'administrative_area_level_2':
  //         this.province = component.long_name
  //         break
  //     }
  //   }
  //   this.coordinates = new Coordinates(
  //     place?.geometry.location.lat(),
  //     place?.geometry.location.lng())
  //   this.latlng = place?.geometry.location
  // }
}
