import React from 'react'
import { Center, VStack } from '@chakra-ui/react'
import { Outlet } from 'react-router-dom'

export const Portal = (): JSX.Element =>
  (
    <Center
      padding="2rem"
      width="100%"
      height="100%"
    >
      <VStack
        alignItems="flex-start"
        gap={4}
        justifyContent="flex-start"
        maxWidth="60rem"
        width="100%"
      >
        <Outlet />
      </VStack>
    </Center>
  )
