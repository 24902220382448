import { getAuth, signOut } from 'firebase/auth'
import { type BackendService } from '../../../../../common/service/BackendService'
import { UserType } from '@howsin/common'
import { FirebaseService } from '../../../../../common/service/firebase/FirebaseService'

export abstract class LoginProvider {
  protected constructor (private readonly backendService: BackendService) {
    FirebaseService.getInstance()
  }

  async login (): Promise<void> {
    const accessToke = await this.getCredential()
    this.setToken(accessToke)
    await this.canLogin()
  }

  protected async canLogin (): Promise<void> {
    const user = await this.backendService.whoAmI()
    if (user.type === UserType.buyer) {
      await signOut(getAuth())
      throw new Error('Invalid credentials role.')
    }
  }

  protected setToken (credential: string): void {
    this.backendService.setToken(credential)
  }

  abstract getCredential (): Promise<string>
}
