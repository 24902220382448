/* eslint-disable @typescript-eslint/no-unused-vars */
import { DotEnvConstantsService } from '../../../../../common/service/constants/DotEnvConstantsService'
import { type PasswordlessService } from '../../../../domain/PasswordlessService'
import { FakePasswordlessService } from './FakePasswordlessService'
import { FirebasePasswordlessService } from './FirebasePasswordlessService'

let instance: PasswordlessService | undefined

export const getInstance = (): PasswordlessService => {
  if (instance != null) {
    return instance
  }
  if (DotEnvConstantsService.getInstance().isDemo()) {
    instance = FakePasswordlessService.getInstance()
    return instance
  }
  // instance = FirebasePasswordlessService.getInstance()
  instance = FakePasswordlessService.getInstance()
  return instance
}
