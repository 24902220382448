import React from 'react'
import { type IconType } from 'react-icons'
import { Box, Divider } from '@chakra-ui/react'
import { NavItem } from './NavItem'
import { Caption } from '../text/BodyTextComponent'

interface SidebarProps {
  items: LinkItemProps[]
}

export interface LinkItemProps {
  name: string
  icon: IconType
  onClick: () => void
}

export const SidebarContent = ({ items }: SidebarProps): JSX.Element => {
  return (
    <Box>
      <Box padding={4}>
        <Caption text={('BackOffice').toUpperCase()}/>
        <Divider />
      </Box>
      {items.map((link) => (
        <NavItem key={link.name} icon={link.icon} onClick={link.onClick}>
          {link.name}
        </NavItem>
      ))}
    </Box>
  )
}
