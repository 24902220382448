import React, { useEffect } from 'react'
import { Heading3 } from '../../../ui/text/TitleComponent'
import {
  Wrap, Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer, Avatar, WrapItem, Box, useToast
} from '@chakra-ui/react'

import { AgentsStore } from './AgentsStore'
import { observer } from 'mobx-react'
import { SecondaryLinkButton } from '../../../ui/button/LinkButton'
import PrimaryButton from '../../../ui/button/PrimaryButton'
import { AppLifeCyclePublisher } from '../../../event/application/AppLifeCyclePublisher'
import { CopyTextComponent } from '../../../ui/text/ClipBoardTextComponent'

const Agents = observer(({ store = AgentsStore.getInstance() }: { store?: AgentsStore }): JSX.Element => {
  useEffect(() => {
    void store.fetch()
  }, [store])

  const toast = useToast()

  const goDetails = (id: string): void => {
    const appLifeCyclePublisher = AppLifeCyclePublisher.getInstance()
    appLifeCyclePublisher.goToAgentsDetailAdmin(id)
  }

  return (
    <Box w={'100%'}>
      <Wrap>
        <WrapItem>
          <Heading3 text="Agentes" />
        </WrapItem>
        <WrapItem>
          <PrimaryButton isFullWidth text={'Nuevo agente'} onTap={() => {
            store.goToCreateAgent()
          }} />
        </WrapItem>
      </Wrap>
      <Wrap marginTop={8}>
        <TableContainer style={{ width: '100%' }}>
          <Table size="md">
            <Thead>
              <Tr>
                <Th></Th>
                <Th>Nombre</Th>
                <Th>Email</Th>
                <Th>Opciones</Th>
              </Tr>
            </Thead>
            <Tbody>
              {store.agents.map(agent => (
                <Tr key={agent.id.toString()}>
                  <Td>
                    <WrapItem>
                      <Avatar size="sm" name={agent.name}
                              src={agent.avatar.getUrl()}
                              onClick={() => { goDetails(agent.id.toString()) }} />
                    </WrapItem>
                  </Td>
                  <Td>
                    <SecondaryLinkButton
                      text={agent.name}
                      onTap={() => { goDetails(agent.id.toString()) }}/>
                  </Td>
                  <Td>{agent.email}</Td>
                  <Td>
                    <CopyTextComponent text={agent.id.toString()} />
                    <SecondaryLinkButton text={'Impersonate'} onTap={() => {
                      void store.generateAndCopyToken(agent.id)
                      toast({
                        title: 'Copiado!',
                        status: 'success',
                        duration: 9000,
                        isClosable: true
                      })
                    }} />
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </Wrap>
    </Box>
  )
})

export default Agents
