import { Icon } from '@chakra-ui/react'
import * as React from 'react'

const BackIcon = ({ boxSize, color }: {
  boxSize: number | string
  color: string
}): JSX.Element => (
    <Icon viewBox="0 0 24 24" boxSize={boxSize} color={color}>
        <path
            fill='currentColor'
            d="m2.117 12 7.527 6.235L9 19l-9-7.521L9 4l.645.764L2.116 11H24v1H2.117z"
        />
    </Icon>
)

export default BackIcon
