import React, { type HTMLInputTypeAttribute } from 'react'
import { Box, Editable, EditableInput, EditablePreview, FormControl, FormLabel } from '@chakra-ui/react'
import { observer } from 'mobx-react'
import { backgroundColor, borderRadius, containerStyle, borderWidth, borderColor, padding } from './InputStyle'

const DefaultEditableInput = observer(({ text, onChangeText, type, isDisabled, value, isRequired }: {
  text: string
  onChangeText: (value: string) => void
  value?: string
  type?: HTMLInputTypeAttribute
  isDisabled?: boolean
  isRequired?: boolean
}): JSX.Element =>
  (
    <FormControl>
      { (value != null || value !== '') &&
        <FormLabel>{text}</FormLabel>
      }
      <Box style={containerStyle}>
        <Editable defaultValue={value}>
          <EditablePreview />
          <EditableInput
            bg={backgroundColor}
            borderColor={borderColor}
            borderRadius={borderRadius}
            borderWidth={borderWidth}
            padding={padding}
            placeholder={text}
            type={type ?? 'text'}
            required={isRequired}
            onChange={(e) => { onChangeText(e.target.value) }}
            width='100%'
          />
        </Editable>
      </Box>
    </FormControl>
  ))

export default DefaultEditableInput
