import { Switch } from '@chakra-ui/react'
import { colors } from '../constants'
import React from 'react'

const SwitchButton = ({ size, onChange, isDisabled, value }: {
  size: 'sn' | 'md' | 'lg'
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  isDisabled?: boolean
  value?: boolean
}): JSX.Element => {
  return (
    <Switch
      colorScheme='teal'
      border="0"
      borderRadius="1.5rem"
      color={colors.primary['900']}
      fontWeight="bold"
      maxHeight={'2.75rem'}
      minWidth={'4rem'}
      p={'0.5rem 1rem'}
      _hover={{
        bg: colors.primary['500'],
        cursor: 'pointer'
      }}
      _active={{
        bg: colors.primary['600']
      }}
      _disabled={{
        bg: colors.coolGray['200'],
        color: colors.coolGray['400'],
        cursor: 'default'
      }}
      isChecked={value}
      isDisabled={isDisabled}
      onChange={onChange}
    />
  )
}

export default SwitchButton
