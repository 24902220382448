import React, { type HTMLInputTypeAttribute } from 'react'
import { Box, FormControl, FormLabel } from '@chakra-ui/react'
import { observer } from 'mobx-react'
import { containerStyle } from './InputStyle'
import DatePicker from '../datePicker/DatePicker'
import moment from 'moment'

const DefaultDateInput = observer(({ text, onChange, value, isRequired }: {
  text: string
  onChange: (value: string) => void
  value?: string
  type?: HTMLInputTypeAttribute
  isDisabled?: boolean
  isRequired?: boolean
}): JSX.Element =>
  (
    <FormControl>
      { (value != null || value !== '') &&
        <FormLabel>{text}</FormLabel>
      }
      <Box style={containerStyle}>
        {/* <Input
          bg={backgroundColor}
          borderColor={borderColor}
          borderRadius={borderRadius}
          borderWidth={borderWidth}
          padding={padding}
          placeholder={text}
          type={type ?? 'text'}
          value={value}
          required={isRequired}
          onChange={(e) => onChangeText(e.target.value)}
          width='100%'
        /> */}
        <DatePicker
            selectedDate={(value != null) && !isNaN(Date.parse(value)) ? new Date(value) : new Date()}
            // selectedDate={new Date(value?.toString() ?? new Date().toISOString())}
            onChange={(date: Date) => { onChange(moment(date).format('L')) }}
            isRequired={isRequired}
            isClearable={true}
            showPopperArrow={true}
        />
      </Box>
    </FormControl>
  ))

export default DefaultDateInput
