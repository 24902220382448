import { MobxStore } from '../MobxStore'
import { AppLifeCyclePublisher } from '../../../event/application/AppLifeCyclePublisher'

export class NavbarAgentStore extends MobxStore {
  static getInstance (): NavbarAgentStore {
    if (this.instance != null) {
      return this.instance
    }
    this.instance = new NavbarAgentStore(
      AppLifeCyclePublisher.getInstance()
    )
    return this.instance
  }

  constructor (
    private readonly appLifeCyclePublisher: AppLifeCyclePublisher
  ) {
    super()
  }

  createRealEstate (): void {
    this.appLifeCyclePublisher.goToCreateRealEstate()
  }

  private static instance: NavbarAgentStore
}
