import React, { useState } from 'react'
import { type RealEstate } from '@howsin/common'
import { Flex, Divider, HStack, Spacer, VStack, Wrap, WrapItem } from '@chakra-ui/react'

import Card from '../../../../ui/card/Card'
import { BodyWithSkeleton, CaptionWithSkeleton } from '../../../../ui/text/BodyTextComponent'
import { Heading3, Heading4WithSkeleton } from '../../../../ui/text/TitleComponent'
import FeedBasicInfo from '../FeedBasicInfo'
import FeedMetrics from '../FeedMetrics'
import { Wrapper } from '@googlemaps/react-wrapper'
import RealEstateMapView from '../../../../common/map/RealEstateMapView'
import { DotEnvConstantsService } from '../../../../common/service/constants/DotEnvConstantsService'
import { RealEstateStoreContext } from './RealEstateDetail'

const RealEstateContentCard = (): JSX.Element => {
  const store = React.useContext(RealEstateStoreContext)
  const [realEstate, setRealEstate] = useState< RealEstate | null >(null)
  const isFetching = realEstate === undefined

  React.useEffect(() => {
    setRealEstate(store.realEstate ?? null)

    return () => {
      setRealEstate(null)
    }
  }, [store.realEstate])

  function getType (): string {
    switch (realEstate?.basicInfo?.type) {
      case 'apartment':
        return 'Apartamento'
      case 'penthouse':
        return 'Ático'
      case 'house':
        return 'Casa'
      case 'chalet':
        return 'Chalet'
      case 'villa':
        return 'Villa'
      case 'duplex':
        return 'Dúplex'
      case 'duplex penthouse':
        return 'Atico dúplex'
      case 'loft':
        return 'Loft'
      default:
        return ''
    }
  }

  return (
    <Card>
      <HStack alignItems="flex-start" justifyContent="space-between" width="100%">
        <VStack alignItems="flex-start" flex={1} margin={1}>
          <Heading4WithSkeleton
            isFetching={isFetching}
            text={realEstate == null ? '' : (realEstate.address.street ?? '') + ' ' + (realEstate.address.number ?? '')}
          />
          <CaptionWithSkeleton
            isFetching={isFetching}
            text={realEstate == null ? '' : realEstate.address.municipality ?? '' + ' ' + realEstate.address.zipCode ?? '' }
          />
          <CaptionWithSkeleton
            isFetching={isFetching}
            text={getType()}
          />
        </VStack>
        <VStack alignItems="flex-end">
          <Heading4WithSkeleton
            isFetching={isFetching}
            text={realEstate?.basicInfo?.operationType === 'buy' ? 'Compra' : 'Alquiler'}
          />
          <Heading3
            text={realEstate?.basicInfo?.price?.toString()}
          />
          {/* <LabelWithSkeleton
            isFetching={isFetching}
            text={realEstate == null ? '' : realEstate.address.getTag()}
          /> */}
        </VStack>
      </HStack>
      <Wrap justifyContent="space-around" alignItems="center" width="100%">
        <WrapItem padding={4} minWidth={400}>
          <FeedBasicInfo basicInfo={realEstate?.basicInfo} />
        </WrapItem>
        <Spacer />
        <WrapItem padding={4} minWidth={300}>
          <FeedMetrics metrics={realEstate?.metrics} />
        </WrapItem>
      </Wrap>
      <Divider my={4} />
      <Flex justifyContent="center" paddingY={8}>
        <BodyWithSkeleton
          isFetching={isFetching}
          numOfLines={5}
          text={realEstate?.description ?? ''}
        />
      </Flex>
      <Flex justifyContent="center" paddingY={8}>
        <BodyWithSkeleton
          isFetching={isFetching}
          numOfLines={5}
          text={ realEstate?.hashtags?.join(', ')}
        />
      </Flex>
      <Divider my={4} />
      { realEstate?.address.coordinates != null &&
        <Flex justifyContent="center" paddingY={8}>
          <Wrapper apiKey={DotEnvConstantsService.getInstance().getGoogleMapsApiKey()}>
            <RealEstateMapView
              center={{
                lat: realEstate?.address.coordinates.toPrimitive().latitude,
                lng: realEstate?.address.coordinates.toPrimitive().longitude
              }}
              zoom={18}
              isHidden={realEstate?.address.isAddressHidden()}
              />
          </Wrapper>
        </Flex>
      }
    </Card>
  )
}

export default RealEstateContentCard
